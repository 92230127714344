import React, { useState } from "react";
import "./BidHallLive.scss";
import { commaFormat, formatDateTime } from "../../utils/Helper";
import { BidSku, IBidEntry } from "../../models/Auction";
import { useParams } from "react-router-dom";

interface BidHallLiveProps {
  skuResult: { [key: number]: IBidEntry[] };
  skus: { [key: number]: BidSku[] };
  fetchSkuHistory: (sku_id: number) => void;
}

const BidHallLive: React.FC<BidHallLiveProps> = ({
  skuResult,
  skus,
  fetchSkuHistory,
}) => {
  const [showExpansion, setShowExpansion] = useState<string[]>([]);
  const { id } = useParams();

  const toggleExpansion = (
    bidIndex: number,
    skuIndex: number,
    sku_id: number,
  ) => {
    const key = `${bidIndex}-${skuIndex}`;
    setShowExpansion((prev) => {
      if (prev.includes(key)) {
        return prev.filter((k) => k !== key);
      } else {
        fetchSkuHistory(sku_id);
        return [...prev, key];
      }
    });
  };

  return (
    <>
      {skus && (
        <div className="live-bid-hall-main-container">
          {Object.values(skus).map((skuList, index) => (
            <div
              className="all-bids-main-container flex-column gap-8"
              key={`address-${index}`}
            >
              <div className="bid-detail-container">
                <div className="address-bar p-8 flex-row gap-16 xetgo-font-tag">
                  <p className="district bold">{skuList[0].state}</p>
                  <p className="line">
                    {skuList[0].address_line}, {skuList[0].city},{" "}
                    {skuList[0].pincode}
                  </p>
                </div>
                <div className="all-sku-detail-container flex-column ">
                  {skuList.map((skuDetail, skuIndex) => {
                    const key = `${index}-${skuIndex}`;
                    const isExpanded = showExpansion.includes(key);
                    return (
                      <div
                        key={skuIndex}
                        className={`sku-expansion-wrapper-container ${
                          skuIndex === 0 && "border-top-none"
                        } ${
                          skuList.length - 1 === skuIndex &&
                          "border-bottom-none-raidus"
                        }`}
                      >
                        <div
                          className={`sku-detail-upper-container cursor-pointer flex-row align-items-center justify-content-space-between p-12 ${
                            skuList.length - 1 === skuIndex &&
                            "border-bottom-none-raidus"
                          }`}
                          onClick={() =>
                            toggleExpansion(index, skuIndex, skuDetail.id)
                          }
                        >
                          <div className="flex-row align-items-center gap-12 full-width">
                            <p
                              style={{ color: "#1F2937" }}
                              className="head-index xetgo-font-tag px-12"
                            >
                              {skuIndex + 1}
                            </p>
                            <div className="sku-right-detail-conntainer flex-row align-items-center gap-48 xetgo-font-tag ">
                              <p style={{ color: "#1F2937" }}>
                                {skuDetail.name}
                              </p>
                              <div
                                style={{ color: "#1F2937" }}
                                className="flex-row align-items-center gap-12"
                              >
                                <p style={{ color: "#6B7280" }}>Quantity</p>
                                <p className="flex-row align-items-center gap-6">
                                  <span>
                                    {" "}
                                    {commaFormat(skuDetail.quantity.toString())}
                                  </span>{" "}
                                  <span style={{ color: "#6B7280" }}>
                                    {skuDetail.unit}
                                  </span>
                                </p>
                              </div>
                              <div
                                style={{ color: "#1F2937" }}
                                className="flex-row align-items-center gap-12"
                              >
                                <p style={{ color: "#6B7280" }}>Start Price</p>
                                <p className="flex-row align-items-center gap-6">
                                  ₹{" "}
                                  {commaFormat(
                                    skuDetail.start_price.toString(),
                                  )}
                                </p>
                              </div>
                              <div
                                style={{ color: "#1F2937" }}
                                className="flex-row align-items-center gap-12"
                              >
                                <p style={{ color: "#6B7280" }}>
                                  Decrement Price
                                </p>
                                <p className="flex-row align-items-center gap-6">
                                  ₹{" "}
                                  {commaFormat(
                                    skuDetail.min_decreement_price.toString(),
                                  )}
                                </p>
                              </div>
                            </div>
                          </div>
                          <img
                            className={` ${!isExpanded && "rotated-arrow"}`}
                            src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20241203190235-chevron-left.svg"
                            alt="arrow-icon"
                            height={16}
                          />
                        </div>
                        {isExpanded && (
                          <div className="expanded-main-container px-8">
                            <div className="expanded-heading-container bidder-grid xetgo-font-tag">
                              <p className="flex-row align-items-center px-12 py-8">
                                S. No.
                              </p>
                              <p className="flex-row align-items-center px-12 py-8">
                                Bidder Name
                              </p>
                              <p className="flex-row align-items-center px-12 py-8">
                                Unit Rate
                              </p>
                              <p className="flex-row align-items-center px-12 py-8">
                                Total Rate
                              </p>
                              <p className="flex-row align-items-center px-12 py-8">
                                Current Rank
                              </p>
                              <p className="flex-row align-items-center px-12 py-8">
                                Bid Time
                              </p>
                            </div>
                            {skuResult[skuDetail.id] != null && (
                              <div className="all-bidder-container">
                                {skuResult[skuDetail.id].map(
                                  (bidder, bidderIndex) => (
                                    <div
                                      key={bidderIndex}
                                      className="bidder-detail-container bidder-grid xetgo-font-tag"
                                    >
                                      <p
                                        style={{ paddingBottom: "17px" }}
                                        className="grid-border-right flex-row align-items-center px-12 py-8"
                                      >
                                        {bidderIndex + 1}
                                      </p>
                                      <p className="grid-border-right flex-row align-items-center px-12 py-8">
                                        {bidder.cname}
                                      </p>
                                      <p
                                        style={{ color: "#5151EC" }}
                                        className="flex-row align-items-center grid-border-right px-12 py-8"
                                      >
                                        ₹ {commaFormat(bidder.price.toString())}
                                      </p>
                                      <p className="flex-row align-items-center grid-border-right px-12 py-8">
                                        ₹{" "}
                                        {commaFormat(
                                          (
                                            Math.round(
                                              skuDetail.quantity *
                                                bidder.price *
                                                100,
                                            ) / 100
                                          ).toString(),
                                        )}
                                      </p>
                                      <p
                                        className={`flex-row align-items-center grid-border-right px-12 py-8 ${
                                          bidder.bid_rank === 1 && "top"
                                        }`}
                                      >
                                        {bidderIndex + 1}
                                      </p>
                                      <p className="flex-row align-items-center px-12 py-8">
                                        {formatDateTime(bidder.created_at)}
                                      </p>
                                    </div>
                                  ),
                                )}
                              </div>
                            )}
                            {(skuResult[skuDetail.id] == null ||
                              skuResult[skuDetail.id].length === 0) && (
                              <p
                                style={{ color: "#6B7280" }}
                                className="no-bid-state flex-row align-items-center justify-content-center xetgo-font-tag bold p-12"
                              >
                                No bids received{" "}
                              </p>
                            )}
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default BidHallLive;
