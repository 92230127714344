import React, { useState, ChangeEvent } from "react";
import "./CreateAuction.scss";
import { MEASUREMENT_UNITS } from "../../components/QuantityDropdown/QuantityDropdown.data";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { formatDate } from "../../utils/Helper";
import { formatTime } from "../../utils/date-util";
import XetEditor from "../../components/shared/xet-editor/XetEditor";
import { useAuctionContext } from "../../context/create-auction-context";
import { uploadFile } from "../../services/fileUploadService";

interface CreateAuctionProps {
  active?: number;
  update?: boolean;
  vendorFormId?: number;
}

const CreateAuction: React.FC<CreateAuctionProps> = () => {
  const [startTimeCalendarOpen, setStartTimeCalendarOpen] = useState(false);
  const [endTimeCalendarOpen, setEndTimeCalendarOpen] = useState(false);
  const { auction, setBasicDetails, setBuyerDetails } = useAuctionContext();

  const handleMeasurementUnitChange = (
    event: ChangeEvent<HTMLSelectElement>,
  ) => {
    const newMeasurementUnit = event.target.value;
    setBasicDetails("currency", newMeasurementUnit);
  };

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (event.target.files && event.target.files.length > 0) {
      const files = Array.from(event.target.files);
      const displayNames = files.map((file) => file.name);
      const fileList: File[] = [];
      const displayNameList: string[] = [];
      files.forEach((file, index) => {
        fileList.push(file);
        const displayName = displayNames[index]?.trim();
        if (displayName) {
          displayNameList.push(displayName);
        }
      });
      const response = await uploadFile(files, displayNameList);
      setBasicDetails("guidelines", response[0].s3url);
      setBasicDetails("guidelineFileName", response[0].name);
    }
  };

  const descriptionChangeHandler = (data: string) => {
    setBasicDetails("description", data);
  };

  return (
    <div className="create-auction-page-form flex-column gap-16 p-12">
      <div className="auction-form-detail-main-container flex-column gap-4">
        <p className="title p-12 xetgo-font-tag bold">Auction Information</p>
        <div className="auction-info-below-container flex-column gap-16 p-12">
          <div className="auction-input-container flex-column xetgo-font-tag gap-8 flex-1">
            <p className="input-title">
              Auction Title <span style={{ color: "red" }}>*</span>
            </p>
            <input
              className="input-box xetgo-font-tag px-12 py-6"
              onChange={(e) => setBasicDetails("title", e.target.value)}
              value={auction.auction_basic_details.title}
              placeholder="Enter title"
            />
          </div>
          <div className="three-input-container flex-row gap-8">
            <div className="auction-input-container flex-column xetgo-font-tag gap-8 flex-1">
              <p className="input-title">
                Auction type <span style={{ color: "red" }}>*</span>
              </p>
              <input
                className="input-box xetgo-font-tag px-12 py-6"
                onChange={(e) => setBasicDetails("type", e.target.value)}
                value={auction.auction_basic_details.type}
                placeholder="Enter auction type"
              />
            </div>
            <div className="auction-input-container flex-column xetgo-font-tag gap-8 flex-1">
              <p className="input-title">
                Currency <span style={{ color: "red" }}>*</span>
              </p>

              <div className="quantity-input-box flex-row position-relative">
                <select
                  value={auction.auction_basic_details.currency}
                  onChange={handleMeasurementUnitChange}
                  className="measurement-box xetgo-font-tag bold"
                >
                  {MEASUREMENT_UNITS.map((unit) => (
                    <option style={{ color: "black" }} key={unit} value={unit}>
                      {unit}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="three-input-container flex-row gap-8">
            <div className="auction-input-container flex-column xetgo-font-tag gap-8 flex-1 position-relative">
              <p className="input-title">
                Start Date & Time <span style={{ color: "red" }}>*</span>
              </p>
              <input
                className="input-box xetgo-font-tag px-12 py-6"
                disabled
                value={auction.auction_basic_details.start_time}
                placeholder="Select Start Date"
              />
              <div
                style={{ color: "#484848" }}
                className="start-date-wrapper flex-row align-items-center justify-content-space-between full-width gap-8"
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    value={dayjs(auction.auction_basic_details.start_time)}
                    format={"DD/MM/YYYY, h A"}
                    views={["year", "month", "day", "hours", "minutes"]}
                    onAccept={(event) => {
                      const date = formatDate(
                        event?.toDate().toISOString() || "",
                      );
                      const time = formatTime(
                        event?.toDate().toISOString() || "",
                      );
                      setBasicDetails("start_time", `${date}, ${time}`);
                      setStartTimeCalendarOpen(false);
                    }}
                    disablePast
                    open={startTimeCalendarOpen}
                    className="delivery-date-selector xetgo-font-tag-bold"
                  />
                </LocalizationProvider>
                <img
                  src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240103135613-calender+check+blue.svg"
                  onClick={() => setStartTimeCalendarOpen(true)}
                  className="calendar-icon cursor-pointer"
                  height={16}
                  width={16}
                  alt=""
                />
              </div>
            </div>
            <div className="auction-input-container flex-column xetgo-font-tag gap-8 flex-1 position-relative">
              <p className="input-title">
                End Date & Time<span style={{ color: "red" }}>*</span>
              </p>

              <div className="quantity-input-box flex-row position-relative">
                <input
                  value={auction.auction_basic_details.end_time}
                  className="input-box full-width px-12 py-6 xetgo-font-tag "
                  placeholder="Select End Date"
                  disabled
                />
              </div>
              <div
                style={{ color: "#484848" }}
                className="start-date-wrapper flex-row align-items-center justify-content-space-between full-width gap-8"
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    value={dayjs(auction.auction_basic_details.end_time)}
                    format={"DD/MM/YYYY, h A"}
                    views={["year", "month", "day", "hours", "minutes"]}
                    onAccept={(event) => {
                      const date = formatDate(
                        event?.toDate().toISOString() || "",
                      );
                      const time = formatTime(
                        event?.toDate().toISOString() || "",
                      );
                      setBasicDetails("end_time", `${date}, ${time}`);
                      setEndTimeCalendarOpen(false);
                    }}
                    disablePast
                    open={endTimeCalendarOpen}
                    className="delivery-date-selector xetgo-font-tag-bold"
                  />
                </LocalizationProvider>
                <img
                  src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240103135613-calender+check+blue.svg"
                  onClick={() => setEndTimeCalendarOpen(true)}
                  className="calendar-icon cursor-pointer"
                  height={16}
                  width={16}
                  alt=""
                />
              </div>
            </div>
          </div>
          <div className="auction-description-container flex-column gap-8">
            <p className="input-title xetgo-font-tag">
              Auction Description <span style={{ color: "red" }}>*</span>
            </p>
            <div className="description-container">
              <XetEditor
                value={auction.auction_basic_details.description}
                defaultHeight="248px"
                onChange={descriptionChangeHandler}
              />
            </div>
          </div>
          <div className="auction-description-container flex-column gap-8">
            <p className="input-title xetgo-font-tag">
              Auction Guidelines <span style={{ color: "red" }}>*</span>
            </p>
            <div>
              <input
                onChange={(event) => {
                  handleFileChange(event);
                }}
                id="pan-fileInput"
                name="file"
                type="File"
                style={{
                  position: "absolute",
                  top: "12px",
                  right: "12px",
                  display: "none",
                }}
                multiple={false}
              />
              <label className="position-relative " htmlFor="pan-fileInput">
                <p className="input-box guideline-file xetgo-font-tag px-12 py-6 flex-row align-items-center cursor-pointer justify-content-space-between">
                  {auction.auction_basic_details.guidelineFileName ||
                    "Upload Guidelines"}
                  <img
                    height={16}
                    width={16}
                    src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240714075812-upload+%286%29.svg"
                    alt="upload-icon"
                  />
                </p>
              </label>
            </div>
          </div>
        </div>
      </div>

      <div className="auction-form-detail-main-container flex-column gap-4">
        <p className="title p-12 xetgo-font-tag bold">Buyer Details</p>
        {auction.auction_buyer_details.map((buyer, index) => (
          <div
            className="auction-info-below-container flex-column gap-16 p-12"
            key={`buyer-auction-${index}`}
          >
            <div className="flex-row gap-12">
              <div className="auction-input-container flex-column xetgo-font-tag gap-8 flex-1">
                <p className="input-title">
                  Person Name <span style={{ color: "red" }}>*</span>
                </p>
                <input
                  className="input-box xetgo-font-tag px-12 py-6"
                  onChange={(e) =>
                    setBuyerDetails(index, "name", e.target.value)
                  }
                  value={buyer.name}
                  placeholder="Enter name"
                />
              </div>
              <div className="auction-input-container flex-column xetgo-font-tag gap-8 flex-1">
                <p className="input-title">
                  Email <span style={{ color: "red" }}>*</span>
                </p>
                <input
                  className="input-box xetgo-font-tag px-12 py-6"
                  onChange={(e) =>
                    setBuyerDetails(index, "email", e.target.value)
                  }
                  value={buyer.email}
                  placeholder="Enter email"
                />
              </div>
            </div>
            <div className="flex-row gap-12">
              <div className="auction-input-container flex-column xetgo-font-tag gap-8 flex-1">
                <p className="input-title">
                  Location <span style={{ color: "red" }}>*</span>
                </p>
                <input
                  className="input-box xetgo-font-tag px-12 py-6"
                  onChange={(e) =>
                    setBuyerDetails(index, "location", e.target.value)
                  }
                  value={buyer.location}
                  placeholder="Enter location"
                />
              </div>
              <div className="auction-input-container flex-column xetgo-font-tag gap-8 flex-1">
                <p className="input-title">
                  Contact No. <span style={{ color: "red" }}>*</span>
                </p>
                <input
                  type="number"
                  className="input-box xetgo-font-tag px-12 py-6"
                  onChange={(e) =>
                    setBuyerDetails(index, "contact", e.target.value)
                  }
                  value={buyer.contact}
                  placeholder="Enter contact number"
                />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CreateAuction;
