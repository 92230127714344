import React, { useEffect, useState } from "react";
import "./VendorBidHistory.scss";
import { useNavigate, useParams } from "react-router-dom";
import { commaFormat, formatDateTime } from "../../utils/Helper";
import { Auction, BidSku, IBidEntry } from "../../models/Auction";
import ApiService from "../../services/apiService";
import URLS from "../../urls/Urls";

const VendorBidHistory: React.FC = () => {
  const [allBidList, setAllBidList] = useState<{ [key: number]: IBidEntry[] }>(
    {},
  );
  const [bidList, setBidList] = useState<{ [key: number]: IBidEntry[] }>({});
  const [activeBid, setActiveBid] = useState(2);
  const { id, auctionId } = useParams();
  const [skus, setSkus] = useState<{ [key: number]: BidSku[] }>();

  useEffect(() => {
    fetchBidSkus();
    fetchSkuHistory();
  }, []);

  useEffect(() => {
    const result: { [key: number]: IBidEntry[] } = {};
    Object.keys(allBidList).forEach((key: string) => {
      if (activeBid === 0) {
        result[parseInt(key)] = allBidList[parseInt(key)].filter(
          (bid) => bid.status === "ACCEPTED",
        );
      } else if (activeBid === 1) {
        result[parseInt(key)] = allBidList[parseInt(key)].filter(
          (bid) => bid.status === "REJECTED" || bid.status === "DECLINED",
        );
      } else {
        result[parseInt(key)] = allBidList[parseInt(key)];
      }
    });

    setBidList(result);
  }, [allBidList, activeBid]);

  const fetchSkuHistory = async () => {
    try {
      const { data } = await ApiService().client.get(
        URLS.AUCTION.VENDOR_BID_RESULT(auctionId || "", id || ""),
      );
      setAllBidList(data.bids);
    } catch (e) {
      console.error("Error: ", e);
    }
  };

  const fetchBidSkus = async () => {
    try {
      const { data } = await ApiService().client.get(
        URLS.AUCTION.BID_SKUS(auctionId || ""),
      );
      setSkus(data.skus);
    } catch (e) {
      console.error("Error: ", e);
    }
  };

  const handleTabChange = (activeIndex: number) => {
    setActiveBid(activeIndex);
  };

  return (
    <div className="vendor-history-bid-hall-main-container flex-column p-12">
      <div className="history-upper-detail-container border-box flex-row align-items-center justify-content-space-between  p-8">
        <div className="history-box-bids-status-tab-container flex-row xetgo-font-tag">
          <p
            onClick={() => handleTabChange(0)}
            className={`bid-tab px-22  bold flex-row align-items-center justify-content-center cursor-pointer ${
              activeBid === 0 && "active-bid-tab"
            }`}
          >
            Accepted Bids
          </p>
          <p
            onClick={() => handleTabChange(1)}
            className={`bid-tab px-22  bold flex-row align-items-center justify-content-center cursor-pointer ${
              activeBid === 1 && "active-bid-tab"
            }`}
          >
            Rejected Bids
          </p>
          <p
            onClick={() => handleTabChange(2)}
            className={`bid-tab px-22  bold flex-row align-items-center justify-content-center cursor-pointer ${
              activeBid === 2 && "active-bid-tab"
            }`}
          >
            All Bids
          </p>
        </div>
      </div>

      {skus != null && (
        <div className="history-bids-main-container flex-column gap-8">
          {Object.values(skus).map((skuList, index) => (
            <div key={index} className="bid-detail-container">
              <div className="address-bar p-8 flex-row gap-16 xetgo-font-tag">
                <p className="district bold">{skuList[0].state}</p>
                <p className="line">
                  {skuList[0].address_line}, {skuList[0].city},{" "}
                  {skuList[0].pincode}
                </p>
              </div>
              <div className="all-sku-detail-container flex-column ">
                {skuList.map((sku, skuIndex) => {
                  const key = `${index}-${skuIndex}`;
                  return (
                    <div
                      key={skuIndex}
                      className={`sku-expansion-wrapper-container ${
                        skuIndex === 0 && "border-top-none"
                      } ${
                        skuList.length - 1 === skuIndex &&
                        "border-bottom-none-raidus"
                      }`}
                    >
                      <div
                        className={`sku-detail-upper-container cursor-pointer flex-row justify-content-space-between px-12  ${
                          skuList.length - 1 === skuIndex &&
                          "border-bottom-none-raidus"
                        }`}
                      >
                        <div className="flex-row gap-12 full-width">
                          <p
                            style={{ color: "#1F2937" }}
                            className="head-index p-12 xetgo-font-tag"
                          >
                            {skuIndex + 1}
                          </p>
                          <div className="sku-right-detail-conntainer flex-row xetgo-font-tag ">
                            <p style={{ color: "#1F2937" }} className="py-12">
                              {sku.name}
                            </p>
                            <div
                              style={{ color: "#1F2937" }}
                              className="flex-row align-items-center py-12 gap-12"
                            >
                              <p style={{ color: "#6B7280" }}>Quantity</p>
                              <p className="flex-row gap-6">
                                <span>
                                  {" "}
                                  {commaFormat(sku.quantity.toString())}
                                </span>{" "}
                                <span style={{ color: "#6B7280" }}>
                                  {sku.unit}
                                </span>
                              </p>
                            </div>
                            <p
                              style={{ color: "#1F2937" }}
                              className="flex-row align-items-center py-12 gap-10"
                            >
                              <span style={{ color: "#6B7280" }}>
                                {" "}
                                Start Price{" "}
                              </span>{" "}
                              <span className="flex-row gap-6">
                                ₹ {commaFormat(sku.start_price.toString())}
                              </span>
                            </p>

                            <div
                              style={{ color: "#1F2937" }}
                              className="flex-row align-items-center py-12 gap-12"
                            >
                              <p style={{ color: "#6B7280" }}>
                                Decrement Price
                              </p>
                              <p className="flex-row gap-6">
                                ₹{" "}
                                {commaFormat(
                                  sku.min_decreement_price.toString(),
                                )}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="expanded-main-container px-8">
                        <div className="expanded-heading-container bidder-grid xetgo-font-tag">
                          <p className="flex-row align-items-center px-12 py-8">
                            S. No.
                          </p>
                          <p className="flex-row align-items-center px-12 py-8">
                            Unit Rate
                          </p>
                          <p className="flex-row align-items-center px-12 py-8">
                            Total Rate
                          </p>
                          <p className="flex-row align-items-center px-12 py-8">
                            Bid Date & Time
                          </p>
                          <p className="flex-row align-items-center px-12 py-8">
                            Bid Status
                          </p>
                          <p className="flex-row align-items-center px-12 py-8">
                            Remarks
                          </p>
                        </div>
                        {bidList[sku.id]?.length > 0 && (
                          <div className="all-bidder-container">
                            {bidList[sku.id].map((bidder, bidderIndex) => (
                              <div
                                key={bidderIndex}
                                className="bidder-detail-container bidder-grid xetgo-font-tag"
                              >
                                <p
                                  style={{ paddingBottom: "17px" }}
                                  className="grid-border-right flex-row align-items-center px-12 py-8"
                                >
                                  {bidderIndex + 1}
                                </p>
                                <p className="unit-rate grid-border-right flex-row align-items-center gap-12 px-12 py-8">
                                  ₹{" "}
                                  <span>
                                    {commaFormat(bidder.price.toString())}
                                  </span>
                                </p>
                                <p className="total-rate grid-border-right px-12 flex-row align-items-center gap-12 py-8">
                                  ₹{" "}
                                  <span>
                                    {commaFormat(
                                      (
                                        Math.round(
                                          sku.quantity * bidder.price * 100,
                                        ) / 100
                                      ).toString(),
                                    )}
                                  </span>
                                </p>
                                <p
                                  className={`grid-border-right flex-row align-items-center gap-2 px-12 py-8`}
                                >
                                  {formatDateTime(bidder.created_at)}
                                </p>
                                <div className="flex-row align-items-center px-12 py-8 grid-border-right">
                                  <p
                                    className={`bidder-status py-4 px-8 ${
                                      bidder.status == "ACCEPTED"
                                        ? "accepted-status"
                                        : bidder.status === "REJECTED"
                                          ? "rejected-status"
                                          : "failed-status"
                                    }`}
                                  >
                                    {bidder.status.toLowerCase()}
                                  </p>
                                </div>
                                <p className="flex-row align-items-center px-12 py-8">
                                  {bidder.remark}
                                </p>
                              </div>
                            ))}
                          </div>
                        )}
                        {bidList[sku.id]?.length === 0 && (
                          <p
                            style={{ color: "#6B7280" }}
                            className="no-bid-state flex-row align-items-center justify-content-center xetgo-font-tag bold p-12"
                          >
                            No bids received
                          </p>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default VendorBidHistory;
