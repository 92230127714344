import { useAuctionContext } from "../../context/create-auction-context";
import "./AuctionReviewConfirmation.scss";

const AuctionReviewConfirmation: React.FC = () => {
  const { auction } = useAuctionContext();
  return (
    <div className="auction-review-page-form flex-column gap-16 p-12">
      <div className="auction-review-detail-main-container flex-column gap-4 px-12">
        <div className="detail-inner-container flex-column gap-4">
          <div>
            <p className="title xetgo-font-tag p-12 bold">
              Auction Information
            </p>
            <div className="auction-review-detail-info-upper-container flex-column gap-14 p-12 xetgo-font-tag">
              <div className="flex-row gap-32">
                <p className="auction-info-title bold">Auction Title </p>
                <p className="auction-info-sub-title">
                  {auction.auction_basic_details.title}
                </p>
              </div>
              <div className="flex-row gap-32">
                <p className="auction-info-title bold"> Auction Type </p>
                <p className="auction-info-sub-title">
                  {auction.auction_basic_details.type}
                </p>
              </div>
              <div className="flex-row gap-32">
                <p className="auction-info-title bold"> Start Date & Time </p>
                <p className="auction-info-sub-title">
                  {auction.auction_basic_details.start_time}
                </p>
              </div>
              <div className="flex-row gap-32">
                <p className="auction-info-title bold"> End Date & Time </p>
                <p className="auction-info-sub-title">
                  {auction.auction_basic_details.end_time}
                </p>
              </div>
              <div className="flex-row gap-32">
                <p className="auction-info-title bold"> Guideline File </p>
                <a
                  href={auction.auction_basic_details.guidelines}
                  className="auction-info-sub-title"
                  target="_blank"
                  rel="noreferrer"
                >
                  {auction.auction_basic_details.guidelineFileName}
                </a>
              </div>
              <div className="flex-row gap-32">
                <p className="auction-info-title bold"> Auction Description</p>
                <div
                  className="auction-info-sub-title"
                  dangerouslySetInnerHTML={{
                    __html: auction.auction_basic_details.description,
                  }}
                ></div>
              </div>
            </div>
          </div>
          <div>
            <p className="title xetgo-font-tag p-12 bold">Buyer Details</p>
            {auction.auction_buyer_details.map((buyer, index) => (
              <div
                className="auction-review-detail-info-upper-container flex-column gap-14 p-12 xetgo-font-tag"
                key={`buyer-${index}`}
              >
                <div className="flex-row gap-32">
                  <p className="auction-info-title bold">Person Name </p>
                  <p className="auction-info-sub-title">{buyer.name}</p>
                </div>
                <div className="flex-row gap-32">
                  <p className="auction-info-title bold"> Email </p>
                  <p className="auction-info-sub-title">{buyer.email}</p>
                </div>
                <div className="flex-row gap-32">
                  <p className="auction-info-title bold"> Location </p>
                  <p className="auction-info-sub-title">{buyer.location}</p>
                </div>
                <div className="flex-row gap-32">
                  <p className="auction-info-title bold"> Contact Number </p>
                  <p className="auction-info-sub-title">{buyer.contact}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="auction-review-detail-main-container flex-column gap-4 px-12">
        <div className="flex-column gap-4">
          <div className="detail-inner-container flex-column gap-4">
            <p className="title xetgo-font-tag p-12 bold">
              Delivery Address & SKU
            </p>
            {auction.auction_sku_details.length > 0 &&
              auction.auction_sku_details.map((addressDetail, index) => (
                <div key={index} className="address-sku-wrapper-container">
                  <div className="heading-bar flex-row align-items-center gap-18 xetgo-font-tag p-8">
                    <p className="state-name bold">{addressDetail.address}</p>
                  </div>
                  <div className="address-skus-main-container">
                    <div className="address-sku-grid heading-bar-container xetgo-font-tag">
                      <p className="empty-index p-12"></p>
                      <p className="p-12 bold">SKU Name</p>
                      <p className="p-12 bold">Quantity</p>
                      <p className="p-12 bold">Start Price (INR)</p>
                      <p className="p-12 bold">Minimum Decrement Price (INR)</p>
                      <p className="p-12 bold">Description</p>
                    </div>
                    {addressDetail.skus.length > 0 &&
                      addressDetail.skus.map((sku, skuIndex) => (
                        <div
                          key={skuIndex}
                          className="address-sku-grid xetgo-font-tag"
                        >
                          <p className="index px-12 py-8">{index + 1}</p>
                          <a
                            href={`/skus/${sku.id}`}
                            className="sku-name px-12 py-8 border-right-top bold"
                            target="_blank"
                            rel="noreferrer"
                          >
                            {sku.name}
                          </a>
                          <p className="border-right-top px-12 py-8 flex-row align-items-center gap-4">
                            {sku.quantity}
                            <span className="measurement-unit">{sku.unit}</span>
                          </p>
                          <p className="start-price border-right-top px-12 py-8 flex-row align-items-center gap-20">
                            ₹<span className="value">{sku.starting_price}</span>
                          </p>
                          <p className="decrement-price border-right-top px-12 py-8 flex-row align-items-center gap-20">
                            ₹<span>{sku.decrement_price}</span>
                          </p>
                          <p className="description-sku border-top px-12 py-8 bold">
                            {sku.description}
                          </p>
                        </div>
                      ))}
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>

      <div className="auction-review-detail-main-container flex-column gap-4 p-12">
        <div className="flex-column gap-12">
          <p className="title xetgo-font-tag px-12 bold">Invited Vendors</p>
          <div className="auction-review-detail-info-upper-container flex-column gap-14 p-12 xetgo-font-tag">
            <p className="auction-info-title bold">
              Assigned Form:{" "}
              <span style={{ color: "#5151EC" }}>
                <a
                  href={`/vendor-form/detail/${auction.vendor_invitation_details.form_id}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {auction.vendor_invitation_details.form_name}
                </a>
              </span>
            </p>

            <div className="all-vendor-auction-review-main-container flex-row gap-9">
              {auction.vendor_invitation_details.invited_vendors.map(
                (vendor, vendorIndex) => (
                  <div
                    key={vendorIndex}
                    className={`vendor-card flex-column gap-4 px-12 py-8 ${
                      vendorIndex % 2 !== 0 && "vendor-card-yellow"
                    }`}
                  >
                    <p className="vendor-title xetgo-font-tag bold">
                      {vendor.name}
                    </p>
                    {/* <p className="vendor-email">{vendor.email}</p> */}
                  </div>
                ),
              )}
            </div>
          </div>
          <div className="auction-note-container flex-row gap-4 p-12 xetgo-font-tag">
            Notes: <span>{auction.vendor_invitation_details.notes}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuctionReviewConfirmation;
