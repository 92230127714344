import React, { useEffect, useState } from "react";
import "./BidHallHistory.scss";
import { useNavigate, useParams } from "react-router-dom";
import { commaFormat, formatDateTime } from "../../utils/Helper";
import { Auction, BidSku, IBidEntry } from "../../models/Auction";

interface BidHallHistoryProps {
  skuHistory: { [key: number]: IBidEntry[] };
  skus: { [key: number]: BidSku[] };
  fetchSkuHistory: (sku_id: number) => void;
  auction: Auction;
}

const BidHallHistory: React.FC<BidHallHistoryProps> = ({
  skuHistory,
  skus,
  fetchSkuHistory,
  auction,
}) => {
  const [bidList, setBidList] = useState<{ [key: number]: IBidEntry[] }>({});
  const [activeBid, setActiveBid] = useState(2);
  const [showExpansion, setShowExpansion] = useState<string[]>([]);
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    const result: { [key: number]: IBidEntry[] } = {};
    Object.keys(skuHistory).forEach((key: string) => {
      if (activeBid === 0) {
        result[parseInt(key)] = skuHistory[parseInt(key)].filter(
          (bid) => bid.status === "ACCEPTED",
        );
      } else if (activeBid === 1) {
        result[parseInt(key)] = skuHistory[parseInt(key)].filter(
          (bid) => bid.status === "REJECTED" || bid.status === "DECLINED",
        );
      } else {
        result[parseInt(key)] = skuHistory[parseInt(key)];
      }
    });
    setBidList(result);
  }, [skuHistory, activeBid]);

  const toggleExpansion = (
    bidIndex: number,
    skuIndex: number,
    sku_id: number,
  ) => {
    const key = `${bidIndex}-${skuIndex}`;
    setShowExpansion((prev) => {
      if (prev.includes(key)) {
        return prev.filter((k) => k !== key);
      } else {
        fetchSkuHistory(sku_id);
        return [...prev, key];
      }
    });
  };

  const handleTabChange = (activeIndex: number) => {
    setActiveBid(activeIndex);
    setShowExpansion([]);
  };

  const handleBackClick = () => {
    navigate(`/auctions/${id}/bid-hall`);
  };
  return (
    <div className="history-bid-hall-main-container flex-column gap-4">
      <div className="history-upper-detail-container border-box flex-row align-items-center justify-content-space-between  p-8">
        <div
          onClick={handleBackClick}
          className="flex-row gap-12 align-items-center cursor-pointer xetgo-font-tag bold"
        >
          <p style={{ color: "#AA5200" }}>Bid Result</p>
        </div>
        <div className="history-box-bids-status-tab-container flex-row xetgo-font-tag">
          <p
            onClick={() => handleTabChange(0)}
            className={`bid-tab px-22  bold flex-row align-items-center justify-content-center cursor-pointer ${
              activeBid === 0 && "active-bid-tab"
            }`}
          >
            Accepted Bids
          </p>
          <p
            onClick={() => handleTabChange(1)}
            className={`bid-tab px-22  bold flex-row align-items-center justify-content-center cursor-pointer ${
              activeBid === 1 && "active-bid-tab"
            }`}
          >
            Rejected Bids
          </p>
          <p
            onClick={() => handleTabChange(2)}
            className={`bid-tab px-22  bold flex-row align-items-center justify-content-center cursor-pointer ${
              activeBid === 2 && "active-bid-tab"
            }`}
          >
            All Bids
          </p>
        </div>
      </div>

      {skus != null && (
        <div className="history-bids-main-container flex-column gap-8">
          {Object.values(skus).map((skuList, index) => (
            <div key={index} className="bid-detail-container">
              <div className="address-bar p-8 flex-row gap-16 xetgo-font-tag">
                <p className="district bold">{skuList[0].state}</p>
                <p className="line">
                  {skuList[0].address_line}, {skuList[0].city},{" "}
                  {skuList[0].pincode}
                </p>
              </div>
              <div className="all-sku-detail-container flex-column ">
                {skuList.map((sku, skuIndex) => {
                  const key = `${index}-${skuIndex}`;
                  const isExpanded = showExpansion.includes(key);
                  return (
                    <div
                      key={skuIndex}
                      className={`sku-expansion-wrapper-container ${
                        skuIndex === 0 && "border-top-none"
                      } ${
                        skuList.length - 1 === skuIndex &&
                        "border-bottom-none-raidus"
                      }`}
                    >
                      <div
                        className={`sku-detail-upper-container cursor-pointer flex-row justify-content-space-between px-12  ${
                          skuList.length - 1 === skuIndex &&
                          "border-bottom-none-raidus"
                        }`}
                        onClick={() => toggleExpansion(index, skuIndex, sku.id)}
                      >
                        <div className="flex-row gap-12 full-width">
                          <p
                            style={{ color: "#1F2937" }}
                            className="head-index p-12 xetgo-font-tag"
                          >
                            {skuIndex + 1}
                          </p>
                          <div className="sku-right-detail-conntainer flex-row xetgo-font-tag ">
                            <p style={{ color: "#1F2937" }} className="py-12">
                              {sku.name}
                            </p>
                            <div
                              style={{ color: "#1F2937" }}
                              className="flex-row align-items-center py-12 gap-12"
                            >
                              <p style={{ color: "#6B7280" }}>Quantity</p>
                              <p className="flex-row gap-6">
                                <span>
                                  {" "}
                                  {commaFormat(sku.quantity.toString())}
                                </span>{" "}
                                <span style={{ color: "#6B7280" }}>
                                  {sku.unit}
                                </span>
                              </p>
                            </div>
                            <p
                              style={{ color: "#1F2937" }}
                              className="flex-row align-items-center py-12 gap-10"
                            >
                              <span style={{ color: "#6B7280" }}>
                                {" "}
                                Start Price{" "}
                              </span>{" "}
                              <span className="flex-row gap-6">
                                ₹ {commaFormat(sku.start_price.toString())}
                              </span>
                            </p>

                            <div
                              style={{ color: "#1F2937" }}
                              className="flex-row align-items-center py-12 gap-12"
                            >
                              <p style={{ color: "#6B7280" }}>
                                Decrement Price
                              </p>
                              <p className="flex-row gap-6">
                                ₹{" "}
                                {commaFormat(
                                  sku.min_decreement_price.toString(),
                                )}
                              </p>
                            </div>
                          </div>
                        </div>
                        <img
                          className={` ${!isExpanded && "rotated-arrow"} py-12`}
                          src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20241203190235-chevron-left.svg"
                          alt="arrow-icon"
                          height={16}
                        />
                      </div>
                      {isExpanded && (
                        <div className="expanded-main-container px-8">
                          <div className="expanded-heading-container bidder-grid xetgo-font-tag">
                            <p className="flex-row align-items-center px-12 py-8">
                              S. No.
                            </p>
                            <p className="flex-row align-items-center px-12 py-8">
                              Bidder Name
                            </p>
                            <p className="flex-row align-items-center px-12 py-8">
                              Unit Rate
                            </p>
                            <p className="flex-row align-items-center px-12 py-8">
                              Total Rate
                            </p>
                            <p className="flex-row align-items-center px-12 py-8">
                              Bid Date & Time
                            </p>
                            <p className="flex-row align-items-center px-12 py-8">
                              Bid Status
                            </p>
                            <p className="flex-row align-items-center px-12 py-8">
                              Remarks
                            </p>
                          </div>
                          {bidList[sku.id]?.length > 0 && (
                            <div className="all-bidder-container">
                              {bidList[sku.id].map((bidder, bidderIndex) => (
                                <div
                                  key={bidderIndex}
                                  className="bidder-detail-container bidder-grid xetgo-font-tag"
                                >
                                  <p
                                    style={{ paddingBottom: "17px" }}
                                    className="grid-border-right flex-row align-items-center px-12 py-8"
                                  >
                                    {bidderIndex + 1}
                                  </p>

                                  <p className="grid-border-right flex-row align-items-center px-12 py-8">
                                    {bidder.cname}
                                  </p>
                                  <p className="unit-rate grid-border-right flex-row align-items-center gap-12 px-12 py-8">
                                    ₹{" "}
                                    <span>
                                      {commaFormat(bidder.price.toString())}
                                    </span>
                                  </p>
                                  <p className="total-rate grid-border-right px-12 flex-row align-items-center gap-12 py-8">
                                    ₹{" "}
                                    <span>
                                      {commaFormat(
                                        (
                                          Math.round(
                                            sku.quantity * bidder.price * 100,
                                          ) / 100
                                        ).toString(),
                                      )}
                                    </span>
                                  </p>
                                  <p
                                    className={`grid-border-right flex-row align-items-center gap-2 px-12 py-8`}
                                  >
                                    {formatDateTime(bidder.created_at)}
                                  </p>
                                  <div className="flex-row align-items-center px-12 py-8 grid-border-right">
                                    <p
                                      className={`bidder-status py-4 px-8 ${
                                        bidder.status == "ACCEPTED"
                                          ? "accepted-status"
                                          : bidder.status === "REJECTED"
                                            ? "rejected-status"
                                            : "failed-status"
                                      }`}
                                    >
                                      {bidder.status.toLowerCase()}
                                    </p>
                                  </div>
                                  <p className="flex-row align-items-center px-12 py-8">
                                    {bidder.remark}
                                  </p>
                                </div>
                              ))}
                            </div>
                          )}
                          {bidList[sku.id]?.length === 0 && (
                            <p
                              style={{ color: "#6B7280" }}
                              className="no-bid-state flex-row align-items-center justify-content-center xetgo-font-tag bold p-12"
                            >
                              No bids received
                            </p>
                          )}
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default BidHallHistory;
