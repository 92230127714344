// src/redux/user/userSelector.ts
import { createSelector } from "reselect";
import { RootState } from "../store";
import { UserState } from "./userReducer";
import { isNotNull } from "../../utils/Helper";

// Non-memoized selector function to access the user state from the Redux store
const selectUserState = (state: RootState): UserState => state.user;

// Memoized selector using createSelector
export const selectUser = createSelector(
  [selectUserState],
  (userState) => userState,
);

export const isAuthenticated = createSelector([selectUserState], (userState) =>
  isNotNull(userState.jwtToken),
);
export const selectAuthToken = createSelector(
  [selectUserState],
  (userState) => userState.jwtToken,
);
export const isUserLoading = createSelector(
  [selectUserState],
  (userState) => userState.loading,
);

export const userDetails = createSelector(
  [selectUserState],
  (userState) => userState,
);

export const newNotification = createSelector(
  [selectUserState],
  (userState) => userState.newNotification,
);

export const getUserAddresses = createSelector(
  [selectUserState],
  (userState) => userState.addresses,
);

export const getWarehouseUserAddresses = createSelector(
  [selectUserState],
  (userState) =>
    userState.addresses.filter(
      (address) => address.address_type === "WAREHOUSE",
    ),
);

export const getUserBillingAddresses = createSelector(
  [selectUserState],
  (userState) =>
    userState.addresses.filter((a) => a.address_type === "BILLING"),
);

export const getUserWarehouseAddresses = createSelector(
  [selectUserState],
  (userState) =>
    userState.addresses.filter((a) => a.address_type === "WAREHOUSE"),
);

export const getUserWarehouseAddresseFilterOptions = createSelector(
  [selectUserState],
  (userState) =>
    userState.addresses
      .filter((a) => a.address_type === "WAREHOUSE")
      .map((row) => {
        return {
          id: row.id,
          name: `${row.address_line}, ${row.city}, ${row.state}, ${row.country} ${row.pincode}`,
        };
      }),
);

export const getUserCompanyDetails = createSelector(
  [selectUserState],
  (userState) => userState.company_details,
);

export const selectProfilePercentageCompletion = createSelector(
  [selectUserState],
  (userState) => {
    let percent = 0;
    if (userState.name && userState.phone_number) {
      percent = percent + 10;
    }
    if (
      userState.company_details?.company_type &&
      userState.company_details.nature_of_business
    ) {
      percent = percent + 10;
    }
    if (userState.company_details?.gst_number) {
      percent = percent + 10;
    }
    if (userState.company_details?.company_attachments.gst) {
      percent = percent + 10;
    }
    if (userState.company_details?.company_attachments.pan) {
      percent = percent + 10;
    }
    if (userState.company_details?.pan_number) {
      percent = percent + 10;
    }

    if (
      userState.addresses.filter((a) => a.address_type === "WAREHOUSE").length >
      0
    ) {
      percent = percent + 20;
    }
    if (
      userState.addresses.filter((a) => a.address_type === "BILLING").length > 0
    ) {
      percent = percent + 20;
    }
    return percent;
  },
);
