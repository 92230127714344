import { toast } from "react-toastify";
import { toastOptions } from "../../utils/toast";
import "./EditTeamInfoDialog.scss";
import React, { useState } from "react";
import Filter from "../Filter/Filter";
import { FilterOptionSchema } from "../Filter/filter.data";
import { NewCompanyAddressPayloadSchema } from "../../redux/user/userTypes";
import { useSelector } from "react-redux";
import { userDetails } from "../../redux/user/userSelector";
import { IMemberDetail } from "../../pages/Team/Team";

interface OnSavePayload {
  name?:string
  phone_number?: number,
  designation?: string,
  password?:string,
  email?: string,
}

interface EditTeamInfoDialogProps {
  onCancel: () => void;
  onSave: (payload: OnSavePayload) => void;
  details:IMemberDetail
}

const EditTeamInfoDialog: React.FC<EditTeamInfoDialogProps> = ({
  onCancel,
  onSave,
  details
}) => {
  const userDetail = useSelector(userDetails);
  const [name,setName] = useState(details.name || '')
  const [designation,setDesignation] = useState(details.designation || '')
  const [phoneNumber,setPhoneNumber] = useState(details.phone_number || '')
  const [password,setPassword] = useState(details.password || '')
  const [email,setEmail] = useState(details.email || '')
  const [showPassword,setShowPassword] = useState(false)

  const handleReset = ()=>{
    setName(details.name || '')
    setDesignation(details.designation || '')
    setPhoneNumber(details.phone_number || '')
    setPassword(details.password || '')
    setEmail(details.email || '')
  }
  
  const handleSave = () => {
    const data: OnSavePayload = {};
  
    if (name !== details.name) {
      data.name = name;
    }
    if (email !== details.email) {
      data.email = email;
    }
    if (password !== details.password) {
      data.password = password;
    }
    if (designation !== details.designation) {
      data.designation = designation;
    }
    if (phoneNumber !== details.phone_number) {
      data.phone_number = Number(phoneNumber);
    }
    if(password.length<=0){
      toast.error("Password Cannot Be Empty", toastOptions);
      return
    }
    if(email.length<=0){
      toast.error("Email Cannot Be Empty", toastOptions);
      return
    }
  
    if (Object.keys(data).length === 0) {
      toast.info("No changes to save", toastOptions);
      onCancel();
      return;
    }
  
    onSave(data);
  };
  return (
    <div className="edit-user-panel-information-dialog px-16 py-24 flex-column gap-40">
      <p className="xetgo-font-tag bold">Edit Information</p>

      <div className="edit-user-middle-info-main-container flex-column gap-16">
        <div className="flex-row gap-24">
          <div className="flex-column gap-16 flex-1">
            <div className="flex-column gap-8">
            <p className="xetgo-font-tag">Name</p>
            <input className="information-input xetgo-font-tag px-8 py-6" onChange={(e)=>setName(e.target.value)} value={name} placeholder="Enter Name" />
            </div>
            <div className="flex-column gap-8">
            <p className="xetgo-font-tag bold">Designation</p>
            <input className="information-input xetgo-font-tag px-8 py-6" onChange={(e)=>setDesignation(e.target.value)} value={designation} placeholder="Enter Designation" />
            </div>
          </div>
          <div className="flex-column gap-16 flex-1">
            <div className="flex-column gap-8">
            <p className="xetgo-font-tag">Phone Number</p>
            <input type="number" className="information-input xetgo-font-tag px-8 py-6" onChange={(e)=>setPhoneNumber(e.target.value)} value={phoneNumber} placeholder="Enter Contact" />
            </div>
            <div className="flex-column gap-8">
            <p className="xetgo-font-tag bold">Password</p>
            <div className="password-main-container">
            <input disabled={!showPassword}  className="information-input password-input xetgo-font-tag px-8 py-6 full-width border-box" onChange={(e)=>setPassword(e.target.value)} value={showPassword? password : '************'} placeholder="Enter Password" />
            <img onClick={(e)=>setShowPassword(!showPassword)} className="password-eye-icon cursor-pointer" height={16} width={16} src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20250113110426-eye-off.svg" alt="eye-icon" />
            </div>
            </div>
          </div>
        </div>
        <div className="flex-column gap-8">
          <p className="xetgo-font-tag">Email Id *</p>
          <input className="information-input xetgo-font-tag px-8 py-6" onChange={(e)=>setEmail(e.target.value)} value={email} placeholder="Enter Email" />
        </div>
         </div>




      <div className="edit-user-btns-main-container flex-row align-items-center justify-content-space-between full-width">
        <p onClick={handleReset} className="reset xetgo-font-tag bold cursor-pointer">Reset</p>
        <div className="flex-row gap-12">
          <div onClick={()=>onCancel()} className="cancel-btn px-12 py-8 xetgo-font-tag bold cursor-pointer">Cancel</div>
          <div onClick={handleSave} className="cancel-btn save px-12 py-8 xetgo-font-tag bold cursor-pointer">Save</div>
        </div>
      </div>
    </div>
  );
};

export default EditTeamInfoDialog;
