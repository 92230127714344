import environment from "../environments/environment";

const URLS = {
  USERS: {
    SIGNUP: `${environment.baseUrl}/pacpro/signup`,
    SIGNIN: `${environment.baseUrl}/pacpro/signin`,
    UPDATE_USER_COMPANY_DETAILS: `${environment.baseUrl}/pacpro/update/user_company_details`,
    GET_USER: `${environment.baseUrl}/pacpro/user/authenticated`,
    LOGOUT: `${environment.baseUrl}/pacpro/logout`,
    NEW_ADDRESS: (id: number) =>
      `${environment.baseUrl}/auth/client_address/${id}`,
    NEW_QUOTE: `${environment.baseUrl}/pacpro/quote`,
    COMPANY_ORDER_DETAILS: `${environment.baseUrl}/pacpro/company_order_details`,
  },
  PRODUCTS: {
    ALL_PRODUCTS: `${environment.baseUrl}/pacpro/products/list`,
  },

  QUOTES: {
    GET_QUOTES: `${environment.baseUrl}/pacpro/quotes`,
    GET_QUOTE_DETAILS: (id: number) =>
      `${environment.baseUrl}/pacpro/quote/${id}`,
    UPDATE_QUOTE_PRODUCT: (id: number) =>
      `${environment.baseUrl}/pacpro/quote/${id}/product`,

    DELETE_QUOTE_PRODUCT: (id: number) =>
      `${environment.baseUrl}/pacpro/quote/${id}/product`,
    UPDATE_ASSET_RECORDS: (id: number) =>
      `${environment.baseUrl}/oms/task/${id}/files`,
    DELETE_QUOTE_ASSET_RECORDS: (id: number) =>
      `${environment.baseUrl}/oms/task/${id}/file`,
    UPDATE_QUOTE_DETAILS: (id: number) =>
      `${environment.baseUrl}/pacpro/quote/${id}`,
    ADD_TASK_COMMENT: (id: number) =>
      `${environment.baseUrl}/oms/task/${id}/comment`,
    RAISE_QUOTE: `${environment.baseUrl}/pacpro/quote`,
    SEND_QUOTE_EMAIL: (id: number) =>
      `${environment.baseUrl}/pacpro/quote/${id}/send_report`,
  },
  SKU: {
    OPTIONS: `${environment.baseUrl}/pacpro/sku/list`,
    CREATE_SKU: `${environment.baseUrl}/pacpro/skus`,
    GET_SKU_LIST: `${environment.baseUrl}/pacpro/skus`,
    GET_SKU_TAGS: `${environment.baseUrl}/pacpro/sku/tags`,
    GET_SKU_QUALITY_CHECKS_AND_ATTACHMENTS: (sku_id: number) =>
      `${environment.baseUrl}/pacpro/sku/${sku_id}/quality_checks_and_attachments`,
    ADD_NEW_TAG: `${environment.baseUrl}/pacpro/sku/add_new_tag`,
    UPDATE_SKU_TAG: (id: number) => `${environment.baseUrl}/pacpro/sku/${id}`,
    UPDATE_SKU_ATTACHMENTS: `${environment.baseUrl}/pacpro/skus/update`,
    CREATE_QUALITY_CHECK: `${environment.baseUrl}/pacpro/skus/add_quality_check`,
    GET_SKU_PRODUCT_PROPERTY: `${environment.baseUrl}/pacpro/skus/quality_checks`,
    UPDATE_SKU_PRODUCT_PROPERTY_VALUE: `${environment.baseUrl}/pacpro/skus/quality_checks/update`,
    UPDATE_CLIENT_VALUE: `${environment.baseUrl}/pacpro/skus/quality_checks/update_client_value`,
    GET_SKU_DETAILS: (id: number) => `${environment.baseUrl}/pacpro/sku/${id}`,
    GET_SKU_DELIVERY_SCHEDULE: (id: number) =>
      `${environment.baseUrl}/pacpro/sku/${id}/schedule`,
    UPDATE_SKU_DETAILS: (sku_id: number) =>
      `${environment.baseUrl}/pacpro/sku/${sku_id}`,
    GET_SKU_VENDOR_PRICES: (sku_id: number) =>
      `${environment.baseUrl}/pacpro/sku/${sku_id}/prices`,
    GET_SKU_VENDOR_PRICE_HISTORY: (sku_id: number, price_id: number) =>
      `${environment.baseUrl}/pacpro/sku/${sku_id}/prices/${price_id}/history`,
    VENDOR_WISE_CONSUMPTION: (sku_id: number) =>
      `${environment.baseUrl}/pacpro/sku/${sku_id}/vendor_wise_consumption`,
    SKU_WISE_CONSUMPTION: (sku_id: number) =>
      `${environment.baseUrl}/pacpro/sku/${sku_id}/sku_wise_consumption`,
    GET_SKU_QUALITY_CHECKS: (id: number) =>
      `${environment.baseUrl}/pacpro/sku/${id}/quality_checks`,
    UPDATE_SKU_QUALITY_CHECK: (id: number) =>
      `${environment.baseUrl}/pacpro/sku/${id}/quality_checks`,
    GET_QUALITY_CHECK_LIST: `${environment.baseUrl}/pacpro/skus/quality_check_list`,
    UPDATE_SKU_QUALITY_CHECK_TEST: (id: number) =>
      `${environment.baseUrl}/pacpro/skus/quality_test/${id}`,
    CREATE_SKU_QUALITY_CHECK_TEST: `${environment.baseUrl}/pacpro/skus/quality_test`,
    GET_SKU_ARTWORKS:(id: number) =>`${environment.baseUrl}/pacpro/sku/sku_artworks/${id}`,
    GET_SKU_ARTWORK_COMMENTS:(id: number) =>`${environment.baseUrl}/pacpro/sku/sku_artwork_comments/${id}`,
    ADD_SKU_ARTWORK_COMMENT:(id: number) =>`${environment.baseUrl}/pacpro/sku/create_comment_artwork/${id}`,
    ADD_ARTWORK:(id: number) =>`${environment.baseUrl}/pacpro/${id}/add_artwork/`,
    UPDATE_ARTWORK_STATUS:(id: number) =>`${environment.baseUrl}/pacpro/sku/update_artwork_status/${id}`
  },
  PURCHASE_ORDER: {
    GET_PURCHASE_ORDERS_LIST: `${environment.baseUrl}/pacpro/purchase_orders`,
    CREATE_PURCHASE_ORDER: `${environment.baseUrl}/pacpro/checkout`,
    GET_CLIENT_PO_LIST: (address_id: number) =>
      `${environment.baseUrl}/pacpro/client_purchase_orders/${address_id}`,
    GET_SKU_PO_LIST: (sku_id: number) =>
      `${environment.baseUrl}/pacpro/sku_purchase_orders/${sku_id}`,
    GET_CLIENT_PO_DETAILS: (cpo_id: number) =>
      `${environment.baseUrl}/pacpro/client_purchase_order_details/${cpo_id}`,
    ADD_CLIENT_PO_COMMENT: (id: number) =>
      `${environment.baseUrl}/pacpro/purchase_orders/${id}/comment`,
    ADD_DELIVERY_POD: (id: number) =>
      `${environment.baseUrl}/pacpro/purchase_orders/delivery/${id}`,
    ADD_DELIVERY_SCHEDULE: (id: number) =>
      `${environment.baseUrl}/digifac/purchase_orders/${id}/schedule`,
    DELETE_DELIVERY_SCHEDULE: (po_id: number, id: number) =>
      `${environment.baseUrl}/digifac/purchase_orders/${po_id}/schedule/${id}`,
    UPDATE_DELIVERY_SCHEDULE: (po_id: number, id: number) =>
      `${environment.baseUrl}/digifac/purchase_orders/${po_id}/schedule/${id}`,
  },
  VENDORS: {
    FETCH_VENDORS: `${environment.baseUrl}/pacpro/client_vendors`,
    FETCH_VENDOR_LIST: `${environment.baseUrl}/pacpro/client_vendor/list`,
    FETCH_SKU_LIST: (vendor_id: number) =>
      `${environment.baseUrl}/pacpro/client_vendor/${vendor_id}/sku_list`,
    FETCH_VENDOR_DETAIL: (vendor_id: number) =>
      `${environment.baseUrl}/pacpro/client_vendor/${vendor_id}/detail`,
    FETCH_VENDOR_FORM_DETAIL: (form_id: number) =>
      `${environment.baseUrl}/digifac/vendor/client_form/${form_id}`,
    ADD_VENDOR: `${environment.baseUrl}/pacpro/add_client_vendors`,
    FETCH_VENDOR_STATUSES: (id: number) =>
      `${environment.baseUrl}/pacpro/quote/vendor/status/${id}`,
    FETCH_VENDOR_COMMENTS: (quote_id: number, vendor_id: number) =>
      `${environment.baseUrl}/pacpro/quote/${quote_id}/vendor/${vendor_id}/comment`,
    UPDATE_VENDOR_STATUS: (id: number) =>
      `${environment.baseUrl}/pacpro/quote/${id}/product`,
    ADD_VENDOR_COMMENT: (quote_id: number, vendor_id: number) =>
      `${environment.baseUrl}/pacpro/quote/${quote_id}/vendor/${vendor_id}/comment`,
    UPDATE_ASSET_RECORDS: (quote_id: number) =>
      `${environment.baseUrl}/pacpro/quote/${quote_id}/asset_record`,
    UPDATE_STATUS: `${environment.baseUrl}/pacpro/update_quo`,
    REMOVE_ASSET_RECORDS: (quote_id: number) =>
      `${environment.baseUrl}/pacpro/quote/${quote_id}/asset_record`,
    FETCH_VENDOR_PRICING: (vendor_id: number) =>
      `${environment.baseUrl}/pacpro/client_vendor/${vendor_id}/pricing`,
    FETCH_VENDOR_PRICING_HISTORY: (vendor_id: number) =>
      `${environment.baseUrl}/pacpro/client_vendor/${vendor_id}/pricing_history`,
    FETCH_VENDOR_QUOTES: (vendor_id: number) =>
      `${environment.baseUrl}/pacpro/client_vendor/${vendor_id}/quotes`,
    FETCH_VENDOR_PURCHASE_ORDERS: (vendor_id: number) =>
      `${environment.baseUrl}/pacpro/client_vendor/${vendor_id}/purchase_orders`,
    LOCATION_SKU_CONSUMPTION: (vendor_id: number) =>
      `${environment.baseUrl}/pacpro/client_vendor/${vendor_id}/location_sku_consumption`,
    LOCATION_AMOUNT: (vendor_id: number) =>
      `${environment.baseUrl}/pacpro/client_vendor/${vendor_id}/total_local_billing`,
    GET_VENDOR_DELIVERY_SCHEDULE: (vendor_id: number) =>
      `${environment.baseUrl}/pacpro/client_vendor/${vendor_id}/delivery_schedule`,
    GET_VENDOR_FORMS: `${environment.baseUrl}/pacpro/client_vendor/vendor_forms_list`,
    SEND_ADD_VENDORS_REQUEST: `${environment.baseUrl}/pacpro/client_vendor/add_vendors_request`,
  },
  DELIVERY: {
    DELIVERY_SCHEDULE_DATA: `${environment.baseUrl}/pacpro/delivery_schedules`,
    UPDATE_DELIVERY_SCHEDULE: (po_id: number, id: number) =>
      `${environment.baseUrl}/pacpro/purchase_orders/delivery/${id}`,
  },
  CHECKLIST: {
    GET_PROPERTY_VALUES: `${environment.baseUrl}/digifac/get_delivery_product_check_list`,
    UPDATRE_CLIENT_VALUE: `${environment.baseUrl}/pacpro/purchase_orders/delivery_client_update`,
  },
  QUALITY_CHECK: {
    GET_QUALITY_CHECK_DETAILS: (prpd_id: number) =>
      `${environment.baseUrl}/pacpro/skus/${prpd_id}/quality_check_details`,
    UPDATE_QUALITY_CHECK_DETAILS: (prpd_id: number) =>
      `${environment.baseUrl}/pacpro/skus/${prpd_id}/quality_check_details`,
    UPDATE_DELIVERED_QUALITY_CHECK: (id: number) =>
      `${environment.baseUrl}/pacpro/skus/${id}/delivered_quality_check`,
    UPDATE_QUALITY_CHECK_TEST: (id: number) =>
      `${environment.baseUrl}/pacpro/skus/${id}/delivered_quality_check_test`,
  },
  FORMS: {
    GET_VENDOR_FORMS: `${environment.baseUrl}/pacpro/client/vendor_forms`,
    CREATE_VENDOR_FORM: `${environment.baseUrl}/pacpro/add_vendor_form`,
    GET_VENDOR_FORM_DETAIL: (id: number) =>
      `${environment.baseUrl}/pacpro/form_details/${id}`,
  },
  AUCTION: {
    LIST: `${environment.baseUrl}/pacpro/auctions`,
    GET_VENDOR_AND_FORMS: `${environment.baseUrl}/pacpro/client/vendor_and_forms`,
    CREATE: `${environment.baseUrl}/pacpro/create_auction`,
    DETAILS: (id: string) => `${environment.baseUrl}/pacpro/auctions/${id}`,
    STATUS: (id: string) =>
      `${environment.baseUrl}/digifac/auctions/${id}/status`,
    VENDORS: (id: string) =>
      `${environment.baseUrl}/pacpro/auctions/${id}/vendors`,
    VENDOR_DETAIL: (id: string, vendor_id: string) =>
      `${environment.baseUrl}/pacpro/auctions/${id}/vendors/${vendor_id}`,
    VENDOR_DETAIL_FORM: (id: string, vendor_id: string) =>
      `${environment.baseUrl}/pacpro/auctions/${id}/vendors/${vendor_id}/form`,
    VENDOR_COMMENT: (id: string, vendor_id: string) =>
      `${environment.baseUrl}/pacpro/auctions/${id}/vendors/${vendor_id}/comment`,
    BID_SKUS: (id: string) =>
      `${environment.baseUrl}/pacpro/auctions/${id}/bid/skus`,
    BID_RESULT: (id: string, sku_id: number) =>
      `${environment.baseUrl}/pacpro/auctions/${id}/bid/skus/${sku_id}`,
    BID_REPORT: (id: number) =>
      `${environment.baseUrl}/pacpro/auctions/${id}/reports`,
    VENDOR_BID_RESULT: (id: string, vendor_id: string) =>
      `${environment.baseUrl}/pacpro/auctions/${id}/vendor/${vendor_id}/bid`,
  },
  FILES: {
    UPLOAD: `${environment.baseUrl}/files/uploads`,
  },
  NEGOTIATIONS:{
    CREATE_NEGOTIATION:`${environment.baseUrl}/pacpro/create_negotiation`,
    GET_NEGOTIATIONS:`${environment.baseUrl}/pacpro/negotiations`,
    NEGOTIATION_PRICES:`${environment.baseUrl}/pacpro/negotiation_prices`,
    APPROVE_NEGOTIATION:`${environment.baseUrl}/pacpro/approve_negotiation`,
  }
};

export default URLS;
