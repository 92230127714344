import React, { useEffect, useState } from "react";
import "./TeamDetail.scss";
import { useSelector } from "react-redux";
import { userDetails } from "../../redux/user/userSelector";
import Filter from "../../components/Filter/Filter";
import { FilterOptionSchema } from "../../components/Filter/filter.data";
import { toast } from "react-toastify";
import { toastOptions } from "../../utils/toast";
import OutsideAlerter from "../../utils/OutsideClickDetector";
import ApiService from "../../services/apiService";
import URLS from "../../urls/Urls";
import { useNavigate, useParams } from "react-router-dom";
import { formatDate } from "../../utils/Helper";
import XetToggle from "../../components/xet-toggle/XetToggle";

interface IMemberData {
  active:boolean
  avatar:string | null
  created_at: string
  member_id:number
  name: string
}
interface ITeamDetail {
  team:{
    active: boolean
    company_id:number
    created_at: string
    description: string
    id: number
    name: string
    updated_at:string
  }
  members:IMemberData[]
}

const TeamDetail: React.FC = () => {
  const {id} = useParams()
  const navigate=useNavigate()
  const statuses = [
    {
      id:1,
      name:'Active',
      code:'ACTIVE'
    },
    {
      id:2,
      name:'Inactive',
      code:'IN_ACTIVE'
    },
  ]
 const userDetail = useSelector(userDetails)
 const [isAdmin,setIsAdmin] = useState(true);
 const [isEditing,setIsEditing] = useState(false)
 const [inActiveMembers,setInactiveMembers] = useState<number[]>([])
 const [activeMembers,setActiveMembers] = useState<number[]>([])
 const [deleteMembers,setDeleteMembers] = useState<number[]>([])
 const [addMembersIds,setAddMembersIds] = useState<number[]>([])
 const [statusDropdownId,setStatusDropdownId] = useState(-100)
 
 const [teamDetails,setTeamDetails] = useState<ITeamDetail | null>(null);
 const [oldTeamDetail,setOldTeamDetail] = useState<ITeamDetail | null>(null);
   const [teamName,setTeamName] = useState( '')
   const [description,setDescription] = useState( '')
    const [allMembers,setAllMembers] = useState<FilterOptionSchema[]>([])
    const [membersData,setMembersData] = useState<IMemberData[]>([])
    
   const [selectedMembers,setSelectedMembers] = useState<FilterOptionSchema[]>([])
   const [status,setStatus] = useState( false)
   const [selectedStatus,setSelectedStatus] = useState<FilterOptionSchema[]>( [])

   useEffect(()=>{
    fetchTeamMemberDetails()
   },[])

   useEffect(()=>{
    fetchCompanyMembers()
  },[])

  const fetchTeamMemberDetails =async ()=>{
    try {
    
      const { data } = await ApiService().client.get(
        URLS.TEAM.TEAM_DETAILS(userDetail.company_id ||-1 ,Number(id) ));
        console.log('DATAAFrom backend::',data)
        setTeamName(data.team.name)
        setDescription(data.team.description)
        setStatus(data.team.active)
        if(data.team.active){
          setSelectedStatus([statuses[0]])
        }else{
          setSelectedStatus([statuses[1]])
        }
        setTeamDetails(data)
        setOldTeamDetail(data)
  } catch (error) {
    console.error("Error Fetching Team Details", error);
  }
  }

  const fetchCompanyMembers =async()=>{
    try {
    
      const { data } = await ApiService().client.get(
        URLS.TEAM.TEAM_MEMBERS(userDetail.company_id ||-1));
      const memberData = data.members.map((member:any) => ({
        id: member.member_id,
        name: member.name,
      }))
      setAllMembers(memberData)
     setMembersData(data.members)
  } catch (error) {
    console.error("Error Fetching Team members", error);
  }
  }

 const handleReset=()=>{
 if(teamDetails){
  setTeamName(teamDetails?.team.name)
  setDescription(teamDetails?.team.description)
  setStatus(teamDetails?.team.active)
  if(teamDetails.team.active){
    setSelectedStatus([statuses[0]])
  }else{
    setSelectedStatus([statuses[1]])
  }
  setTeamDetails(oldTeamDetail)
  setDeleteMembers([])
  setAddMembersIds([])
  if(oldTeamDetail){
    const data = oldTeamDetail?.members.map((member, index) => ({
      id: member.member_id,
      name: member.name, 
      active:member.active
    }));
    setSelectedMembers(data)
  }
 }

 }

 const onCancel =()=>{
  setIsEditing(false)
  handleReset()
 }


 const handleSave = async () => {
  if (selectedStatus.length === 0) {
    toast.error("Please Select Status", toastOptions);
    return;
  }
  if (selectedMembers.length === 0) {
    toast.error("Please Add Member", toastOptions);
    return;
  }

  const params: any = {
    add_members: [],
    inactive_members: inActiveMembers,
    active_members: activeMembers,
  };

  let isUpdated = false;

  if (teamDetails?.team.name !== teamName) {
    params.name = teamName;
    isUpdated = true;
    setTeamDetails((prev) =>
      prev
        ? {
            ...prev,
            team: {
              ...prev.team,
              name: teamName,
            },
          }
        : null
    );
  }

  if (teamDetails?.team.description !== description) {
    params.description = description;
    isUpdated = true;
    setTeamDetails((prev) =>
      prev
        ? {
            ...prev,
            team: {
              ...prev.team,
              description: description,
            },
          }
        : null
    );
  }

  if (teamDetails?.team.active !== status) {
    params.active = status;
    isUpdated = true;
    setTeamDetails((prev) =>
      prev
        ? {
            ...prev,
            team: {
              ...prev.team,
              active: status,
            },
          }
        : null
    );
  }

  if(addMembersIds.length > 0 ){
    params.add_members =addMembersIds
    isUpdated=true
    
  }

    if(deleteMembers.length >0){
      params.delete_members = deleteMembers
      isUpdated = true
    }

    if(inActiveMembers.length >0){
      params.inactive_members = inActiveMembers
      isUpdated = true
    }

     if(activeMembers.length >0){
      params.active_members = activeMembers
      isUpdated = true
    }

  if (!isUpdated) {
    toast.info("No changes detected.", toastOptions);
    return;
  }

  try {
    const { data } = await ApiService().client.put(
      URLS.TEAM.UPDATE_TEAM_DETAILS(Number(id) || -1),
      params
    );
    setIsEditing(false);
    setOldTeamDetail(teamDetails)
    toast.success("Team details updated successfully", toastOptions);
  } catch (error) {
    console.error("Error Updating Team Details:", error);
    toast.error("Failed to update team details. Please try again.", toastOptions);
  }
};

const handleStatusChange = (memberID: number, newStatus: boolean) => {
  const member = teamDetails?.members.find((member) => member.member_id === memberID);

  if (member?.active === newStatus) {
    return;
  }
  setOldTeamDetail(teamDetails)

  setTeamDetails((prev) => {
    if (!prev) {
      return null;
    }

    return {
      ...prev,
      members: prev.members.map((member) =>
        member.member_id === memberID
          ? { ...member, active: newStatus }
          : member
      ),
    };
  });

  allMembers

  if (newStatus) {
    setInactiveMembers((prev) => prev.filter((id) => id !== memberID)); 
    setActiveMembers((prev) => {
      if (prev.some((id) => id === memberID)) {
        return prev; 
      } else {
        return [...prev, memberID]; 
      }
    });
  } else {
    setActiveMembers((prev) => prev.filter((id) => id !== memberID)); 
    setInactiveMembers((prev) => {
      if (prev.some((id) => id === memberID)) {
        return prev; 
      } else {
        return [...prev, memberID]; 
      }
    });
  }

  setStatusDropdownId(-100);
};

const handleEditClicked = () => {
  setIsEditing(true);
  const data = teamDetails?.members.map((member, index) => ({
    id: member.member_id,
    name: member.name, 
  }));
  setSelectedMembers(data || []);
};

const handleMemberSelection = (value: FilterOptionSchema[]) => {
  const updatedDeleteMembers: number[] = [];
  const updatedAddMembersIds: number[] = [];

  teamDetails?.members.forEach((member) => {
    const isMemberSelected = value.some((val) => val.id === member.member_id);
    if (!isMemberSelected) {
      updatedDeleteMembers.push(member.member_id);
    }
  });

  value.forEach((val) => {
    const isMemberAlreadyPresent = teamDetails?.members.some(
      (member) => member.member_id === val.id
    );

    if (!isMemberAlreadyPresent) {
      updatedAddMembersIds.push(val.id);
    }
  });

  setTeamDetails((prev) => {
    if (!prev) return null;

    const newMembers = updatedAddMembersIds.map((memberId) => {
      const memberDetail = value.find((val) => val.id === memberId);
      const nextNegativeId =
        prev.members.filter((m) => m.member_id < 0).length + 1;

      return memberDetail
        ? {
            active: true,
            avatar: '',
            created_at: new Date().toISOString(),
            member_id: -nextNegativeId,
            name: memberDetail.name,
          }
        : null;
    }).filter((memberDetail) => memberDetail !== null) as typeof prev.members;

    return {
      ...prev,
      members: [...prev.members, ...newMembers],
    };
  });

  setSelectedMembers(value);
  setDeleteMembers(updatedDeleteMembers);
  setAddMembersIds(updatedAddMembersIds);
};

  return (
    <div className="team-details-main-page-container full-width flex-column gap-20">
   <div className="full-height border-box flex-column">
    <div className="flex-1 flex-column">
    <div className="upper-bar">
      <p className="xetgo-font-tag bold px-16 py-12"> <span onClick={()=>navigate("/teams")} className="sub-title  cursor-pointer xetgo-font-tag">{userDetail.name}</span> <span>/ {teamDetails?.team.name}</span></p>
    </div>
   <div className="lower-main-container-wrapper-team-detail overflow-scroll p-12 flex-1 border-box">
   
   <div className="user-panel-lower-main-container flex-column gap-14  p-12 full-height border-box">
   <div  className="upper-bar-back-navigation-mob xetgo-font-tag bold p-12">
      <div onClick={()=>navigate("/teams")} className="flex-row align-items-center gap-16 cursor-pointer"><img  className="cursor-pointer"  src="https://xetoolbucket.s3.ap-south-1.amazonaws.com/1690551659404-left-arrow.svg" alt="back-icon" />
      <p  className="cursor-pointer">Back</p></div>
    </div>
    <div className="user-panel-inner-main-container flex-1 full-height border-box border-box flex-column gap-16 px-24 py-8">
     
      <div className="team-information-main-container flex-column gap-24">
       {!isEditing? <div className="team-detail-info-upper-container  flex-column gap-24">
          <div className="team-info-upper-container flex-row align-items-center">
            <p className="team-name team-name-mob xetgo-font-tag flex-row flex-1"> <span className={`in-active-dot-mob p-4 ${teamDetails?.team.active && ' dot-mob-team-name'}`}></span>  <span>{teamDetails?.team.name}</span></p>
            <div className="team-info-right-container flex-row align-items-center">
             <div className="flex-row gap-40 align-items center">
             <p className={`team-status-mob xetgo-font-tag flex-row gap-4 align-items-center in-active-status bold ${teamDetails?.team.active && 'status-active'}`}>
             <span className={`dot p-2 ${teamDetails?.team.active && 'active-dot'}`}></span>{teamDetails?.team.active ? 'Active' : 'Inactive'}</p>
             <p className="team-creation-date  xetgo-font-tag">{formatDate(teamDetails?.team.created_at || '')}</p>
             </div>
             {isAdmin&&<div onClick={handleEditClicked} className="edit-btn flex-row align-items-center gap-4 cursor-pointer justify-content-center px-8 py-4"><img height={14} width={14} src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20250108082347-pen-square.svg" alt="edit-icon" />
          <p className="xetgo-font-tag bold">Edit</p>
          </div>}
          {isAdmin&& <div className="pensquare-edit-mob flex-row align-items-base-line full-height">
          <img onClick={handleEditClicked}  className="cursor-pointer" height={18} width={18} src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20250108082347-pen-square.svg" alt="edit-icon" />
          </div>}
            </div>
          </div>
          <p className="xetgo-font-tag">{teamDetails?.team.description}</p>
        </div>: 
         <div className="team-detail-edit-user-middle-info-main-container flex-column gap-16 p-12">
         <div className="flex-column gap-24">
           <div className="flex-column gap-16 flex-1">
             <p className="xetgo-font-tag">Team Name</p>
             <input className="information-input xetgo-font-tag px-8 py-6" onChange={(e)=>setTeamName(e.target.value)} value={teamName} placeholder="Enter Name" />
             </div>
             <div className="flex-column gap-16 flex-1">
             <p className="xetgo-font-tag">Description</p>
             <textarea className="information-input description-input border-box xetgo-font-tag px-8 py-6" onChange={(e)=>setDescription(e.target.value)} value={description} placeholder="Enter Description"></textarea>
             </div>
         </div>
         <div className="flex-column gap-8">
           <p className="xetgo-font-tag">Status</p>
              <Filter
          option={{
            key: "status",
            name: "Status",
          }}
          optionList={statuses}
          multipleSelection={false}
          onSelection={(val, _option) => {
            setSelectedStatus(val);
              if(val.length > 0){
                if(val[0].id == 1){
                  setStatus(true)
                }else{
                  setStatus(false)
                }
              }
          }}
          preSelected={selectedStatus}
          mode="showNames"
          showPlaceholder={false}
          showId={false}
          direction="left"
          addNew={false}
          classname="information-input select-status-filter border-box xetgo-font-tag px-8 py-6"
          showDescription={false}
          showSearch={false}
        />
         </div>
         <div className="flex-column gap-8">
           <p className="xetgo-font-tag">Members</p>
               <Filter
            option={{
              key: "assignees",
              name: "Member",
            }}
            optionList={allMembers }
            multipleSelection={true}
            onSelection={(val, _option) => {
              handleMemberSelection(val)
            }}
            preSelected={selectedMembers}
            mode="showNames"
            showPlaceholder={true}
            direction="left"
            addNew={false}
            classname="information-input member-filter border-box xetgo-font-tag"
          />
         </div>
          </div>
        }
        <div className="all-teams-main-container px-8">
          <div className="heading-team member-grid xetgo-font-tag bold">
            <p className="px-20 py-12">Members</p>
            <p className={`joined-heading px-20 py-12 ${isEditing && isAdmin&&'hide-joined-while-editing-mob'}`}>Joined</p>
            <p className={`active-heading-mob px-20 py-12`}>Active</p>
            <p className=" hide-status-cell-mob px-20 py-12">Status</p>
          </div>
          {teamDetails && teamDetails.members.length > 0 && (
  <div className="all-members">
    {teamDetails.members.map((member, index) => {
      if (deleteMembers.includes(member.member_id)) {
        return null;
      }

      return (
        <div key={index} className="member-container member-grid">
          <div className="px-20 py-12 flex-row align-items-center gap-10 xetgo-font-tag bold">
            <img
              className={`avatar ${!member.active && 'inactive-avatar'}`}
              height={24}
              width={24}
              src={member.avatar || ''}
              alt="avatar"
            />
            <p>{member.name}</p>
          </div>

        
          <p className={`member-joined-mob px-20 py-12 flex-row align-items-center xetgo-font-tag bold ${isEditing && isAdmin&&'hide-joined-while-editing-mob'}`}>
            {formatDate(member.created_at)}
          </p>

      
          {isAdmin && isEditing ? (
            <div className="status-dropdown-main-container  px-20 py-12 flex-row align-items-center">
              <span
                onClick={() =>
                  setStatusDropdownId(
                    statusDropdownId === member.member_id ? -100 : member.member_id
                  )
                }
                className={`status px-8 py-2 xetgo-font-tag flex-row gap-4 cursor-pointer align-items-center justify-content-center ${
                  member.active ? 'status-active' : ''
                }`}
              >
                <img
                  height={12}
                  width={12}
                  src={
                    member.active
                      ? 'https://d2k6zobmg5lufr.cloudfront.net/assets%2F20250116064439-chevron-down.svg'
                      : 'https://d2k6zobmg5lufr.cloudfront.net/assets%2F20250116064345-chevron-down.svg'
                  }
                  alt="dropdown-icon"
                />
                {member.active ? 'Active' : 'Inactive'}
              </span>
              {member.member_id === statusDropdownId && (
                <OutsideAlerter
                  className="status-dropdown-container"
                  action={() => setStatusDropdownId(-100)}
                >
                  <div className="xetgo-font-tag bolder p-4">
                    <p
                      onClick={() => handleStatusChange(member.member_id, true)}
                      style={{ color: '#3CE316' }}
                      className="active-dropdown flex-row align-items-center px-8 py-4 cursor-pointer"
                    >
                      Active
                    </p>
                    <p
                      onClick={() => handleStatusChange(member.member_id, false)}
                      style={{ color: '#99A496' }}
                      className="active-dropdown flex-row align-items-center cursor-pointer px-8 py-4"
                    >
                      Inactive
                    </p>
                  </div>
                </OutsideAlerter>
              )}
            </div>
          ) : (
            <p className="px-20 py-12 flex-row align-items-center  hide-status-cell-mob">
              <span
                className={`status px-8 py-2 xetgo-font-tag flex-row gap-4 align-items-center justify-content-center ${
                  member.active ? 'status-active' : ''
                }`}
              >
                <span
                  className={`dot p-2 ${member.active ? 'active-dot' : ''}`}
                ></span>
                {member.active ? 'Active' : 'Inactive'}
              </span>
            </p>
          )}

          {isAdmin&& isEditing&& <div className="status-dropdown-container-mob px-20 py-12 flex-row align-items-center">
            <XetToggle
            onToggle={(value: boolean) =>
              handleStatusChange(member.member_id, value)
            }
            toggleValue={member.active}
            backgroundColor="#f3f4f6"
            disableColor="#FF5252"
            enableColor="#00C414"
          />
            </div>}
        </div>
      );
    })}
  </div>
)}

        </div>
      </div>
    </div>
   </div>
   </div>
    </div>

    {isEditing && <div className="edit-user-btns-main-container flex-row align-items-center border-box justify-content-space-between full-width p-16">
        <p onClick={handleReset} className="reset xetgo-font-tag bold cursor-pointer">Reset</p>
        <div className="btns-container flex-row gap-12">
          <div onClick={()=>onCancel()} className="cancel-btn cancel-btn-mob px-12 py-8 xetgo-font-tag bold cursor-pointer">Cancel</div>
          <div onClick={handleSave} className="cancel-btn save px-12 py-8 xetgo-font-tag bold cursor-pointer">Save</div>
        </div>
      </div>}
   </div>
          
  </div>
  );
};

export default TeamDetail;
