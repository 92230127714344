// ToastContext.js
import { createContext, ReactChild, useReducer } from "react";
import ToastsContainer from "../components/ToastContainer/ToastContainer";
import { toastReducer } from "../redux/toastReducer";

export interface IToastContext {
  success: (title: string, msg: string) => void;
  info: (title: string, msg: string) => void;
  remove: (id: number) => void;
}

export const ToastContext = createContext<IToastContext>({
  success: (title: string, msg: string) => {},
  info: (title: string, msg: string) => {},
  remove: (id: number) => {},
});

const initialState = {
  toasts: [],
};

export const ToastContextProvider: React.FC<{ children: ReactChild }> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(toastReducer, initialState);

  const addToast = (
    type: "success" | "info",
    message: string,
    title: string,
  ) => {
    const id = Math.floor(Math.random() * 10000000);
    dispatch({ type: "ADD_TOAST", payload: { id, message, type, title } });
  };

  const success = (title: string, message: string) => {
    addToast("success", message, title);
  };

  const info = (title: string, message: string) => {
    addToast("info", message, title);
  };

  const remove = (id: number) => {
    dispatch({
      type: "DELETE_TOAST",
      payload: { id, message: "", type: "info", title: "" },
    });
  };

  const value = { success, info, remove };

  return (
    <ToastContext.Provider value={value}>
      <ToastsContainer toasts={state.toasts} position="top-right" />
      {children}
    </ToastContext.Provider>
  );
};
