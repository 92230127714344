import SignUp from "../pages/SignUp/SignUp";
import SignIn from "../pages/SignIn/SignIn";
import QuoteEnquiry from "../pages/QuoteEnquiry/QuoteEnquiry";
import QuoteDetails from "../pages/QuoteDetails/QuoteDetails";
import SkuList from "../pages/SkuList/SkuList";
import PurchaseOrders from "../pages/PurchaseOrders/PurchaseOrders";
import CheckoutPurchaseOrders from "../pages/CheckoutPurchaseOrders/CheckoutPurchaseOrders";
import ClientPurchaseOrderStatus from "../pages/PurchaseOrderStatus/ClientPurchaseOrderStatus";
import CompanyProfile from "../pages/CompanyProfile/CompanyProfile";
import ClientPurchaseOrderDetail from "../pages/ClientPurchaseOrderDetail/ClientPurchaseOrderDetail";
import Vendor from "../pages/Vendor/Vendor";
import DeliveryCalendar from "../pages/DeliveryCalendar/DeliveryCalendar";
import SkuDetail from "../pages/SkuDetail/SkuDetail";
import VendorDetail from "../pages/VendorDetail/VendorDetail";
import QualityCheck from "../pages/QualityCheck/QualityCheck";
import QualityCheckDetail from "../pages/QualityCheckDetail/QualityCheckDetail";
import VendorForms from "../pages/VendorForms/VendorForms";
import CreateVendorForm from "../pages/CreateVendorForm/CreateVendorForm";
import VendorFormDetail from "../pages/VendorFormDetail/VendorFormDetailPage";
import Quotes from "../pages/Quotes/Quotes";
import Auction from "../pages/Auction/Auction";
import AuctionDetail from "../pages/AuctionDetailPage/AuctionDetailPage";
import CreateAuctionForm from "../pages/CreateAuctionForm/CreateAuctionForm";
import AuctionVendorDetailPage from "../pages/AuctionVendorDetailPage/AuctionVendorDetailPage";
import BidHall from "../pages/BidHall/BidHall";
import NegotiationList from "../pages/NegotiationList/NegotiationList";

const routes = [
  {
    path: "/signup",
    Component: SignUp,
    protected: false,
  },
  {
    path: "/signin",
    Component: SignIn,
    protected: false,
  },
  {
    path: "/quote/raise",
    Component: QuoteEnquiry,
    protected: true,
  },
  {
    path: "/quotes",
    Component: Quotes,
    protected: true,
  },
  {
    path: "/quotes/:id",
    Component: QuoteDetails,
    protected: true,
  },
  {
    path: "/purchase-order/orders",
    Component: PurchaseOrders,
    protected: true,
  },
  {
    path: "/purchase-orders",
    Component: ClientPurchaseOrderStatus,
    protected: true,
  },
  {
    path: "/purchase-orders/:id",
    Component: ClientPurchaseOrderDetail,
    protected: true,
  },
  {
    path: "/skus",
    Component: SkuList,
    protected: true,
  },
  {
    path: "/skus/:id",
    Component: SkuDetail,
    protected: true,
  },
  {
    path: "/checkout",
    Component: CheckoutPurchaseOrders,
    protected: true,
  },
  {
    path: "/profile",
    Component: CompanyProfile,
    protected: true,
  },
  {
    path: "/vendors",
    Component: Vendor,
    protected: true,
  },
  {
    path: "/forms",
    Component: VendorForms,
    protected: true,
  },
  {
    path: "/forms/new",
    Component: CreateVendorForm,
    protected: true,
  },
  {
    path: "/vendor/details/:id",
    Component: VendorDetail,
    protected: true,
  },
  {
    path: "/vendor-form/detail/:id",
    Component: VendorFormDetail,
    protected: true,
  },
  {
    path: "/delivery-calendar",
    Component: DeliveryCalendar,
    protected: true,
  },
  {
    path: "/quality-checks",
    Component: QualityCheck,
    protected: true,
  },
  {
    path: "/quality-checks/detail/:id",
    Component: QualityCheckDetail,
    protected: true,
  },
  {
    path: "/auctions",
    Component: Auction,
    protected: true,
  },
  {
    path: "/auctions/:id",
    Component: AuctionDetail,
    protected: true,
  },
  {
    path: "/auctions/:id/vendors",
    Component: AuctionDetail,
    protected: true,
  },
  {
    path: "/auctions/new",
    Component: CreateAuctionForm,
    protected: true,
  },
  {
    path: "/auctions/:id",
    Component: AuctionDetail,
  },
  {
    path: "/auctions/:auctionId/vendors/:id",
    Component: AuctionVendorDetailPage,
    protected: true,
  },
  {
    path: "/auctions/:id/bid-hall",
    Component: AuctionDetail,
    protected: true,
  },
  {
    path: "/auctions/:id/bid-history",
    Component: AuctionDetail,
    protected: true,
  },
  {
    path: "/auctions/:auctionId/vendors/:id/:tab",
    Component: AuctionVendorDetailPage,
    protected: true,
  },
  {
    path: "/negotiations",
    Component: NegotiationList,
    protected: true,
  },
];

export default routes;
