import URLS from "../urls/Urls";
import ApiService from "./apiService";

const uploadFile = async (files: Blob[], names: string[]) => {
  try {
    const formData = new FormData();
    files.forEach((file, index) => {
      formData.append("files[]", file);
      const displayName = names[index]?.trim();
      if (displayName) {
        formData.append("displayNames[]", displayName);
      }
    });
    const { data } = await ApiService().client.post(
      URLS.FILES.UPLOAD,
      formData,
    );
    if (data) {
      console.log("Data: ", data);
      return data.data;
    }
  } catch (e) {
    console.error(e);
  }
};

export { uploadFile };
