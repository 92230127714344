import { FilterOptionSchema } from "../components/Filter/filter.data";

// Function to check if a value is null
export function isNull(value: unknown): boolean {
  return value == null;
}

// Function to check if a value is not null
export function isNotNull(value: unknown): boolean {
  return value != null;
}

// Function to check if a value is undefined
export function isUndefined(value: unknown): boolean {
  return typeof value === "undefined";
}

// Function to check if a value is not undefined
export function isNotUndefined(value: unknown): boolean {
  return typeof value !== "undefined";
}

// Function to check if a value is null or undefined
export function isNullOrUndefined(value: unknown): boolean {
  return value == null || typeof value === "undefined";
}

// Function to check if a value is not null or undefined
export function isNotNullOrUndefined(value: unknown): boolean {
  return value != null && typeof value !== "undefined";
}

export function round(value: number, digit: number): number {
  return Math.round(value * Math.pow(10, digit)) / Math.pow(10, digit);
}

export function FinalPrice(base_price: number, margin: number) {
  return (base_price * 100) / (100 - margin);
}

export function formatValue(value: number) {
  if (value === 0) {
    return "0";
  }
  if (value > 10000000) {
    return round(value / 10000000, 2) + " Cr";
  }
  if (value > 100000) {
    return round(value / 100000, 2) + " L";
  }
  return round(value / 1000, 2) + " k";
}

export function commaFormat(value: string) {
  if (isNullOrUndefined(value)) {
    return;
  }
  value = value.toString();
  let result = "";
  const commas = [3, 5, 7, 9, 11];
  let curIndex = 0;
  let decimal = null;
  if (value?.indexOf(".") > -1) {
    const split = value.split(".");
    value = split[0];
    decimal = split[1];
  }
  value
    .split("")
    .reverse()
    .forEach((val, index) => {
      if (index === commas[curIndex]) {
        result = result + "," + val;
        curIndex = curIndex + 1;
      } else {
        result = result + val;
      }
    });
  if (decimal != null) {
    return result.split("").reverse().join("") + "." + decimal;
  }
  return result.split("").reverse().join("");
}

export const formatDate = (actualDelivery: string, dashed?: boolean) => {
  if (actualDelivery == null) {
    return "";
  }
  const date = new Date(actualDelivery);
  const options = {
    day: "2-digit" as const,
    month: "2-digit" as const,
    year: "numeric" as const,
  };
  const formattedDate = date.toLocaleDateString("en-IN", options);
  return dashed ? formattedDate.replace(/\//g, "-") : formattedDate;
};

export const formatDateTime = (actualDelivery: string) => {
  if (actualDelivery == null) {
    return "";
  }
  const date = new Date(actualDelivery);
  const options = {
    day: "2-digit" as const,
    month: "2-digit" as const,
    year: "numeric" as const,
  };
  const formattedDate =
    date.toLocaleDateString("en-IN", options) +
    " " +
    date.toLocaleTimeString("en-IN");
  return formattedDate.replace(/\//g, "-");
};

export const iconList = {
  pdf: "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20241204053638-file-text.svg",
  xlsx: "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20231012114327-document.svg",
  commonFile:
    "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240119130001-file-text.svg",
};

export const getFileIcon = (file: { name: string; url?: string }) => {
  if (file.url) {
    if (file.url.endsWith(".pdf")) {
      return iconList.pdf;
    }
    if (file.url.endsWith(".xlsx") || file.url.endsWith(".csv")) {
      return iconList.xlsx;
    }
    if (
      file.url.endsWith(".svg") ||
      file.url.endsWith(".webp") ||
      file.url.endsWith(".png") ||
      file.url.endsWith(".jpeg") ||
      file.url.endsWith(".jpg")
    ) {
      return file.url;
    }
    return iconList.commonFile;
  }
  return iconList.pdf;
};

export const getLast12MonthList = () => {
  const monthName: string[] = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const result: FilterOptionSchema[] = [];
  const d = new Date();
  d.setDate(1);
  for (let i = 0; i <= 11; i++) {
    result.push({
      id: i,
      name: monthName[d.getMonth()],
    });
    d.setMonth(d.getMonth() - 1);
  }
  return result;
};
