import React, { useEffect, useState } from "react";
import "./ArtworkDialog.scss";
import { useDispatch } from "react-redux";
// import { sampleAddressStart } from "../../redux/oms/omsActions";
import ApiService from "../../services/apiService";
import URL from "../../urls/Urls";
// import { FilterOptionSchema, SampleProduct } from "../../redux/oms/omsTypes";
import { ToastContainer, toast } from "react-toastify";
import { toastOptions } from "../../utils/toast";
import { FilterOptionSchema } from "../Filter/filter.data";
import Filter from "../Filter/Filter";
// import Filter from "../oms/Options/Filter/Filter";

interface ArtworkSchema {
  artwork_url: string;
  category: string;
  created_at: string;
  id: number;
  source: string;
  status: string;
}
interface ArtworkDialogProps {
  handleClose: () => void;
  skuId: number;
  handleArtworkSubmit: (handleArtworkSubmit: ArtworkSchema) => void;
}

const ArtworkDialog: React.FC<ArtworkDialogProps> = ({
  handleClose,
  skuId,
  handleArtworkSubmit,
}) => {

  const SOURCE = [
    {
      id: 11,
      name: "Client",
    },
    {
      id: 12,
      name: "Vendor",
    },
  ];
  const STATUS = [
    {
      id: 11,
      name: "Approved",
    },
    {
      id: 12,
      name: "Rework",
    },
    {
      id: 13,
      name: "Review",
    },
    {
      id: 14,
      name: "Rejected",
    },
  ];
  const [selectedStatus, setSelectedStatus] = useState<FilterOptionSchema[]>(
    [],
  );
  const [selectedSource, setSelectedSource] = useState<FilterOptionSchema[]>(
    [],
  );
  const [artworkFile, setArtworkFile] = useState<File[]>([]);
  const [artworkFileName, setArtworkFileName] = useState<string[]>([]);
  const [category, setCategory] = useState("");

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const files = Array.from(event.target.files);
      const displayNames = files.map((file) => file.name);
      const fileList: File[] = [];
      const displayNameList: string[] = [];
      files.forEach((file, index) => {
        fileList.push(file);
        const displayName = displayNames[index]?.trim();
        if (displayName) {
          displayNameList.push(displayName);
        }
      });

      setArtworkFile(fileList);
      setArtworkFileName(displayNameList);
    }
  };

  const handleReset = () => {
    setCategory("");
    setSelectedSource([]);
    setSelectedStatus([]);
    setArtworkFile([]);
    setArtworkFileName([]);
  };

  const handleSubmit = async () => {
    try {
      if (selectedSource.length <= 0) {
        toast.error(`Please Select Source`, toastOptions);
        return;
      }
      if (artworkFileName.length <= 0) {
        toast.error(`Please Select File`, toastOptions);
        return;
      }
      if (selectedStatus.length <= 0) {
        toast.error(`Please Select Status`, toastOptions);
        return;
      }
      if (category.length <= 0) {
        toast.error(`Category Required`, toastOptions);
        return;
      }

      const formData = new FormData();
      formData.append("category", category);
      formData.append("source", selectedSource[0].name.toUpperCase());
      formData.append("status", selectedStatus[0].name.toUpperCase());
      formData.append("artwork_file", artworkFile[0]);
      formData.append("artwork_file_name", artworkFileName[0]);

      const { data } = await ApiService().client.post(
        URL.SKU.ADD_ARTWORK(skuId),
        formData,
      );
      handleArtworkSubmit(data.data);
    } catch (error) {
      console.error("error adding artwork", error);
    }
  };

  return (
    <div className="artwork-dialog-main-container flex-column gap-8 xetgo-font-tag-bold">
      <div className="upper-bar-container p-16 flex-row align-items-center justify-content-space-between">
        <h3 className="xetgo-font-button-bolder m-0"> Upload Artwork</h3>
        <img
          onClick={handleClose}
          className="cursor-pointer"
          height={8}
          width={8}
          src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240624162818-close+2.svg"
          alt="close-icon"
        />
      </div>
      <div className="artwork-middle-main-container px-16 flex-column gap-12">
        <div className="flex-row gap-8">
          <div className="flex-column gap-8 flex-1">
            <p style={{ color: "#999898" }} className="xetgo-font-tag bold">
              Source <span style={{ color: "red" }}>*</span>
            </p>

            <Filter
              option={{
                key: "status",
                name: selectedSource.length > 0 ? "" : "Source",
              }}
              optionList={SOURCE || []}
              multipleSelection={false}
              onSelection={(val, _option) => {
                if (val) {
                  setSelectedSource(val);
                } else {
                  setSelectedSource([]);
                }
              }}
              mode="showNames"
              showPlaceholder={true}
              showId={false}
              preSelected={selectedSource}
              direction="left"
              addNew={false}
              classname="filter-box"
              showSearch={false}
            />
          </div>
          <div className="flex-column gap-8 flex-1">
            <p style={{ color: "#999898" }} className="xetgo-font-tag bold">
              Artwork <span style={{ color: "red" }}>*</span>
            </p>
            <div>
              <input
                onChange={(event) => {
                  handleFileChange(event);
                }}
                id="artwork-fileInput"
                name="file"
                type="File"
                style={{
                  position: "absolute",
                  top: "12px",
                  right: "12px",
                  display: "none",
                }}
                multiple={false}
              />
              <label
                className="input-main-container"
                htmlFor="artwork-fileInput"
              >
                <p className="input-box xetgo-font-tag px-12 py-8 flex-row align-items-center cursor-pointer justify-content-space-between">
                  {artworkFileName[0] || "Upload artwork"}
                  <img
                    className="input-icon"
                    height={16}
                    width={16}
                    src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240714075812-upload+%286%29.svg"
                    alt="upload-icon"
                  />
                </p>
              </label>
            </div>
          </div>
        </div>
        <div className="flex-row gap-8">
          <div className="flex-column gap-8 flex-1">
            <p style={{ color: "#999898" }} className="xetgo-font-tag bold">
              Status <span style={{ color: "red" }}>*</span>
            </p>

            <Filter
              option={{
                key: "status",
                name: selectedStatus.length > 0 ? "" : "Status",
              }}
              optionList={STATUS || []}
              multipleSelection={false}
              onSelection={(val, _option) => {
                if (val) {
                  setSelectedStatus(val);
                } else {
                  setSelectedStatus([]);
                }
              }}
              mode="showNames"
              showPlaceholder={true}
              showId={false}
              preSelected={selectedStatus}
              direction="left"
              addNew={false}
              classname="filter-box"
              showSearch={false}
            />
          </div>
          <div className="flex-column gap-8 flex-1">
            <p style={{ color: "#999898" }} className="xetgo-font-tag bold">
              Category <span style={{ color: "red" }}>*</span>
            </p>
            <div className="input-main-container">
              <input
                className="input-box px-12 py-8 border-box full-width xetgo-font-tag"
                placeholder={"Category"}
                value={category}
                onChange={(e) => setCategory(e.target.value)}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="artwork-add-product-modal-btns-container justify-content-end p-16 flex-row gap-12 new-form-btn-container">
        <div onClick={handleReset} className="cancel-btn xetgo-font-tag">
          Reset
        </div>
        <div onClick={handleSubmit} className="add-btn xetgo-font-tag">
          Submit
        </div>
      </div>
      <ToastContainer position="top-right" />
    </div>
  );
};

export default ArtworkDialog;
