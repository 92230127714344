import { IAuctionItem } from "../../models/Auction";
import AuctionListCard from "../AuctionListCard/AuctionListCard";
import "./LiveAuction.scss";
import React from "react";

interface LiveAuctionProps {
  auctions: IAuctionItem[];
}

const LiveAuction: React.FC<LiveAuctionProps> = ({ auctions }) => {
  return (
    <div className="live-auction-main-container full-width flex-column p-20 border-box">
      <div className="auction-heading-container flex-column gap-18 px-3">
        <div className="flex-row gap-12 align-items-center">
          <div className="xetgo-font-caption bold flex-row gap-8 align-items-center">
            <span className="live-bullet-point"></span>
            <p>Live Auction</p>
          </div>
          {auctions.length > 0 && (
            <div className="live-circular-count-container xetgo-font-tag flex-row align-items-center justify-content-center">
              {auctions.length}
            </div>
          )}
        </div>

        <p className="lower-heading-bar"></p>
      </div>
      {auctions.length > 0 && (
        <div className="all-auction-main-container flex-1 flex-column gap-10">
          {auctions.map((auction, index) => (
            <AuctionListCard key={auction.id} type="LIVE" detail={auction} />
          ))}
        </div>
      )}
      {auctions.length < 0 && (
        <div
          style={{ color: "#B2B2B2" }}
          className="empty-state flex-row align-items-center justify-content-center flex-1 xetgo-font-button bold"
        >
          No auction to show
        </div>
      )}
    </div>
  );
};

export default LiveAuction;
