import { ToastSchema } from "../../models/Toast";
import Toast from "../Toast/Toast";

import "./ToastContainer.scss";

interface ToastContainerProps {
  toasts: ToastSchema[];
  position: string;
}

const ToastContainer: React.FC<ToastContainerProps> = ({
  toasts,
  position = "top-right",
}) => {
  return (
    <div className={`toasts-container ${position}`}>
      {toasts.map((toast) => (
        <Toast key={toast.id} {...toast} />
      ))}
    </div>
  );
};

export default ToastContainer;
