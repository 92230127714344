import React from "react";
import { Auction } from "../../models/Auction";
import "./AuctionDetail.scss";
import { commaFormat, formatDateTime } from "../../utils/Helper";
interface AuctionDetailProps {
  auction: Auction;
}

const AuctionDetail: React.FC<AuctionDetailProps> = ({ auction }) => {
  return (
    <div className="auction-detail-wrapper-container p-12 flex-column gap-12">
      <div className="detail-upper-main-container flex-column gap-12 p-12">
        <div className="flex-column flex-1">
          <p className="heading-bar xetgo-font-button bold px-12 py-5">
            {auction.title}
          </p>
          <div className="flex-column gap-18 p-12">
            <div className="flex-row justify-content-space-between">
              <div className="flex-column gap-12 xetgo-font-tag">
                <div className="flex-row align-items-center xetgo-font-tag">
                  <p className="detail-title">Auction ID</p>
                  <p className="detail-subtitle bold flex-1 bold">
                    {auction.auction_id}
                  </p>
                </div>
                <div className="flex-row align-items-center xetgo-font-tag">
                  <p className="detail-title">Start Date & Time</p>
                  <p className="detail-subtitle bold flex-1">
                    {formatDateTime(auction.start_time)}
                  </p>
                </div>
                <div className="flex-row align-items-center  xetgo-font-tag">
                  <p className="detail-title">End date & time</p>
                  <p className="detail-subtitle bold flex-1">
                    {formatDateTime(auction.end_time)}
                  </p>
                </div>
              </div>
              <div className="flex-column gap-12 xetgo-font-tag">
                <div className="flex-row align-items-center xetgo-font-tag">
                  <p className="detail-title">Currency</p>
                  <p className="detail-subtitle bold">{auction.currency}</p>
                </div>
                <div className="flex-row align-items-center xetgo-font-tag">
                  <p className="detail-title">Auction Type</p>
                  <p className="detail-subtitle bold">{auction.type}</p>
                </div>
                <div className="flex-row align-items-center xetgo-font-tag">
                  <p className="detail-title">Auction Guidelines</p>
                  <a
                    href={auction.guidelines}
                    className="detail-guideline"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Guidelines
                  </a>
                </div>
              </div>
            </div>
            <div
              className="detail-subtitle xetgo-font-tag bold"
              dangerouslySetInnerHTML={{
                __html: auction.description,
              }}
            ></div>
          </div>
        </div>
        <div className="middle-border"></div>
        <div
          style={{ color: "#686868" }}
          className="note-container flex-row p-12 xetgo-font-tag"
        >
          <p>Note</p>
          <p className="bold">{auction.notes}</p>
        </div>

        <div className="flex-column flex-1">
          <p className="heading-bar xetgo-font-button bold px-12 py-5">
            Buyer Details{" "}
          </p>
          <div className="flex-row p-12 justify-content-space-between">
            <div className="flex-column gap-12 xetgo-font-tag">
              <div className="flex-row align-items-center justify-content-space-between xetgo-font-tag">
                <p className="detail-title flex-1">Contact Number</p>
                <p className="detail-subtitle bold flex-1 bold">
                  {auction.buyer_details.contact}
                </p>
              </div>
              <div className="flex-row align-items-center justify-content-space-between xetgo-font-tag">
                <p className="detail-title flex-1">Person Name</p>
                <p className="detail-subtitle bold flex-1">
                  {auction.buyer_details.name}
                </p>
              </div>
            </div>
            <div className="flex-column gap-12 xetgo-font-tag">
              <div className="flex-row align-items-center xetgo-font-tag">
                <p className="detail-title flex-1">Location</p>
                <p className="detail-subtitle bold flex-1">
                  {auction.buyer_details.location}
                </p>
              </div>
              <div className="flex-row align-items-centerxetgo-font-tag">
                <p className="detail-title flex-1">Email</p>
                <p className="detail-subtitle bold flex-1">
                  {auction.buyer_details.email}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="detail-upper-main-container p-12 flex-column gap-4">
        <p className="heading-bar xetgo-font-button bold px-12 py-5">
          Address & SKUs
        </p>
        {auction.address.length > 0 &&
          auction.address.map((row, index) => (
            <div key={index} className="address-sku-wrapper-container">
              <div className="heading-bar flex-row align-items-center gap-18 xetgo-font-tag p-8">
                <p className="state-name bold">{row.detail.state}</p>
                <p style={{ color: "#919191" }}>
                  {row.detail.line}, {row.detail.pincode}
                </p>
              </div>
              <div className="address-skus-main-container">
                <div className="address-sku-grid heading-bar-container xetgo-font-tag">
                  <p className="empty-index p-12"></p>
                  <p className="p-12 bold">SKU Name</p>
                  <p className="p-12 bold">Quantity</p>
                  <p className="p-12 bold">Start Price (INR)</p>
                  <p className="p-12 bold">Minimum Decrement Price (INR)</p>
                  <p className="p-12 bold">Description</p>
                </div>
                {row.skus.length > 0 &&
                  row.skus.map((sku, skuIndex) => (
                    <div
                      key={skuIndex}
                      className="address-sku-grid xetgo-font-tag "
                    >
                      <p className="index px-12 py-8 flex-row align-items-center justify-content-center">
                        {skuIndex + 1}
                      </p>
                      <a
                        href={`/skus/${sku.id}`}
                        className="sku-name px-12 py-8 border-right-top bold flex-row align-items-center"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {sku.name}
                      </a>
                      <p className="border-right-top px-12 py-8 flex-row align-items-center gap-4">
                        {commaFormat(sku.quantity.toString())}
                        <span className="measurement-unit">{sku.unit}</span>
                      </p>
                      <p className="start-price border-right-top px-12 py-8 flex-row align-items-center gap-20">
                        ₹
                        <span className="value">
                          {commaFormat(sku.start_price.toString())}
                        </span>
                      </p>
                      <p className="decrement-price border-right-top px-12 py-8 flex-row align-items-center gap-20">
                        ₹
                        <span>
                          {commaFormat(sku.decrement_price?.toString())}
                        </span>
                      </p>
                      <p className="description-sku border-top px-12 py-8 bold">
                        {sku.description}
                      </p>
                    </div>
                  ))}
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default AuctionDetail;
