import { useNavigate } from "react-router-dom";
import LiveAuction from "../../components/LiveAuction/LiveAuction";
import PastAuction from "../../components/PastAuction/PastAuction";
import UpcomingAuction from "../../components/UpcomingAuction/UpcomingAuction";
import "./Auction.scss";
import React, { useEffect, useState } from "react";
import ApiService from "../../services/apiService";
import URLS from "../../urls/Urls";
import { IAuctionItem } from "../../models/Auction";

const Auction: React.FC = () => {
  const navigate = useNavigate();
  const [pastAuctions, setPastAuctions] = useState<IAuctionItem[]>([]);
  const [liveAuctions, setLiveAuctions] = useState<IAuctionItem[]>([]);
  const [upcomingAuctions, setUpcomingAuctions] = useState<IAuctionItem[]>([]);

  useEffect(() => {
    fetchAuctions();
  }, []);

  const fetchAuctions = async () => {
    try {
      const { data } = await ApiService().client.get(URLS.AUCTION.LIST);
      setPastAuctions(data.past);
      setLiveAuctions(data.live);
      setUpcomingAuctions(data.upcoming);
    } catch (e) {
      console.error("Error: ", e);
    }
  };

  return (
    <div className="auction-main-page full-width flex-column">
      <div className="upper-bar">
        <p className="xetgo-font-tag bold px-16 py-12">Auctions</p>
      </div>
      <div className="auction-header-bar flex-row align-items-center justify-content-end py-10 ">
        <div
          onClick={() => navigate("/auctions/new")}
          className="create-auction-btn flex-row align-items-center justify-content-center gap-10 px-12 py-8 xetgo-font-tag bold py-10 border-box cursor-pointer"
        >
          <img
            height={12}
            width={12}
            src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240618101041-plus_white.svg"
            alt="add-icon"
          />
          <p>Create Auction</p>
        </div>
      </div>
      <div className="all-auction-type-main-container flex-1 flex-row gap-26 p-16">
        <PastAuction auctions={pastAuctions} />
        <LiveAuction auctions={liveAuctions} />
        <UpcomingAuction auctions={upcomingAuctions} />
      </div>
    </div>
  );
};

export default Auction;
