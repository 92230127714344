import "./AuctionConfirmationAlert.scss";
import React, { useState } from "react";

import { useSelector } from "react-redux";
import { userDetails } from "../../redux/user/userSelector";

interface AuctionConfirmationProps {
  onEditAuction: () => void;
  onAuctionSubmit: () => void;
}

const AuctionConfirmationAlert: React.FC<AuctionConfirmationProps> = ({
  onEditAuction,
  onAuctionSubmit,
}) => {
  return (
    <div className="auction-confirmation-modal-alert-modal-box">
      <div className="flex-row px-16 py-12 justify-content-end">
        <img
          className="cursor-pointer"
          height={16}
          width={16}
          onClick={onEditAuction}
          src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240209103001-x.svg"
        />
      </div>
      <div className="flex-column align-items-center justify-content-center gap-12 px-16 py-12">
        <div className="flex-column gap-34">
          <div className=" flex-column gap-18 align-items-center justify-content-center">
            <div className="circular-alert-container flex-row align-items-center justify-content-center">
              <img
                width={43}
                height={40}
                src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20241030053829-Group+1171276135.svg"
                alt="alert-icon"
              />
            </div>
            <p
              style={{ color: "#454545" }}
              className="xetgo-font-subHeading bold"
            >
              Confirmation Required
            </p>
            <p
              style={{ color: "#454545", width: "450px", textAlign: "center" }}
              className="xetgo-font-tag"
            >
              Are you sure you want to finalize this auction? Once submitted, no
              further changes can be made.{" "}
            </p>
          </div>
          <p style={{ color: "#ABABAB" }} className="xetgo-font-tag">
            Vendors will be notified, and the auction process will commence as
            scheduled.
          </p>
        </div>
      </div>
      <div className="auction-alert-btn-container flex-row gap-12 justify-content-center px-16 py-12">
        <div
          className="edit-auction-btn auction-alert-btn flex-row align-items-center justify-content-center xetgo-font-tag py-6 px-10 cursor-pointer border-box"
          onClick={onEditAuction}
        >
          Edit Auction
        </div>
        <div
          className="auction-confirm-btn auction-alert-btn flex-row align-items-center justify-content-center xetgo-font-tag py-6 px-10 cursor-pointer border-box"
          onClick={onAuctionSubmit}
        >
          Confirm
        </div>
      </div>
    </div>
  );
};

export default AuctionConfirmationAlert;
