import React from "react";
import "./AuctionAddressSku.scss";
import { useAuctionContext } from "../../context/create-auction-context";
import AuctionAddressSkuEntry from "../AuctionAddressSkuEntry/AuctionAddressSkuEntry";

interface CreateAuctionProps {
  active?: number;
  update?: boolean;
  vendorFormId?: number;
}

const AuctionAddressSku: React.FC<CreateAuctionProps> = () => {
  const { auction, addAddress } = useAuctionContext();

  return (
    <div className="auction-address-sku-main-container flex-column gap-16 p-12">
      {auction.auction_sku_details.map((detail, addressIndex) => (
        <AuctionAddressSkuEntry
          addressIndex={addressIndex}
          key={`address-sku-${addressIndex}`}
        />
      ))}
      <div
        onClick={addAddress}
        className="add-more-btn flex-row align-items-center gap-2 xetgo-font-tag cursor-pointer"
      >
        <img
          height={14}
          width={14}
          src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20241028071854-add.svg"
          alt="add-icon"
        />
        <p>Add more</p>
      </div>
    </div>
  );
};

export default AuctionAddressSku;
