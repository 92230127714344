import React, { useEffect, useState } from "react";
import "./CompanyDetailsEdit.scss";
import { BusinessNature, CompanyTypes } from "./CompanyDetails.data";
import { UserCompanyDetails } from "../../models/User";
import { useDispatch } from "react-redux";
import { updateUserDetails } from "../../redux/user/userAction";
import { userDetails } from "../../redux/user/userSelector";
import { useSelector } from "react-redux";

interface CompanyDetailsEditProps {
  companyId: number | null;
  companyDetails: UserCompanyDetails | null;
  handleClose: () => void;
  userAvatar: string | null;
}

const CompanyDetailsEdit: React.FC<CompanyDetailsEditProps> = ({
  companyId,
  companyDetails,
  handleClose,
  userAvatar,
}) => {
  const dispatch = useDispatch();
  const userId = useSelector(userDetails).id;
  const [panFiles, setPanFiles] = useState<File[]>([]);
  const [profileFile, setProfileFile] = useState<File>();
  const [profileFileName, setProfileFileName] = useState<string>(
    userAvatar || "",
  );

  const [panFileNames, setPanFileNames] = useState<string[]>([]);

  useEffect(() => {
    setPanFileNames([
      `${
        companyDetails?.company_attachments.pan?.name
          ? companyDetails?.company_attachments.pan?.name
          : ""
      }`,
    ]);
    setGstDisplayNames([
      `${
        companyDetails?.company_attachments.gst?.name
          ? companyDetails?.company_attachments.gst?.name
          : ""
      }`,
    ]);
  }, []);

  const [gstFileList, setGstFileList] = useState<File[]>([]);

  const [gstDisplayNames, setGstDisplayNames] = useState<string[]>([]);
  const [companyTypeDropdownVisible, setCompanyTypeDropdownVisible] =
    useState(false);
  const [companyType, setCompanyType] = useState(companyDetails?.company_type);
  const [panNumber, setPanNumber] = useState(companyDetails?.pan_number);
  const [gstNumber, setGstNumber] = useState(companyDetails?.gst_number);
  const [companyNatureDropdownVisible, setCompanyNatureDropdownVisible] =
    useState(false);
  const [companyNature, setCompanyNature] = useState(
    companyDetails?.nature_of_business,
  );

  const toggleCompanyTypeDropdown = () => {
    setCompanyTypeDropdownVisible((val) => !val);
    setCompanyNatureDropdownVisible(false);
  };

  const toggleCompanyNatureDropdown = () => {
    setCompanyNatureDropdownVisible((val) => !val);
    setCompanyTypeDropdownVisible(false);
  };

  const handleUpdate = () => {
    const formData = new FormData();
    formData.append("company_id", companyId?.toString() || "");
    formData.append("company_type", companyType || "");
    formData.append("nature_of_business", companyNature || "");
    formData.append("gst_number", gstNumber || "");
    formData.append("pan_number", panNumber || "");
    if (panFiles.length > 0) {
      formData.append("pan_proof_file", panFiles[0]);
      formData.append("pan_proof_file_name", panFileNames[0]);
    }
    if (gstFileList.length > 0) {
      formData.append("gst_proof_file", gstFileList[0]);
      formData.append("gst_proof_file_name", gstDisplayNames[0]);
    }
    if (profileFile && userId) {
      formData.append("user_id", userId.toString());
      formData.append("avatar", profileFile);
      formData.append("avatar_name", profileFile.name);
    }
    dispatch(updateUserDetails(formData));
    handleClose();
  };

  const handleCompanyTypeDropDownChange = (val: string) => {
    setCompanyType(val);
    setCompanyTypeDropdownVisible(false);
  };

  const handleCompanyNatureChange = (val: string) => {
    setCompanyNature(val);
    setCompanyNatureDropdownVisible(false);
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const files = Array.from(event.target.files);
      const displayNames = files.map((file) => file.name);
      const fileList: File[] = [];
      const displayNameList: string[] = [];
      files.forEach((file, index) => {
        fileList.push(file);
        const displayName = displayNames[index]?.trim();
        if (displayName) {
          displayNameList.push(displayName);
        }
      });

      setPanFiles(fileList);
      setPanFileNames(displayNameList);
    }
  };

  const handleGstFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const files = Array.from(event.target.files);
      const displayNames = files.map((file) => file.name);
      const fileList: File[] = [];
      const displayNameList: string[] = [];
      files.forEach((file, index) => {
        fileList.push(file);
        const displayName = displayNames[index]?.trim();
        if (displayName) {
          displayNameList.push(displayName);
        }
      });

      setGstFileList(fileList);
      setGstDisplayNames(displayNameList);
    }
  };

  const handleProflePhotoChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (event.target.files && event.target.files.length > 0) {
      const files = Array.from(event.target.files);
      setProfileFile(files[0]);
      setProfileFileName(files[0].name);
    }
  };

  return (
    <div className="company-details-dialog border-box">
      <div className="upper-bar flex-row align-items-center justify-content-space-between py-12 px-16">
        <p className="xetgo-font-button bold">Update Company Details</p>
        <img
          onClick={handleClose}
          className="cursor-pointer"
          height={8}
          width={8}
          src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240624162818-close+2.svg"
          alt="close-icone"
        />
      </div>

      <div className="flex-column gap-12 py-12 px-16">
        <div className="flex-column gap-8">
          <p style={{ color: "#999898" }} className="xetgo-font-tag bold">
            Company Nature
          </p>
          <div className="input-main-container">
            <input
              className="input-box px-12 py-8 border-box full-width xetgo-font-tag"
              placeholder={"Company Type"}
              value={companyNature}
              disabled
              onChange={(e) => setCompanyNature(e.target.value)}
            />
            <img
              src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240715100443-chevron-down.svg"
              className="input-icon cursor-pointer"
              onClick={toggleCompanyNatureDropdown}
              alt="dropdown-icon"
            />
            {companyNatureDropdownVisible && (
              <div className="company-type-dropdown">
                {BusinessNature.map((opt, i) => {
                  return (
                    <div
                      key={i}
                      className="option"
                      onClick={() => handleCompanyNatureChange(opt)}
                    >
                      {opt}
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>

        <div className="flex-column gap-8">
          <p style={{ color: "#999898" }} className="xetgo-font-tag bold">
            Company Type
          </p>
          <div className="input-main-container">
            <input
              className="input-box px-12 py-8 border-box full-width xetgo-font-tag"
              placeholder={"Company Type"}
              value={companyType}
              disabled
              onChange={(e) => setCompanyType(e.target.value)}
            />
            <img
              src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240715100443-chevron-down.svg"
              className="input-icon cursor-pointer"
              onClick={toggleCompanyTypeDropdown}
              alt="dropdown-icon"
            />
            {companyTypeDropdownVisible && (
              <div className="company-type-dropdown">
                {CompanyTypes.map((opt, i) => {
                  return (
                    <div
                      key={i}
                      className="option"
                      onClick={() => handleCompanyTypeDropDownChange(opt)}
                    >
                      {opt}
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>

        <div className="flex-column gap-8">
          <p style={{ color: "#999898" }} className="xetgo-font-tag bold">
            Upload profile photo
          </p>
          <div>
            <input
              onChange={(event) => {
                handleProflePhotoChange(event);
              }}
              id="profile-fileInput"
              name="file"
              type="File"
              style={{
                position: "absolute",
                top: "12px",
                right: "12px",
                display: "none",
              }}
              multiple={false}
            />
            <label
              className="input-main-container "
              htmlFor="profile-fileInput"
            >
              <p className="input-box xetgo-font-tag px-12 py-8 flex-row align-items-center cursor-pointer justify-content-space-between">
                {profileFileName
                  ? profileFileName
                  : profileFile
                    ? profileFile.name
                    : " Upload Profile Photo"}
                <img
                  height={16}
                  width={16}
                  src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240714075812-upload+%286%29.svg"
                  alt="upload-icon"
                />
              </p>
            </label>
          </div>
        </div>

        <div className="flex-row gap-8">
          <div className="flex-column gap-8 flex-1">
            <p style={{ color: "#999898" }} className="xetgo-font-tag bold">
              PAN Number
            </p>
            <div className="input-main-container">
              <input
                className="input-box px-12 py-8 border-box full-width xetgo-font-tag"
                placeholder={"PAN Number"}
                value={panNumber}
                onChange={(e) => setPanNumber(e.target.value)}
              />
            </div>
          </div>
          <div className="flex-column gap-8 flex-1">
            <p style={{ color: "#999898" }} className="xetgo-font-tag bold">
              PAN Number Proof
            </p>
            <div>
              <input
                onChange={(event) => {
                  handleFileChange(event);
                }}
                id="pan-fileInput"
                name="file"
                type="File"
                style={{
                  position: "absolute",
                  top: "12px",
                  right: "12px",
                  display: "none",
                }}
                multiple={false}
              />
              <label className="input-main-container " htmlFor="pan-fileInput">
                <p className="input-box xetgo-font-tag px-12 py-8 flex-row align-items-center cursor-pointer justify-content-space-between">
                  {panFileNames[0] || "Upload Document"}
                  <img
                    height={16}
                    width={16}
                    src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240714075812-upload+%286%29.svg"
                    alt="upload-icon"
                  />
                </p>
              </label>
            </div>
          </div>
        </div>
        <div className="flex-row gap-8">
          <div className="flex-column gap-8 flex-1">
            <p style={{ color: "#999898" }} className="xetgo-font-tag bold">
              GSTIN Number
            </p>
            <div className="input-main-container">
              <input
                className="input-box px-12 py-8 border-box full-width xetgo-font-tag"
                placeholder={"GSTIN Number"}
                value={gstNumber}
                onChange={(e) => setGstNumber(e.target.value)}
              />
            </div>
          </div>
          <div className="flex-column gap-8 flex-1">
            <p style={{ color: "#999898" }} className="xetgo-font-tag bold">
              GSTIN Number Proof
            </p>
            <div>
              <input
                onChange={(event) => {
                  handleGstFileChange(event);
                }}
                id="gst-fileInput"
                name="file"
                type="File"
                style={{
                  position: "absolute",
                  top: "12px",
                  right: "12px",
                  display: "none",
                }}
                multiple={false}
              />
              <label className="input-main-container " htmlFor="gst-fileInput">
                <p className="input-box xetgo-font-tag px-12 py-8 flex-row align-items-center cursor-pointer justify-content-space-between">
                  {gstDisplayNames[0] || "Upload Document"}
                  <img
                    height={16}
                    width={16}
                    src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240714075812-upload+%286%29.svg"
                    alt="upload-icon"
                  />
                </p>
              </label>
            </div>
          </div>
        </div>
      </div>

      <div className="add-product-modal-btns-container justify-content-end flex-row gap-12 new-form-btn-container py-12 px-16">
        <div onClick={handleClose} className="cancel-btn xetgo-font-tag">
          Cancel
        </div>
        <div onClick={handleUpdate} className="add-btn xetgo-font-tag">
          Update
        </div>
      </div>
    </div>
  );
};

export default CompanyDetailsEdit;
