import React, { useEffect, useState } from "react";
import "./Team.scss";
import { useSelector } from "react-redux";
import { userDetails } from "../../redux/user/userSelector";
import { Tooltip } from "@mui/material";
import XetImage from "../../components/shared/xet-image/XetImage";
import XetModal from "../../components/shared/xet-modal/XetModal";
import EditUserInfoDialog from "../../components/EditTeamInfoDialog/EditTeamInfoDialog";
import TeamCreationDialog from "../../components/TeamCreationDialog/TeamCreationDialog";
import { useNavigate } from "react-router-dom";
import URLS from "../../urls/Urls";
import ApiService from "../../services/apiService";
import { formatDate } from "../../utils/Helper";
import { toast } from "react-toastify";

interface OnSavePayload {
  name?:string
  phone_number?: number,
  designation?: string,
  password?:string,
  email?: string,
}
interface createTeamPayload {
  name:string,
  description:string,
  members:number[]
}

interface Teams{
  id:number;
  name:string ;
  created_at:string;
  active:boolean;
}

export interface IMemberDetail {
  admin:boolean
  designation:string
  email: string
  employee_id: string
  id:number
  name: string
  phone_number: string
  password:string
}

const Team: React.FC = () => {
 const user = useSelector(userDetails)
 const navigate = useNavigate();
 const [showEditUserInfo,setShowEditUserInfo] = useState(false)
 const [teams,setTeams] = useState<Teams[]>([])
 const [allTeamAvatars,setAllTeamAvatars] = useState<any>([])
 const [showCreateTeamDialog,setShowCreateTeamDialog] = useState(false)
 const [userInformationDetails,setUserInformationDetails] = useState<IMemberDetail|null>(null)

 useEffect(()=>{
  getMemberDetails()
 },[])

 const getMemberDetails =async()=>{
  try {
    
    const { data } = await ApiService().client.get(
      URLS.TEAM.MEMBER_DETAILS(user.company_id ||-1));

      setUserInformationDetails(
        data.member
      )
      setAllTeamAvatars(data.team_avatars)
      setTeams(data.teams)
      setUserInformationDetails(data.member)
      
} catch (error) {
  console.error("Error Fetching Member Details", error);
}
 }


 const informationUpdate = async(params:OnSavePayload)=>{

  try {
    
    const { data } = await ApiService().client.put(
      URLS.TEAM.MEMBER_DETAILS(user.id ||-1),
      params
    );
      const userInfo: IMemberDetail =  {
        admin:data.member.admin,
        designation:data.member.designation,
        email: data.user.email,
        employee_id: data.member.employee_id,
        id:data.member.id,
        name: data.user.name,
        phone_number: data.user.phone_number,
        password:data.user.password
      }
      setUserInformationDetails(userInfo)
      setShowEditUserInfo(false)
} catch (error) {
  console.error("Error Updatig Details", error);
}
 }

 const createTeamHandler = async (information: createTeamPayload) => {
  try {
    const { data } = await ApiService().client.post(
      URLS.TEAM.CREATE_TEAM(user.company_id || -1),
      {
        name: information.name,
        description: information.description,
        members: information.members,
      }
    );
    console.log('DATAA::',data)
    const keyValPairAvatar = data.team_avatars
    setAllTeamAvatars((prev:any) => ({ ...prev, ...keyValPairAvatar }))
    const teamData: Teams = {
      id: data.team.id,
      name: data.team.name,
      created_at: data.team.created_at,
      active: data.team.active,
    };

  
    setTeams((prev) => [...(prev || []), teamData]);
    setShowCreateTeamDialog(false);
    
    toast.success("Team created successfully!");
  } catch (error) {
    console.error("Error creating team:", error);
    toast.error("Failed to create the team. Please try again.");
  }
};

  return (
    <div className="user-panel-main-page full-width flex-column gap-20">
    <div className="upper-bar">
      <p className="xetgo-font-tag bold px-16 py-12">{userInformationDetails?.name}</p>
    </div>
   
   <div className="user-panel-lower-main-container p-12 overflow-scroll">
    <div className="user-panel-inner-main-container flex-column gap-16 px-24 py-8">
      <div className="main-container-user-info flex-row align-items-center justify-content-space-between">
        <div className="flex-row align-items-center justify-content-space-between full-width">
          <div className="flex-row align-items-center gap-12">
          <img className="avatar-mob" height={62} width={62} src={user.avatar || ""} alt="avatar" />
          <div className="flex-column gap-4 xetgo-font-tag">
            <p className="bold">{userInformationDetails?.name}</p>
            <p>{userInformationDetails?.designation}</p>
          </div>
          </div>
          <div className="pensquare-edit-mob flex-row align-items-base-line full-height">
          <img onClick={()=>setShowEditUserInfo(true)}  className="cursor-pointer" height={18} width={18} src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20250108082347-pen-square.svg" alt="edit-icon" />
          </div>
        </div>
       <div className="all-mid-info-detail-container flex-row align-items-center justify-content-space-between">
       <div  className="middle-section-contact-cards-container  flex-row align-items-center justify-content-space-between">
       <div className="flex-row gap-2 align-items-center">
          <img src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20250108082749-personalcard.svg" alt="contact-icon" />
          <p className="xetgo-font-tag">{userInformationDetails?.employee_id}</p>
        </div>
        <div className="flex-row gap-2 align-items-center">
          <img src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20250108082434-sms.svg" alt="email-icon" />
          <p className="xetgo-font-tag">{userInformationDetails?.email}</p>
        </div>
       </div>
        <div className="middle-section-contact-cards-container flex-row align-items-center justify-content-space-between">
        <div className="flex-row gap-2 align-items-center">
          <img src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20250108082530-call.svg" alt="phone-icon" />
          <p className="xetgo-font-tag">{userInformationDetails?.phone_number}</p>
        </div>
        <div className="flex-row gap-2 align-items-center">
          <img src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20250108082502-Icon.svg" alt="lock-icon" />
          <p className="xetgo-font-tag">*******</p>
        </div>
        </div>
       </div>
        <div className="flex-row align-items-center justify-content end">
          <div onClick={()=>setShowEditUserInfo(true)} className="edit-btn flex-row align-items-center cursor-pointer justify-content-center px-8 py-4"><img height={14} width={14} src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20250108082347-pen-square.svg" alt="edit-icon" />
          <p className="xetgo-font-tag bold">Edit</p>
          </div>
        </div>
      </div>
      <div className="team-information-main-container flex-column gap-8">
        <div className="my-teams-title-container flex-row align-items-center justify-content-space-between">
          <p style={{color:'#1F2937'}} className="team-head-mob xetgo-font-tag bold">Teams</p>
         {userInformationDetails?.admin && <div onClick={()=>setShowCreateTeamDialog(true)} className="create-team-btn flex-row align-items-center gap-8 justify-content-center py-8 border-box cursor-pointer">
            <img height={12} width={12} src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240618101041-plus_white.svg" alt="add-icon" />
            <p className="xetgo-font-tag bold">Create Team</p>
          </div>}
        </div>
        <div className="all-teams-main-container px-8">
          <div className="heading-team team-grid xetgo-font-tag bold">
            <p className="px-20 py-12">Team</p>
            <p className="px-20 py-12">Members</p>
            <p className="px-20 py-12">Joined</p>
            <p className="hide-status-cell px-20 py-12">Status</p>
          </div>
          {teams?.length>0 && <div className="all-teams">
            {teams.map((team,index)=> {
              const avatarData: string[] = allTeamAvatars[team.id] || []
           
            return(
              <div onClick={()=>navigate(`/teams/${team.id}`)} key={team.id} className="team-container team-grid cursor-pointer">

              <p  className="px-20 py-12 flex-row align-items-center xetgo-font-tag bold">{team.name}</p>
              <div className="px-20 py-12 flex-row align-items-center">
              {avatarData.length>0 &&avatarData.map((url,memberIndex)=>{
                if(memberIndex<4){
                  return(
                    <Tooltip
                      title={``}
                      key={url}
                      >
                      <XetImage
                        className="members"
                        src={url}
                        alt={'avatar'}
                        width={22}
                        height={22}
                      />
                    </Tooltip>
                  )
                }else if(memberIndex===5){
                  return(
                    <div className="avatar-custom-member flex-row align-items-center justify-content-center  xetgo-font-tag" key={memberIndex}>
                      <p >+ {avatarData.length-1}</p>
                    </div>
                  )
                }
              })}
              </div>
              <p className="px-20 py-12 flex-row align-items-center xetgo-font-tag bold">{formatDate(team.created_at)}</p>
              <p className="hide-status-cell px-20 py-12 flex-row align-items-center"><span className={`status px-8 py-2 xetgo-font-tag flex-row gap-4 align-items-center justify-content-center ${team.active && 'status-active'}`}>
              <span className={`dot p-2 ${team.active&& 'active-dot'}`}></span>{team.active ? 'Active': 'Inactive'}</span></p>
            </div>
            )
            })}
            </div>}
        </div>
        <div  className="all-teams-main-container-mob">
          {teams?.length>0 &&   <div className="all-teams-container-mob flex-row gap-12">
            
            {teams.map((team,index)=> {
              const avatarData: string[] = allTeamAvatars[team.id] || []
              return(
                <div onClick={()=>navigate(`/teams/${team.id}`)} key={team.id}className={`team-container-mob border-box flex-column gap-14 p-16 xetgo-font-tag cursor-pointer ${!team.active && 'team-container-mob-inactive '}`}>
              <div className="team-card-upper-container-mob flex-column gap-8 align-items-center">
                <p className={`team-type-mob py-6 px-16 bold ${!team.active  && 'team-type-mob-inactive'}`}>{team.name}</p>
                <p className="joined bold">{formatDate(team.created_at)}</p>
              </div>
              <p className={` ${team.active ?'mid-line': 'mid-line-inactive'}`}></p>
              <div className="team-card-upper-container-mob flex-column gap-8 align-items-center">
                <p className="joined bold">{avatarData.length} Members</p>
                <div className=" flex-row align-items-center">
              {avatarData.map((avatar,avatarIndex)=>{
                if(avatarIndex<4){
                  return(
                    <Tooltip
                      title={``}
                      key={avatar}
                      >
                      <XetImage
                        className="members"
                        src={avatar}
                        alt={'avatar'}
                        width={22}
                        height={22}
                      />
                    </Tooltip>
                  )
                }else if(avatarIndex===5){
                  return(
                    <div className="avatar-custom-member flex-row align-items-center justify-content-center  xetgo-font-tag" key={avatarIndex}>
                      <p >+ {avatarData.length-1}</p>
                    </div>
                  )
                }
              })}
              </div>
              </div>
            </div>
              )
            })}
            
            </div>}
        </div>
      </div>
    </div>
   </div>
   {showEditUserInfo &&userInformationDetails&&<XetModal
            backdropClose={true}
            open={showEditUserInfo}
            handleClose={() => setShowEditUserInfo(false)}
          >
            <EditUserInfoDialog
            onCancel={()=>setShowEditUserInfo(false)}
            onSave={informationUpdate}
            details={userInformationDetails}
             />
          
          </XetModal>}

          {showCreateTeamDialog &&<XetModal
            backdropClose={true}
            open={showCreateTeamDialog}
            handleClose={() => setShowCreateTeamDialog(false)}
          >
            <TeamCreationDialog
            onCancel={()=>setShowCreateTeamDialog(false)}
            onSave={createTeamHandler}
             />
          
          </XetModal>}
  </div>
  );
};

export default Team;
