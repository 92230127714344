import "./AuctionVendorDetailPage.scss";
import React, { useEffect, useRef, useState } from "react";
import CommentCard from "../../components/CommentSection/CommentCard/CommentCard";
import { useNavigate, useParams } from "react-router-dom";
import ApiService from "../../services/apiService";
import URLS from "../../urls/Urls";
import {
  AuctionVendorInvitationStatus,
  IAuctionVendor,
  IAuctionVendorForm,
} from "../../models/Auction";
import VendorFormResult from "../../components/VendorFormResult/VendorFormResult";
import { getFileIcon } from "../../utils/Helper";
import VendorBidHistory from "../../components/VendorBidHistory/VendorBidHistory";

const AuctionVendorDetailPage: React.FC = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [details, setDetails] = useState<IAuctionVendor>();
  const [vendorForm, setVendorForm] = useState<IAuctionVendorForm>();
  const [selectedAddressId, setSelectedAddressId] = useState(0);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [newComment, setNewComment] = useState("");
  const navigate = useNavigate();
  const { id, auctionId, tab } = useParams();
  const messagesEndRef = useRef<null | HTMLDivElement>(null);

  useEffect(() => {
    fetchVendorDetails();
    fetchVendorFormDetails();
  }, []);

  useEffect(() => {
    if (tab === "form") {
      setActiveTab(1);
    } else if (tab === "comments") {
      setActiveTab(2);
    } else if (tab === "bids") {
      setActiveTab(3);
    } else {
      setActiveTab(0);
    }
    scrollToBottom();
  }, [id, auctionId, tab]);

  useEffect(() => {
    scrollToBottom();
  }, [details]);

  const fetchVendorDetails = async () => {
    try {
      const { data } = await ApiService().client.get(
        URLS.AUCTION.VENDOR_DETAIL(auctionId || "", id || ""),
      );
      setDetails(data);
      scrollToBottom();
    } catch (e) {
      console.error("Error: ", e);
    }
  };

  const fetchVendorFormDetails = async () => {
    try {
      const { data } = await ApiService().client.get(
        URLS.AUCTION.VENDOR_DETAIL_FORM(auctionId || "", id || ""),
      );
      setVendorForm(data.data);
    } catch (e) {
      console.error("Error: ", e);
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const files = Array.from(event.target.files);
      setSelectedFiles(files);
    }
  };

  const deleteFile = (file: File) => {
    setSelectedFiles((old) => old.filter((row) => row.name !== file.name));
  };

  const handleCommentSubmit = async () => {
    if (newComment.length <= 0 && selectedFiles.length <= 0 && details) {
      return;
    }
    try {
      if (details) {
        const formData = new FormData();
        formData.append("comment", newComment);
        formData.append("id", id || "");
        formData.append(
          "auction_vendor_invitation_id",
          details.invite_id.toString(),
        );
        if (selectedFiles.length > 0) {
          selectedFiles.forEach((file) => {
            formData.append("files[]", file);
            formData.append("displayNames[]", file.name);
          });
        }
        const { data } = await ApiService().client.post(
          URLS.AUCTION.VENDOR_COMMENT(auctionId || "", id || ""),
          formData,
        );
        setNewComment("");
        setSelectedFiles([]);
        setDetails((val) => {
          if (val) {
            return { ...val, comments: [...val.comments, data.comment] };
          }
          return val;
        });
      }
    } catch (e) {
      console.error("Error: ", e);
    }
  };

  const scrollToBottom = () => {
    setTimeout(() => {
      if (messagesEndRef.current) {
        messagesEndRef.current.scrollIntoView({
          behavior: "smooth",
          block: "end",
        });
      }
    }, 10);
  };

  const statusUpdate = async (status: string) => {
    try {
      const { data } = await ApiService().client.put(
        URLS.AUCTION.STATUS(auctionId || ""),
        {
          status,
          vendor_id: id,
        },
      );
      if (data) {
        setDetails((prev) => {
          if (prev) {
            return {
              ...prev,
              status,
            };
          }
          return prev;
        });
      }
    } catch (e) {
      console.error("Error: ", e);
    }
  };

  return (
    <div className="vendor-auction-detail-main-page full-width flex-column">
      <div className="upper-bar flex-row align-items-center justify-content-space-between py-12 px-16">
        <div className="xetgo-font-tag flex-row gap-4">
          <span
            className="sub-title cursor-pointer"
            onClick={() => navigate("/auctions")}
          >
            Auctions
          </span>
          <span
            className="sub-title cursor-pointer"
            onClick={() => navigate(`/auctions/${auctionId}`)}
          >
            / {details?.auction.title}
          </span>
          <span
            className="sub-title cursor-pointer"
            onClick={() => navigate(`/auctions/${auctionId}/vendors`)}
          >
            / Vendors
          </span>
          <span
            className={`${activeTab > 0 && "sub-title"} cursor-pointer`}
            onClick={() => navigate(`/auctions/${auctionId}/vendors/${id}`)}
          >
            / {details?.company_details.name}
          </span>
          {activeTab === 1 && <span>/ Form</span>}
          {activeTab === 2 && <span>/ Comments</span>}
          {activeTab === 3 && <span>/ Bids</span>}
        </div>
      </div>
      <div className="auction-detail-lower-main-container px-16 py-20 flex-column gap-12">
        {details && (
          <div className="editng-btns-main-container flex-row gap-8 align-items-center justify-content-end">
            {details?.status === "PENDING_APPROVAL" ? (
              <>
                <div
                  className="edit-btn bold px-14 py-6 flex-row align-items-center justify-content-center xetgo-font-tag cursor-pointer"
                  onClick={() => statusUpdate("EDIT_REQUESTED")}
                >
                  Request Edit
                </div>
                <div
                  className="edit-btn bold approve-btn px-14 py-6 flex-row align-items-center justify-content-center xetgo-font-tag cursor-pointer"
                  onClick={() => statusUpdate("APPROVED")}
                >
                  Approve
                </div>
                <div
                  className="edit-btn bold reject-btn px-14 py-6 flex-row align-items-center justify-content-center xetgo-font-tag cursor-pointer"
                  onClick={() => statusUpdate("REJECTED")}
                >
                  Reject
                </div>
              </>
            ) : (
              <div
                className="status-btn px-24 py-6 flex-row align-items-center justify-content-center xetgo-font-tag cursor-pointer bold"
                style={{
                  background:
                    AuctionVendorInvitationStatus[details?.status].background,
                  border: `1px solid ${
                    AuctionVendorInvitationStatus[details?.status].border
                  }`,
                  color: AuctionVendorInvitationStatus[details?.status].color,
                }}
              >
                {AuctionVendorInvitationStatus[details?.status].name}
              </div>
            )}
          </div>
        )}
        <div className="auction-detail-tab-main-container flex-row align-items-center justify-content-center xetgo-font-button">
          <div
            onClick={() => navigate(`/auctions/${auctionId}/vendors/${id}`)}
            className={`tab-container cursor-pointer border-box flex-row align-items-center gap-8 px-12 py-8 flex-row align-items-center justify-content-center ${
              activeTab == 0 && "activeTab"
            }`}
          >
            <img
              height={18}
              width={18}
              src={`${
                activeTab == 0
                  ? "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20241127205328-hammer-outline.svg"
                  : "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20241127205448-hammer-outline.svg"
              }`}
              alt="auction-icon"
            />
            <p>Vendor Details</p>
          </div>
          <div
            onClick={() =>
              navigate(`/auctions/${auctionId}/vendors/${id}/form`)
            }
            className={`tab-container border-box flex-row align-items-center gap-8 px-12 py-8 flex-row align-items-center justify-content-center        cursor-pointer ${
              activeTab == 1 && "activeTab"
            }`}
          >
            <img
              height={18}
              width={18}
              src={`${
                activeTab == 1
                  ? "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20241122103346-Icon.svg"
                  : "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20241122103512-Icon.svg"
              }`}
              alt="auction-icon"
            />
            <p>Vendor Form</p>
          </div>
          {details?.auction_status === "PAST" && (
            <div
              onClick={() =>
                navigate(`/auctions/${auctionId}/vendors/${id}/bids`)
              }
              className={`tab-container border-box flex-row align-items-center gap-8 px-12 py-8 flex-row align-items-center justify-content-center cursor-pointer ${
                activeTab == 3 && "activeTab"
              }`}
            >
              <img
                height={18}
                width={18}
                src={`${
                  activeTab == 3
                    ? "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20241220060726-hammer_red.svg"
                    : "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20241220060726-hammer_white.svg"
                }`}
                alt="auction-icon"
              />
              <p>Bid History</p>
            </div>
          )}
          <div
            onClick={() =>
              navigate(`/auctions/${auctionId}/vendors/${id}/comments`)
            }
            className={`tab-container border-box flex-row align-items-center gap-8 px-12 py-8 flex-row align-items-center justify-content-center cursor-pointer ${
              activeTab == 2 && "activeTab"
            }`}
          >
            <img
              height={18}
              width={18}
              src={`${
                activeTab == 2
                  ? "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20241127205729-message-square.svg"
                  : "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20241127205657-message-square.svg"
              }`}
              alt="auction-icon"
            />
            <p>Comments</p>
          </div>
        </div>
        {activeTab === 0 && details && (
          <div className="vendor-detail-lower-main-container p-12">
            <p className="header-bar xetgo-font-tag  px-12 py-5">
              Vendor Details
            </p>
            <div className="information-main-container p-18 flex-column gap-40">
              <div className="flex-row ">
                <div className="xetgo-font-tag flex-column gap-8 flex-1">
                  <p className="title">Company Name</p>
                  <p className="sub-title">{details.company_details.name}</p>
                </div>
                <div className="xetgo-font-tag flex-column gap-8 flex-1">
                  <p className="title">POC Name</p>
                  <p className="sub-title">{details.poc.name}</p>
                </div>
                <div className="xetgo-font-tag flex-column gap-8 flex-1">
                  <p className="title">Phone Number</p>
                  <p className="sub-title">{details.poc.phone_number}</p>
                </div>
                <div className="xetgo-font-tag flex-column gap-8 flex-1">
                  <p className="title">Capacity</p>
                  <p className="sub-title">
                    {details.company_details.capacity}{" "}
                    {details.company_details.capacity_unit}
                  </p>
                </div>
              </div>
              <div className="flex-row ">
                <div className="xetgo-font-tag flex-column gap-8 flex-1">
                  <p className="title">PAN Number</p>
                  <p className="sub-title">
                    {details.company_details.pan_number}
                  </p>
                </div>
                <div className="xetgo-font-tag flex-column gap-8 flex-1">
                  <p className="title">GST Number </p>
                  <p className="sub-title">
                    {details.company_details.gst_number}
                  </p>
                </div>
                <div className="xetgo-font-tag flex-column gap-8 flex-1">
                  <p className="title">Trade License Number</p>
                  <p className="sub-title">
                    {details.company_details.trade_license_number}
                  </p>
                </div>
                <div className="xetgo-font-tag flex-column gap-8 flex-1">
                  <p className="title">Company Size</p>
                  <p className="sub-title">
                    {details.company_details.employee_count} Employees
                  </p>
                </div>
              </div>
              <div className="address-main-container flex-column gap-16">
                <p className="heading xetgo-font-tag">Address</p>
                <div className="flex-column gap-18">
                  {details.addresses.length > 0 && (
                    <div className="all-addresses flex-row gap-8 xetgo-font-tag">
                      {details.addresses.map((address, index) => (
                        <p
                          key={index}
                          onClick={() => setSelectedAddressId(index)}
                          className={`${
                            selectedAddressId === index && "address-active"
                          } px-12 py-4 cursor-pointer`}
                        >
                          Factory{index + 1}
                        </p>
                      ))}
                    </div>
                  )}
                  {details.addresses.map((address, index) => (
                    <>
                      {selectedAddressId === index && (
                        <div
                          style={{ color: "#6E6E6E" }}
                          className="address-detail xetgo-font-tag px-12"
                        >
                          <p>{address.address_poc.poc_name}</p>
                          <p>{address.address_line}</p>
                          <p>
                            {address.city}, {address.state}, {address.pincode}
                          </p>
                        </div>
                      )}
                    </>
                  ))}
                </div>
              </div>
              <div className="category-main-container flex-column gap-8">
                <p className="xetgo-font-tag">Category</p>
                {details.vendor_products.length > 0 && (
                  <div className="all-categories flex-row gap-6 xetgo-font-tag">
                    {details.products
                      .filter(
                        (row) => details.vendor_products.indexOf(row.id) > -1,
                      )
                      .map((product, index) => (
                        <p key={index} className="category px-8 py-4">
                          {product.name}
                        </p>
                      ))}
                  </div>
                )}
              </div>
              <div className="category-main-container flex-column gap-8">
                <p className="xetgo-font-tag">Machinery</p>
                {details.vendor_machines.length > 0 && (
                  <div className="all-categories flex-row gap-6 xetgo-font-tag">
                    {details.vendor_machines.map((category, index) => (
                      <p key={index} className="category machinery px-8 py-4">
                        {category}
                      </p>
                    ))}
                  </div>
                )}
              </div>
              <div className="category-main-container flex-column gap-8">
                <p className="xetgo-font-tag">Factory Images</p>
                {details.vendor_images.length > 0 && (
                  <div className="all-categories flex-row gap-6 xetgo-font-tag">
                    {details.vendor_images.map((factory_image, index) => (
                      <a
                        key={index}
                        href={factory_image.url}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          height={32}
                          width={32}
                          src={factory_image.url}
                          className="cursor-pointer"
                          style={{ borderRadius: "5px" }}
                        />
                      </a>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        )}

        {activeTab === 1 && details && (
          <div>
            <VendorFormResult form_id={details.auction.vendor_form_id} />
          </div>
        )}

        {activeTab === 3 && details && (
          <div>
            <VendorBidHistory />
          </div>
        )}

        {activeTab === 2 && details && (
          <div className="comment-section-main-container p-12 border-box">
            <div className="comment-section-inner-container px-12 py-24 border-box flex-column  justify-content-space-between">
              <div className="flex-column gap-12 flex-1 overflow-scroll">
                {details.comments.length > 0 &&
                  details.comments.map((comment, index) => (
                    <CommentCard
                      key={comment.id}
                      avatar={comment.avatar}
                      name={comment.name}
                      timestamp={comment.created_at}
                      description={comment.comment}
                      commentFiles={comment.files}
                      user_id={comment.user_id}
                      hideType={true}
                    />
                  ))}
                <div ref={messagesEndRef} />
              </div>
              <div className="add-comment-container full-width border-box p-8 flex-column">
                {selectedFiles && (
                  <div className="flex-column">
                    {selectedFiles.map((file: File, index: number) => {
                      return (
                        <div
                          style={{ background: "white" }}
                          className="file-card flex-row justify-content-space-between border-box p-8"
                          key={index}
                        >
                          <div className="file-left flex-row gap-8 align-items-center">
                            <img
                              src={getFileIcon(file)}
                              alt="file-icon"
                              height={16}
                              width={16}
                              className="file-preview"
                            />
                            <p
                              className="xetgo-font-tag file-name bold"
                              style={{ color: "#E1E1E1" }}
                            >
                              |
                            </p>
                            <p
                              className="xetgo-font-tag file-name bolder"
                              style={{ color: "#4A4A4A" }}
                            >
                              {file.name}
                            </p>
                          </div>
                          <div className="flex-row gap-12 align-items-center">
                            <img
                              className="cursor-pointer"
                              src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20231012084858-close.svg"
                              alt="close"
                              width={16}
                              height={16}
                              onClick={() => deleteFile(file)}
                            />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
                <div className="full-width border-box xetgo-font-tag comment-value">
                  <textarea
                    style={{ outline: "none" }}
                    className="full-width p-8 border-box xetgo-font-tag"
                    rows={3}
                    onChange={(e) => setNewComment(e.target.value)}
                    value={newComment}
                  ></textarea>
                </div>

                <div className="attach-send-comment-container flex-row align-items-center justify-content-space-between">
                  <input
                    onChange={handleFileChange}
                    id="commentFileInput"
                    name="file"
                    type="File"
                    style={{ display: "none" }}
                    multiple
                  />
                  <label htmlFor="commentFileInput">
                    <img
                      className="cursor-pointer"
                      src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240117075035-_WYSIWYG-toolbar-button.svg"
                      alt="attachment-icon"
                    />
                  </label>
                  <div
                    onClick={handleCommentSubmit}
                    className="send-btn xetgo-font-tag px-13 py-5 flex-row align-items-center justify-content-center gap-12 cursor-pointer"
                  >
                    <span>Send</span>{" "}
                    <img
                      height={16}
                      width={16}
                      src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20241128100505-send.svg"
                      alt="send-icon"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AuctionVendorDetailPage;
