import { createContext, useContext } from "react";

export interface IAuctionSKU {
  id: number;
  name: string;
  description: string;
  unit: string;
  quantity: string;
  starting_price: string;
  decrement_price: string;
}

export interface IAuction {
  auction_basic_details: {
    title: string;
    type: string;
    currency: string;
    start_time: string;
    end_time: string;
    description: string;
    guidelines: string;
    guidelineFileName: string;
  };
  auction_buyer_details: {
    name: string;
    email: string;
    location: string;
    contact: string;
  }[];
  auction_sku_details: {
    address_id: number;
    address: string;
    skus: IAuctionSKU[];
  }[];

  vendor_invitation_details: {
    invitation_type: string;
    invited_vendors_ids: number[];
    invited_vendors: { name: string; id: number }[];
    form_id: number;
    form_name: string;
    notes: string;
  };
}

export type IAuctionContext = {
  auction: IAuction;
  setBasicDetails: (key: string, value: string) => void;
  setBuyerDetails: (index: number, key: string, value: string) => void;
  setAddress: (index: number, value: { name: string; id: number }) => void;
  setAddressSkuDetails: (
    ad_index: number,
    sku_index: number,
    key: string,
    value: number | string,
  ) => void;
  addAddress: () => void;
  deleteAddress: (id: number) => void;
  addAddressSku: (ad_index: number, sku: IAuctionSKU) => void;
  deleteAddressSku: (ad_index: number, sku_id: number) => void;
  setVendorInvitationDetails: (
    key: string,
    val: string | number | number[] | { name: string; id: number }[],
  ) => void;
};

export const NEW_AUCTION_VALUES: IAuction = {
  auction_basic_details: {
    title: "",
    type: "REVERSE",
    currency: "INR",
    start_time: "",
    end_time: "",
    description: "",
    guidelines: "",
    guidelineFileName: "",
  },
  auction_buyer_details: [
    {
      name: "",
      email: "",
      location: "",
      contact: "",
    },
  ],
  auction_sku_details: [
    {
      address_id: -1,
      address: "",
      skus: [],
    },
  ],
  vendor_invitation_details: {
    invitation_type: "PRIVATE",
    invited_vendors_ids: [],
    invited_vendors: [],
    form_id: -1,
    notes: "",
    form_name: "",
  },
};

export const AuctionContext = createContext<IAuctionContext>({
  auction: NEW_AUCTION_VALUES,
  setBasicDetails: (key: string, value: string) => {},
  setBuyerDetails: (index: number, key: string, value: string) => {},
  setAddress: (index: number, value: { name: string; id: number }) => {},
  setAddressSkuDetails: (
    ad_index: number,
    sku_index: number,
    key: string,
    value: number | string,
  ) => {},
  addAddress: () => {},
  deleteAddress: (id: number) => {},
  addAddressSku: (ad_index: number, sku: IAuctionSKU) => {},
  deleteAddressSku: (ad_index: number, sku_id: number) => {},
  setVendorInvitationDetails: (
    key: string,
    val: string | number | number[] | { name: string; id: number }[],
  ) => {},
});

export const useAuctionContext = () => useContext(AuctionContext);
