import React, { useEffect, useState, useRef, ChangeEvent } from "react";
import "./CreateAuctionForm.scss";
import { useLocation, useNavigate } from "react-router-dom";
import ApiService from "../../services/apiService";
import URLS from "../../urls/Urls";
import CreateAuction from "../CreateAuction/CreateAuction";
import AuctionVendorInvitation from "../../components/AuctionVendorInvitation/AuctionVendorInvitation";
import AuctionReviewConfirmation from "../../components/AuctionReviewConfirmation/AuctionReviewConfirmation";
import AuctionAddressSku from "../../components/AuctionAddressSku/AuctionAddressSku";
import AuctionConfirmationAlert from "../../components/AuctionConfirmationAlert/AuctionConfirmationAlert";
import XetModal from "../../components/shared/xet-modal/XetModal";
import {
  AuctionContext,
  IAuctionSKU,
  NEW_AUCTION_VALUES,
} from "../../context/create-auction-context";

interface AuctionFormProps {
  active?: number;
  update?: boolean;
  vendorFormId?: number;
}

const CreateAuctionForm: React.FC<AuctionFormProps> = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { update, active } = location.state || {};
  const [activeStep, setActiveStep] = useState(active || 0);
  const [openAuctionSubmitDialog, setOpenAuctionSubmitDialog] = useState(false);
  const [newAuction, setNewAuction] = useState(NEW_AUCTION_VALUES);

  const setBasicDetails = (key: string, val: string) => {
    setNewAuction((value) => {
      return {
        ...value,
        auction_basic_details: { ...value.auction_basic_details, [key]: val },
      };
    });
  };

  const setBuyerDetails = (index: number, key: string, val: string) => {
    setNewAuction((value) => {
      const buyerDetails = value.auction_buyer_details.map((row, ind) => {
        if (ind === index) {
          return { ...row, [key]: val };
        }
        return row;
      });
      return {
        ...value,
        auction_buyer_details: buyerDetails,
      };
    });
  };

  const setAddress = (index: number, val: { id: number; name: string }) => {
    setNewAuction((value) => {
      value.auction_sku_details[index].address_id = val.id;
      value.auction_sku_details[index].address = val.name;
      return { ...value };
    });
  };

  const setAddressSkuDetails = (
    ad_index: number,
    sku_index: number,
    key: string,
    val: number | string,
  ) => {
    setNewAuction((value) => {
      const entry = value.auction_sku_details.map((row, ind) => {
        if (ind === ad_index) {
          row.skus[sku_index] = { ...row.skus[sku_index], [key]: val };
        }
        return row;
      });
      return { ...value, auction_sku_details: entry };
    });
  };

  const addAddress = () => {
    setNewAuction((value) => {
      value.auction_sku_details.push({
        address_id: -1,
        address: "",
        skus: [],
      });
      return { ...value };
    });
  };

  const deleteAddress = (id: number) => {
    setNewAuction((value) => {
      const entry = value.auction_sku_details.filter(
        (row) => row.address_id !== id,
      );
      return { ...value, auction_sku_details: entry };
    });
  };

  const addAddressSku = (ad_index: number, sku: IAuctionSKU) => {
    setNewAuction((value) => {
      value.auction_sku_details[ad_index].skus.push(sku);
      return { ...value };
    });
  };

  const deleteAddressSku = (ad_index: number, sku_id: number) => {
    setNewAuction((value) => {
      const entry = value.auction_sku_details.map((val, index) => {
        if (ad_index === index) {
          return { ...val, skus: val.skus.filter((row) => row.id !== sku_id) };
        }
        return val;
      });
      return { ...value, auction_sku_details: entry };
    });
  };

  const setVendorInvitationDetails = (
    key: string,
    val: string | number | number[] | { name: string; id: number }[],
  ) => {
    setNewAuction((value) => {
      return {
        ...value,
        vendor_invitation_details: {
          ...value.vendor_invitation_details,
          [key]: val,
        },
      };
    });
  };

  const handleBackStep = () => {
    if (activeStep === 0) {
      navigate("/auctions");
    } else {
      setActiveStep(activeStep - 1);
    }
  };

  const handleNextStep = () => {
    if (activeStep < 3) {
      setActiveStep(activeStep + 1);
    } else {
      console.log("handle submit ");
    }
  };

  const handleConfirmationAndSubmit = () => {
    setOpenAuctionSubmitDialog(true);
  };

  const createAuction = async () => {
    try {
      const formData = new FormData();
      formData.append("data", JSON.stringify(newAuction));
      const { data } = await ApiService().client.post(
        URLS.AUCTION.CREATE,
        formData,
      );
      if (data) {
        navigate(`/auctions/${data.auction_id}`);
      }
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div className="create-auction-form-page flex-column full-width ">
      {!update && (
        <div className="upper-bar">
          <p
            onClick={() => navigate("/forms")}
            className="xetgo-font-tag bold px-16 py-12 cursor-pointer"
          >
            Auction <span style={{ color: "#7B7D84" }}>/ New</span>
          </p>
        </div>
      )}
      <div className="auction-main-form-container full-width flex-column gap-20 flex-1 ">
        <div className="xet-form-upper-container flex-row align-items-center justify-content-center px-27 py-14 gap-16">
          <div className="left-box flex-row gap-16 align-items-center justify-content-center">
            <div className="auction-step-detail-container flex-column align-items-center gap-4 xetgo-font-tag">
              <p
                className={`form-step flex-row align-items-center justify-content-center p-2 border-box ${
                  activeStep === 0 && "form-step-active"
                }`}
              >
                1
              </p>
              <p className={`${activeStep === 0 && "title-active"}`}>
                Create Auction
              </p>
            </div>
            <p className="auction-step-dashed"></p>
            <div className="auction-step-detail-container flex-column gap-4 align-items-center xetgo-font-tag">
              <p
                className={`form-step flex-row align-items-center justify-content-center p-2 border-box ${
                  activeStep === 1 && "form-step-active"
                }`}
              >
                2
              </p>
              <p className={`${activeStep === 1 && "title-active"}`}>
                Address & SKU
              </p>
            </div>
            <p className="auction-step-dashed"></p>
            <div className="auction-step-detail-container flex-column gap-4 align-items-center xetgo-font-tag">
              <p
                className={`form-step flex-row align-items-center justify-content-center p-2 border-box ${
                  activeStep === 2 && "form-step-active"
                }`}
              >
                3
              </p>
              <p className={`${activeStep === 2 && "title-active"}`}>
                Vendor Invitation
              </p>
            </div>
            <p className="auction-step-dashed"></p>
            <div className="auction-step-detail-container flex-column gap-4 align-items-center xetgo-font-tag">
              <p
                className={`form-step flex-row align-items-center justify-content-center p-2 border-box ${
                  activeStep === 3 && "form-step-active"
                }`}
              >
                4
              </p>
              <p className={`${activeStep === 3 && "title-active"}`}>
                Review & Confirm
              </p>
            </div>
          </div>
        </div>
        <AuctionContext.Provider
          value={{
            auction: newAuction,
            setBasicDetails,
            setBuyerDetails,
            setAddress,
            addAddress,
            deleteAddress,
            addAddressSku,
            deleteAddressSku,
            setAddressSkuDetails,
            setVendorInvitationDetails,
          }}
        >
          <div className="auction-form-step-one-below-main-container flex-column justify-content-space-between overflow-scroll flex-1">
            {activeStep === 0 && <CreateAuction />}
            {activeStep === 1 && <AuctionAddressSku />}
            {activeStep === 2 && <AuctionVendorInvitation />}
            {activeStep === 3 && <AuctionReviewConfirmation />}
            <XetModal
              backdropClose={true}
              open={openAuctionSubmitDialog}
              handleClose={() => setOpenAuctionSubmitDialog(false)}
            >
              <AuctionConfirmationAlert
                onAuctionSubmit={createAuction}
                onEditAuction={() => setOpenAuctionSubmitDialog(false)}
              />
            </XetModal>
            <div className="auction-step-one-btn-container flex-row align-items-center gap-12 px-16 py-8">
              <div
                onClick={handleBackStep}
                className="back-btn py-7 cursor-pointer flex-row align-items-center justify-content-center xetgo-font-tag bold"
              >
                Back
              </div>
              {activeStep !== 3 && (
                <div
                  onClick={handleNextStep}
                  className="next-step-btn flex-row align-items-center justify-content-center  py-7 gap-4 cursor-pointer xetgo-font-tag bold"
                >
                  <p>
                    {activeStep === 3
                      ? "Submit"
                      : activeStep === 2
                        ? "Review"
                        : "Next Step"}
                  </p>
                  {activeStep !== 2 && activeStep !== 3 && (
                    <img
                      className="next-step-forward"
                      src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240308090308-arrow-right+%281%29.svg"
                      alt="next-icon"
                      height={16}
                      width={16}
                    />
                  )}
                </div>
              )}
              {activeStep === 3 && (
                <div
                  onClick={handleConfirmationAndSubmit}
                  className="next-step-btn flex-row align-items-center justify-content-center  py-7 gap-4 cursor-pointer xetgo-font-tag bold"
                >
                  <p>Submit</p>
                </div>
              )}
            </div>
          </div>
        </AuctionContext.Provider>
      </div>
    </div>
  );
};

export default CreateAuctionForm;
