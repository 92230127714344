import { toast } from "react-toastify";
import { toastOptions } from "../../utils/toast";
import "./TeamCreationDialog.scss";
import React, { useEffect, useState } from "react";
import Filter from "../Filter/Filter";
import { FilterOptionSchema } from "../Filter/filter.data";
import { NewCompanyAddressPayloadSchema } from "../../redux/user/userTypes";
import { useSelector } from "react-redux";
import { userDetails } from "../../redux/user/userSelector";
import ApiService from "../../services/apiService";
import URLS from "../../urls/Urls";
import { breakPointObserver, ScreenSizes } from "../../utils/BreakpointObserver";

interface OnSavePayload {
  name:string
  description: string,
  members: number[],
}

interface EditUserInfoDialogProps {
  onCancel: () => void;
  onSave: (payload: OnSavePayload) => void;
}

const TeamCreationDialog: React.FC<EditUserInfoDialogProps> = ({
  onCancel,
  onSave,
}) => {
  const userDetail = useSelector(userDetails);
  const [teamName,setTeamName] = useState( '')
  const [description,setDescription] = useState( '')
  const [allMembers,setAllMembers] = useState<FilterOptionSchema[]>([])
  const [selectedMembers,setSelectedMembers] = useState<FilterOptionSchema[]>([])
  const [breakpoint, setBreakpoint] = useState("");

    useEffect(() => {
      breakPointObserver(setBreakpoint);
    }, [breakpoint]);

  useEffect(()=>{
    fetchCompanyMembers(userDetail.id||-1)
  },[])

  const fetchCompanyMembers =async(id:number)=>{
    try {
    
      const { data } = await ApiService().client.get(
        URLS.TEAM.TEAM_MEMBERS(userDetail.company_id ||-1));
      const memberData = data.members.map((member:any) => ({
        id: member.member_id,
        name: member.name,
      }))
      setAllMembers(memberData)
    
  } catch (error) {
    console.error("Error Fetching Team members", error);
  }
  }

  const handleReset = ()=>{
    setTeamName('')
    setDescription('')
    setSelectedMembers([])
  }
  
  const handleSave = () => {
    const memberIds = selectedMembers.map((member,id)=>member.id)
    const data:OnSavePayload= {
      name:teamName,
      description:description,
      members:memberIds 
    }

    if (Object.keys(data).length === 0) {
      toast.info("No changes to save", toastOptions);
      onCancel();
      return;
    }
    if(teamName.length==0 ||selectedMembers.length==0 || description.length==0){
      toast.error("Please Fill the Details", toastOptions);
      return ;
    }
  
    onSave(data);
  };

  const handleRemoveSelectedMember  =(id:number)=>{
    setSelectedMembers((prevMembers) => prevMembers.filter((prevMember, i) => prevMember.id !== id));
  }
  return (
    <div className={`create-team-information-dialog px-16 py-24 flex-column gap-12 ${selectedMembers.length>0 &&'create-team-information-dialog-members '}`}>
      <p className="xetgo-font-tag bold">Create New Team</p>

      <div className="edit-user-middle-info-main-container flex-column gap-16 p-12">
        <div className="flex-column gap-24">
          <div className="flex-column gap-16 flex-1">
            <p className="xetgo-font-tag">Team Name</p>
            <input className="information-input xetgo-font-tag px-8 py-6" onChange={(e)=>setTeamName(e.target.value)} value={teamName} placeholder="Enter Name" />
            </div>
            <div className="flex-column gap-16 flex-1">
            <p className="xetgo-font-tag">Description</p>
            <textarea className="information-input description-input border-box xetgo-font-tag px-8 py-6" onChange={(e)=>setDescription(e.target.value)} value={description} placeholder="Enter Description"></textarea>
            </div>
        </div>
        <div className="flex-column gap-8">
          <p className="xetgo-font-tag">Members</p>
            <Filter
            option={{
              key: "assignees",
              name: "Member",
            }}
            optionList={allMembers }
            multipleSelection={true}
            onSelection={(val, _option) => {
              setSelectedMembers(val);
            }}
            vertical={`${[
                                          ScreenSizes.MINI_TABLET,
                                          ScreenSizes.MOBILE,
                                        ].includes(breakpoint as ScreenSizes) ?'top':'bottom'}`}
            preSelected={selectedMembers}
            mode="showNames"
            showPlaceholder={true}
            direction="left"
            addNew={false}
            classname="information-input member-filter border-box xetgo-font-tag"
          />
        </div>
       {selectedMembers.length>0&& <div className="emails-main-container flex-row gap-8">
        {selectedMembers.map((member,index)=> <div key={member.id} className="email-tag flex-row gap-6 px-6 py-4">
          <p  className="xetgo-font-tag">{member.name}</p>
          <img onClick={()=>handleRemoveSelectedMember(member.id)} className="cursor-pointer" height={14} width={14} src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240715055536-Frame+1321314633.svg" alt="close-circle-icon" />
        </div>)}
        
        </div>}
         </div>




      <div className="edit-user-btns-main-container flex-row align-items-center justify-content-space-between full-width">
        <p onClick={handleReset} className="reset xetgo-font-tag bold cursor-pointer">Reset</p>
        <div className="flex-row gap-12">
          <div onClick={()=>onCancel()} className="cancel-btn px-12 py-8 xetgo-font-tag bold cursor-pointer">Cancel</div>
          <div onClick={handleSave} className="cancel-btn save px-12 py-8 xetgo-font-tag bold cursor-pointer">Save</div>
        </div>
      </div>
    </div>
  );
};

export default TeamCreationDialog;
