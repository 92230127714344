import React, { useEffect, useState } from "react";
import "./SkuArtworkTab.scss";
import { SkuDetailSchema } from "../../models/SkuListSchema";
import XetModal from "../shared/xet-modal/XetModal";
import { useSelector } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import ApiService from "../../services/apiService";
import URL from "../../urls/Urls";
import { userDetails } from "../../redux/user/userSelector";
import { FilterOptionSchema } from "../Filter/filter.data";
import Filter from "../Filter/Filter";
import { formatDate } from "../../utils/Helper";
import { formatTime } from "../../utils/date-util";
import ArtworkDialog from "../ArtworkDialog/ArtworkDialog";
import XetImage from "../shared/xet-image/XetImage";
import ArtworkDropdown from "../ArtworkDropdown/ArtworkDropdown";


interface SkuPricingProps {
  sku: SkuDetailSchema;
  refresh: boolean;
}

interface ArtworkSchema {
  artwork_url: string;
  category: string;
  created_at: string;
  id: number;
  source: string;
  status: string;
}
interface ArtworkCommentSchema {
  id: number;
  comment: string;
  user_avatar: string | null;
  user_name: string;
  created_at: string;
}

const SkuArtworkTab: React.FC<SkuPricingProps> = ({ sku, refresh }) => {
  const user = useSelector(userDetails);
  const STATUS = [
    {
      id: 11,
      name: "Approved",
    },
    {
      id: 12,
      name: "Rework",
    },
    {
      id: 13,
      name: "Review",
    },
    {
      id: 14,
      name: "Rejected",
    },
  ];
  const [selectedStatus, setSelectedStatus] = useState<FilterOptionSchema[]>(
    [],
  );
  const [justCreatedIds, setJustCreatedIds] = useState<number[]>([]);

  const [artworkList, setArtworkList] = useState<ArtworkSchema[]>([]);
  const [commentList, setCommentList] = useState<ArtworkCommentSchema[]>([]);
  const [commentPageNumber, setCommentPageNumber] = useState<number>(1);
  const [artworkPageNumber, setArtworkPageNumber] = useState<number>(1);
  const [artworkHasMore, setArtworkHasMore] = useState(true);
  const [commentHasMore, setCommentHasMore] = useState(true);
  const perPage = 20;

  const [openArtworkDialog, setOpenArtworkDialog] = useState(false);
  const [comment, setComment] = useState("");

  useEffect(() => {
    getSkuArtworkList(1,status);
  }, [selectedStatus]);

  useEffect(() => {
    getCommentList(1);
  }, []);

  const getSkuArtworkList = async (page:number, statuses: string) => {
    try{
    const { data, status } = await ApiService().client.get(
      URL.SKU.GET_SKU_ARTWORKS(sku.id),
      {
        params: {
          pageNumber: page,
          status:
            selectedStatus.length > 0
              ? selectedStatus[0].name.toUpperCase()
              : "",
        },
      },
    );
    if (status === 200) {
      let updatedSkuArtworks;
      if (selectedStatus.length > 0 && page === 1) {
        updatedSkuArtworks = data.data;
        setArtworkPageNumber(1);
      } else {
        updatedSkuArtworks =
          page > 1 ? [...artworkList, ...data.data] : data.data;
        setArtworkPageNumber(artworkPageNumber + 1);
      }
      setArtworkList(updatedSkuArtworks);
      if (data.data.length < perPage) {
        setArtworkHasMore(false);
      } else {
        setArtworkHasMore(true);
      }
    } else {
      setArtworkHasMore(false);
    }
  } catch (error) {
    console.error("Error Fetching sku artworks", error);
  }
  };

  const getCommentList = async (page: number) => {
    try {
      const { data } = await ApiService().client.get(
        URL.SKU.GET_SKU_ARTWORK_COMMENTS(sku.id),
        {
          params: {
            pageNumber: page,
          },
        },
      );
      if (data.data.length > 0) {
        const updatedSkuArtworkComments =
          page > 1 ? [...commentList, ...data.data] : data.data;
        setCommentList(updatedSkuArtworkComments);
        setCommentPageNumber(commentPageNumber + 1);
        if (data.data.length == perPage) {
          setCommentHasMore(true);
        } else {
          setCommentHasMore(false);
        }
      } else {
        setCommentHasMore(false);
      }
    } catch (error) {
      console.error("Error fetching quotes:", error);
      return [];
    }
  };

  const handleCommentSubmit = async () => {
    try {
      if (comment.length <= 0) {
        return;
      }
      const { data } = await ApiService().client.post(
        URL.SKU.ADD_SKU_ARTWORK_COMMENT(sku.id),
        { comment: comment },
      );
      setComment("");
      commentList.unshift(data.data);
    } catch (error) {
      console.error("Error adding comment", error);
    }
  };

  const handleArtworkSubmit = (data: ArtworkSchema) => {
    setArtworkList((prev) => [data, ...prev]);
    setOpenArtworkDialog(false);
    justCreatedIds.push(data.id);
  };

  const handleStatusUpdate = async (artworkId: number, newStatus: string) => {
    try {
      const { data } = await ApiService().client.put(
        URL.SKU.UPDATE_ARTWORK_STATUS(artworkId),
        { artwork_id: artworkId, status: newStatus.toUpperCase() },
      );
      setArtworkList((prev) =>
        prev.map((prevArtwork) =>
          prevArtwork.id === artworkId
            ? { ...prevArtwork, status: newStatus.toUpperCase() }
            : prevArtwork,
        ),
      );
    } catch (error) {
      console.error("Error updating status", error);
    }
  };

  const handleMoreClick = () => {
    getCommentList(commentPageNumber);
  };

  const checkOpeningUpward = (index: number) => {
    const multipliedValue = index * 44;
    return multipliedValue <= 44 * 13;
  };

  return (
    <div className="sku-artwork-main-container flex-column gap-20 full-height">
      <div className="sku-artwork-filter-main-container flex-row align-items-center justify-content-space-between">
        <Filter
          showSearch={false}
          option={{
            key: "status",
            name: selectedStatus.length > 0 ? "" : "Status",
          }}
          optionList={STATUS}
          multipleSelection={false}
          onSelection={(val, _option) => {
            if (val) {
              setSelectedStatus(val);
            } else {
              setSelectedStatus([]);
            }
          }}
          mode="showNames"
          showPlaceholder={true}
          showId={false}
          preSelected={selectedStatus}
          direction="left"
          addNew={false}
          classname="status-filter"
        />
        <div className="upload-btn-container flex-row justify-  content-end flex-1">
          <div
            onClick={() => setOpenArtworkDialog(true)}
            className="upload-btn xetgo-font-tag px-20 py-7 border-box cursor-pointer"
          >
            Upload Artwork{" "}
          </div>
        </div>
      </div>

      <div className="sku-artwork-lower-main-container flex-row gap-12 flex-1">
        <div className="lower-left-main-container flex-1">
          <div className="sku-artwork-grid header-row xetgo-font-tag">
            <div className="cell-right-border px-8 border-box flex-row align-items-center border-box">
              <p>Source</p>
            </div>
            <p className="cell-right-border flex-row align-items-center px-8 border-box">
              Status
            </p>
            <p className="cell-right-border flex-row align-items-center px-8 border-box">
              Category
            </p>
            <p className="cell-right-border flex-row align-items-center px-8 border-box">
              Artwork
            </p>
            <p className="flex-row align-items-center px-8 border-box">
              Created at
            </p>
          </div>

          <div
            className={`all-sku-artwork-main-container flex-column  ${
              artworkList.length === 0 && "empty-artwork-state-main-container"
            } ${artworkList.length < 11 && "overflowVisible"}`}
          >
            {artworkList.length === 0 && (
              <div className="empty-artwork-state xetgo-font-tag bold">
                No history to show here
              </div>
            )}
            <div
              id="scrollableDiv"
              className={`artwork-all-list-scroll-wrapper flex-1 ${
                artworkList.length < 11 && "overflowVisible"
              }`}
            >
              {artworkList.map((artwork, index) => (
                <div
                  key={artwork.id}
                  className={`sku-artwork-grid artwork-detail-container flex-row align-items-center ${
                    artworkList.length - 1 === index && "bottom-border-none"
                  }  ${justCreatedIds.includes(artwork.id) && "just-created"}`}
                >
                  <p className="capitalised-status cell cell-right-border flex-row align-items-center border-box xetgo-font-tag p-8">
                    {artwork.source.toLowerCase()}
                  </p>
                  <div className=" cell cell-right-border flex-row align-items-center border-box xetgo-font-tag p-8">
                    <ArtworkDropdown
                      key={artwork.id}
                      artworkId={artwork.id}
                      currentStatus={artwork.status}
                      handleStatusUpdate={handleStatusUpdate}
                      openUpwards={checkOpeningUpward(index + 1)}
                    />
                  </div>
                  <p className="cell cell-right-border flex-row align-items-center border-box xetgo-font-tag p-8">
                    {artwork.category}
                  </p>

                  <p className="view-file cell-right-border xetgo-font-tag bold p-8 cell flex-row align-items-center border-box">
                    <a
                      href={artwork.artwork_url}
                      target="_blank"
                      rel="noreferrer"
                      className="view cursor-pointer"
                    >
                      View
                    </a>
                  </p>
                  <p className="cell flex-row gap-2 align-items-center border-box xetgo-font-tag p-8">
                    {formatDate(artwork.created_at)},{" "}
                    <span className="time">
                      {formatTime(artwork.created_at)}
                    </span>
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="lower-right-main-container px-20 py-8 flex-column gap-18">
          <p className="comment-heading xetgo-font-button">Comments </p>
          <div className="upper-comment-box-main-container flex-column gap-8">
            <div className="flex-row gap-8 align-items-center">
              <XetImage
                className="display-pic"
                src={user.avatar}
                alt={user.name || ''}
                width={22}
                height={22}
                key={user.id}
              />
              <p className="user-name xetgo-font-tag bold">{user.name}</p>
            </div>
            <div className="flex-column gap-2 comment-area">
              <textarea
                placeholder="Write comment here ..."
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                className=" xetgo-font-mini p-8 full-width border-box"
              ></textarea>
              <p className=" p-2"></p>
            </div>
            <div className="comment-btn-container flex-row align-items-center ">
              <div
                onClick={handleCommentSubmit}
                className="comment-btn xetgo-font-tag bolder px-20 py-6 cursor-pointer"
              >
                Comment
              </div>
            </div>
          </div>

          {commentList.length > 0 && (
            <div
              id="commentScrollableDiv"
              className="comment-list-scroll-wrapper flex-1  flex-column gap-18"
            >
              {commentList.length > 0 &&
                commentList.map((comment, index) => {
                  return (
                    <div
                      key={index}
                      className="comment-detail-container flex-column gap-4"
                    >
                      <div className="flex-row align-items-center justify-content-space-between">
                        <div className="flex-row gap-8 align-items-center">
                          <XetImage
                            className="display-pic"
                            src={comment.user_avatar}
                            alt={comment.user_name}
                            width={22}
                            height={22}
                            key={user.id}
                          />
                          <p className="user-title user-name-comment xetgo-font-tag bold">
                            {comment.user_name}
                          </p>
                        </div>
                        <p className="comment-creation xetgo-font-mini">
                          {formatDate(comment.created_at)},{" "}
                          <span>{formatTime(comment.created_at)}</span>
                        </p>
                      </div>
                      <p className="user-name-comment user-comment xetgo-font-mini">
                        {comment.comment}
                      </p>
                    </div>
                  );
                })}
              {commentHasMore && (
                <div
                  onClick={handleMoreClick}
                  className="load-more-btn xetgo-font-tag flex-row align-items-center py-6 justify-content-center cursor-pointer"
                >
                  Load More
                </div>
              )}
            </div>
          )}
          {commentList.length === 0 && (
            <div
              style={{ color: "#626262" }}
              className="flex-row justify-content-center p-4 xetgo-font-tag bold"
            >
              No comments yet
            </div>
          )}
        </div>
      </div>
      {openArtworkDialog && (
        <XetModal
          backdropClose={true}
          open={openArtworkDialog}
          handleClose={() => setOpenArtworkDialog(false)}
        >
          <ArtworkDialog
            handleClose={() => setOpenArtworkDialog(false)}
            skuId={sku.id}
            handleArtworkSubmit={handleArtworkSubmit}
          />
        </XetModal>
      )}
    </div>
  );
};

export default SkuArtworkTab;
