import React, { useEffect, useState } from "react";
import "./SkuPricing.scss";
import { SkuDetailSchema } from "../../models/SkuListSchema";
import ApiService from "../../services/apiService";
import URLS from "../../urls/Urls";
import { formatDate } from "../../utils/Helper";
import environment from "../../environments/environment";
import { useSelector } from "react-redux";
import { userDetails } from "../../redux/user/userSelector";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { toastOptions } from "../../utils/toast";

interface SkuPricingProps {
  sku: SkuDetailSchema;
}

interface SkuVendorPrice {
  sku_price_id: number;
  price: number;
  unit: string;
  quantity: number;
  lead_time: number;
  name: string;
  vendor_id: number;
  created_at: string;
  source_id: number;
  source_type: string;
  quote_id: number;
  id: number;
}

interface SkuVendorPriceHistory {
  price: number;
  quantity: number;
  lead_time: number;
  created_at: string;
  source_type: string;
  source_id: number;
}

const SkuPricing: React.FC<SkuPricingProps> = ({ sku }) => {
  const user = useSelector(userDetails);
  const { id } = useParams();

  const [skuDetail, setSkuDetail] = useState<{
    [id: number]: SkuVendorPrice[];
  }>({});
  const [priceId, setPriceId] = useState(-1);
  const [priceHistory, setPriceHistory] = useState<SkuVendorPriceHistory[]>([]);

  useEffect(() => {
    getVendorPrices();
  }, []);

  useEffect(() => {
    if (priceId !== -1) {
      getVendorPriceHistory();
    }
  }, [priceId]);

  const getVendorPrices = async () => {
    const { data } = await ApiService().client.get(
      URLS.SKU.GET_SKU_VENDOR_PRICES(sku.id),
    );
    setSkuDetail(data);
  };

  const toggleHistory = (price_id: number) => {
    if (priceId === price_id) {
      setPriceId(-1);
      setPriceHistory([]);
    } else {
      setPriceHistory([]);
      setPriceId(price_id);
    }
  };

  const getVendorPriceHistory = async () => {
    const { data } = await ApiService().client.get(
      URLS.SKU.GET_SKU_VENDOR_PRICE_HISTORY(sku.id, priceId),
    );
    setPriceHistory(data.history);
  };

  const handleVendorDetailNavigation = (id: number) => {
    const url = `${environment.clientUrl}/vendor/details/${id}`;
    window.open(url, "_blank");
  };
  const handleNegotiation = async (
    entry: SkuVendorPrice,
    addressID: number,
  ) => {
    try {
      const { data } = await ApiService().client.post(
        URLS.NEGOTIATIONS.CREATE_NEGOTIATION,
        {
          lead_time: entry.lead_time,
          quantity: entry.quantity,
          address_id: addressID,
          sku_id: Number(id),
          vendor_id: entry.vendor_id,
          category: "VENDOR",
          price: Number(entry.price),
          client_id: user.company_id,
        },
      );
      toast.success("Negotiation Started successfully!", toastOptions);
      window.open(`${environment.baseUrl}/negotiations`, "_blank");
    } catch (e) {
      console.error("Error approving negotiation price:", e);
    }
  };

  return (
    <div className="sku-pricing-container">
      <div className="sku-pricing-header sku-pricing-main-grid">
        <div className="flex-row gap-8 align-items-center p-12">
          <img
            src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240520063058-user.svg"
            alt="warehouse"
            width={12}
            height={12}
          />
          <p className="xetgo-font-tag bold" style={{ color: "#9D9D9D" }}>
            Vendor
          </p>
        </div>
        <div className="flex-row gap-8 align-items-center p-12">
          <img
            src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240716182234-boxes.svg"
            alt="moq"
            width={12}
            height={12}
          />
          <p className="xetgo-font-tag bold" style={{ color: "#9D9D9D" }}>
            MOQ
          </p>
        </div>
        <div className="flex-row gap-8 align-items-center p-12">
          <img
            src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240709111945-%E2%82%B9.svg"
            alt="price"
            height={12}
          />
          <p className="xetgo-font-tag bold" style={{ color: "#9D9D9D" }}>
            Price
          </p>
        </div>
        <div className="flex-row gap-8 align-items-center p-12">
          <img
            src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240710053743-table-properties.svg"
            alt="warehouse"
            width={12}
            height={12}
          />
          <p className="xetgo-font-tag bold" style={{ color: "#9D9D9D" }}>
            Lead Time
          </p>
        </div>
        <div className="flex-row gap-8 align-items-center p-12">
          <img
            src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240703143713-calendar.svg"
            alt="warehouse"
            width={12}
            height={12}
          />
          <p className="xetgo-font-tag bold" style={{ color: "#9D9D9D" }}>
            Start Time
          </p>
        </div>
        <div className="flex-row gap-8 align-items-center p-12">
          <img
            src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240520062831-file-box+%281%29.svg"
            alt="source"
            width={12}
            height={12}
          />
          <p className="xetgo-font-tag bold" style={{ color: "#9D9D9D" }}>
            Source
          </p>
        </div>
      </div>
      {sku.locations.map((row, index) => (
        <div key={`address-container-${index}`}>
          <div>
            <div className="flex-row align-items-center gap-8 px-12 py-12 address-container">
              <div className="flex-row gap-10 align-items-center address-name-container">
                <img
                  src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240716103610-business.svg"
                  alt="warehouse"
                  width={12}
                  height={12}
                />
                <p className="xetgo-font-tag bold" style={{ color: "#000000" }}>
                  {row.address_city}
                </p>
              </div>
              <p
                className="address-name-container xetgo-font-tag bold"
                style={{ color: "#686868" }}
              >
                {row.address_line}
              </p>
            </div>
            {skuDetail &&
              (skuDetail[row.id] || []).map((entry, index) => (
                <div
                  key={`price-entry-${index}`}
                  onClick={() => toggleHistory(entry.sku_price_id)}
                  className="flex-row"
                >
                  <div
                    style={{ borderTop: "1px solid #e8e8e8" }}
                    className="sku-pricing-entry flex-1"
                  >
                    <p
                      className="px-12 py-8 xetgo-font-tag price-entry bold cursor-pointer "
                      style={{
                        color: "#AA5200",
                        borderBottom: "1px solid #e8e8e8",
                        minWidth: "150px",
                      }}
                    >
                      <span
                        onClick={(e) => {
                          e.stopPropagation();
                          handleVendorDetailNavigation(entry.vendor_id);
                        }}
                        className="vendor-name"
                      >
                        {entry.name}
                      </span>
                    </p>
                    <div>
                      <div className="sku-pricing-history-grid">
                        <p
                          className="px-12 py-8 xetgo-font-tag price-entry bold"
                          style={{ background: "#fff0e2" }}
                        >
                          {entry.quantity} {entry.unit}
                        </p>
                        <p
                          className="px-12 py-8 xetgo-font-tag price-entry bold"
                          style={{ background: "#E8FFEA" }}
                        >
                          ₹ {entry.price}
                        </p>
                        <p
                          className="px-12 py-8 xetgo-font-tag price-entry bold"
                          style={{ background: "#FFE8F4" }}
                        >
                          {entry.lead_time ? `${entry.lead_time} days` : "---"}
                        </p>
                        <p className="px-12 py-8 xetgo-font-tag bold price-entry">
                          {formatDate(entry.created_at)}
                        </p>
                        <div className="flex-row align-items-center py-4 px-12 justify-content-space-between">
                          <a
                            href={
                              entry.source_type === "Quote"
                                ? `/quotes/${entry.source_id}`
                                : `/auctions/${entry.source_id}/bid-hall`
                            }
                            className="px-12 py-4 xetgo-font-tag bold quote-tag"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {entry.source_type.toUpperCase().substring(0, 4) +
                              "0".repeat(
                                4 - entry.source_id.toString().length,
                              ) +
                              entry.source_id.toString()}
                          </a>
                          <div className="negotiate-container">
                            <img
                              onClick={(e) => {
                                e.stopPropagation();
                                handleNegotiation(entry, row.id);
                              }}
                              className="cursor-pointer"
                              height={24}
                              width={24}
                              src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20250107121142-mdi_deal-outline.svg"
                              alt="negotiate-icon"
                            />
                          </div>
                        </div>
                      </div>
                      {entry.sku_price_id === priceId &&
                        priceHistory.map((history) => (
                          <div
                            className="sku-pricing-history-grid"
                            key={`history-price-${history.source_id}`}
                          >
                            <p
                              className="px-12 py-8 xetgo-font-tag price-entry bold"
                              style={{ background: "#fff0e2" }}
                            >
                              {history.quantity} {entry.unit}
                            </p>
                            <p
                              className="px-12 py-8 xetgo-font-tag price-entry bold"
                              style={{ background: "#E8FFEA" }}
                            >
                              ₹ {history.price}
                            </p>
                            <p
                              className="px-12 py-8 xetgo-font-tag price-entry bold"
                              style={{ background: "#FFE8F4" }}
                            >
                              {history.lead_time != null
                                ? `${history.lead_time} days`
                                : "---"}
                            </p>
                            <p className="px-12 py-8 xetgo-font-tag bold price-entry">
                              {formatDate(history.created_at)}
                            </p>
                            <div className="flex-row align-items-center py-4 px-12 gap-12">
                              <a
                                className="px-12 py-4 xetgo-font-tag bold quote-tag"
                                href={
                                  history.source_type === "Quote"
                                    ? `/quotes/${history.source_id}`
                                    : `/auctions/${history.source_id}/bid-hall`
                                }
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {history.source_type
                                  .toUpperCase()
                                  .substring(0, 4) +
                                  "0".repeat(
                                    4 - history.source_id.toString().length,
                                  ) +
                                  history.source_id.toString()}
                              </a>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default SkuPricing;
