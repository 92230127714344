import React, { useState, useEffect } from "react";
import "./AuctionAddressSkuEntry.scss";
import ApiService from "../../services/apiService";
import URLS from "../../urls/Urls";
import Filter from "../Filter/Filter";
import { FilterOptionSchema } from "../Filter/filter.data";
import { useSelector } from "react-redux";
import { getUserWarehouseAddresseFilterOptions } from "../../redux/user/userSelector";
import {
  IAuctionSKU,
  useAuctionContext,
} from "../../context/create-auction-context";

interface CreateAuctionProps {
  addressIndex: number;
}

const AuctionAddressSkuEntry: React.FC<CreateAuctionProps> = ({
  addressIndex,
}) => {
  const addresses = useSelector(getUserWarehouseAddresseFilterOptions);
  const {
    auction,
    setAddress,
    setAddressSkuDetails,
    deleteAddress,
    addAddressSku,
    deleteAddressSku,
  } = useAuctionContext();
  const [skus, setSkus] = useState<FilterOptionSchema[]>([]);
  const [selectedAddress, setSelectedAddress] = useState<FilterOptionSchema[]>(
    [],
  );

  useEffect(() => {
    if (
      auction.auction_sku_details[addressIndex].address_id !==
      selectedAddress[0]?.id
    ) {
      setSelectedAddress(
        addresses.filter(
          (row) =>
            row.id === auction.auction_sku_details[addressIndex].address_id,
        ),
      );
    }
  }, [auction]);

  useEffect(() => {
    fetchSkus();
  }, [selectedAddress]);

  const fetchSkus = async () => {
    if (selectedAddress[0]?.id == null) {
      return;
    }
    try {
      const { data } = await ApiService().client.get(URLS.SKU.GET_SKU_LIST, {
        params: {
          address: selectedAddress[0].id,
        },
      });
      setSkus(data.data);
    } catch (e) {
      console.error(e);
    }
  };

  const addressSelected = (val: FilterOptionSchema[]) => {
    setAddress(addressIndex, val[0]);
  };

  const skuSelected = (val: FilterOptionSchema[]) => {
    val.forEach((sku) => {
      if (
        auction.auction_sku_details[addressIndex].skus.filter(
          (row) => row.id === sku.id,
        ).length === 0
      ) {
        addAddressSku(addressIndex, {
          id: sku.id,
          name: sku.name,
          description: sku.description || "",
          unit: sku.unit || "",
          quantity: "",
          starting_price: "",
          decrement_price: "",
        });
      }
    });
    auction.auction_sku_details[addressIndex].skus.forEach((row) => {
      if (val.filter((entry) => entry.id === row.id).length === 0) {
        deleteAddressSku(addressIndex, row.id);
      }
    });
  };

  return (
    <div className="auction-address-detail-main-container flex-column gap-8 p-12">
      <div className="flex-row align-items-center justify-content-space-between xetgo-font-tag">
        <p>
          Address <span style={{ color: "red" }}>*</span>
        </p>
        <p
          onClick={() => deleteAddress(addressIndex)}
          className="remove-btn flex-row align-items-center justify-content-center px-10 py-4 cursor-pointer bold"
        >
          Remove
        </p>
      </div>
      <div className="auction-address-filter-wrapper">
        <Filter
          option={{
            key: "address",
            name: "Address",
          }}
          optionList={addresses}
          multipleSelection={false}
          onSelection={(val, _option) => addressSelected(val)}
          preSelected={selectedAddress}
          mode="showNames"
          showPlaceholder={false}
          addNew={false}
          showSearch={true}
          classname="auction-address-filter"
        />
      </div>
      <div className="auction-sku-detail-main-container flex-column gap-12 p-12">
        <div className="flex-row justify-content-space-between align-items-center xetgo-font-tag">
          <p>
            Select SKU <span style={{ color: "red" }}> *</span>
          </p>
          <a
            target="_blank"
            rel="noreferrer"
            href="/skus"
            className="flex-row align-items-center gap-2 cursor-pointer"
          >
            <img
              height={12}
              width={12}
              src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20231020043732-add.svg"
              alt="add-icon"
            />
            <p style={{ color: "#5151EC" }}>Add New SKU</p>
          </a>
        </div>
        <Filter
          option={{
            key: "skuName",
            name: "Sku Name",
          }}
          optionList={skus}
          multipleSelection={true}
          onSelection={(val, _option) => {
            skuSelected(val);
          }}
          preSelected={auction.auction_sku_details[addressIndex].skus}
          mode="showNames"
          showPlaceholder={false}
          showId={false}
          direction="left"
          addNew={false}
          classname="auction-sku-filter"
          showDescription={true}
          showSearch={true}
        />
        {auction.auction_sku_details[addressIndex].skus.length > 0 && (
          <div className="all-selected-skus flex-column gap-12 p-8">
            {auction.auction_sku_details[addressIndex].skus.map(
              (selectedSku, skuIndex) => (
                <div
                  key={selectedSku.id}
                  className="selected-sku-detail-main-container p-8"
                >
                  <div className="sku-detail-container flex-column gap-8">
                    <div className="sku-name-action-container flex-row align-items-center justify-content-space-between">
                      <div className="flex-row gap-4 xetgo-font-tag">
                        <p style={{ color: "#5151EC" }}>{selectedSku.name}</p>
                        <a
                          href={`/skus/${selectedSku.id}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img
                            className="cursor-pointer"
                            height={18}
                            width={18}
                            src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20241028055627-arrow-up-right.svg"
                            alt="diagonal-arrow-icon"
                          />
                        </a>
                        <p
                          style={{
                            color: "#9D9D9D",
                            textTransform: "capitalize",
                          }}
                        >
                          {selectedSku.unit}
                        </p>
                      </div>
                      <div
                        onClick={() =>
                          deleteAddressSku(addressIndex, selectedSku.id)
                        }
                        className="trash-container flex-row align-items-center justify-content-space-between border-box p-4 cursor-pointer"
                      >
                        <img
                          height={16}
                          width={16}
                          src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240821104010-trash-2.svg"
                          alt="trash-icon"
                        />
                      </div>
                    </div>
                    <p style={{ color: "#5B5B5B" }} className="xetgo-font-tag">
                      {selectedSku.description}
                    </p>
                    <div className="selected-sku-all-inputs-main-container flex-row gap-14">
                      <div className="sku-input-main-container xetgo-font-tag flex-1">
                        <input
                          className="quantity-input px-8 py-5 xetgo-font-tag full-width border-box"
                          type="number"
                          value={selectedSku.quantity}
                          onChange={(e) =>
                            setAddressSkuDetails(
                              addressIndex,
                              skuIndex,
                              "quantity",
                              e.target.value,
                            )
                          }
                        />
                        <p className="heading bolder">Quantity</p>
                      </div>
                      <div className="sku-input-main-container xetgo-font-tag flex-1">
                        <input
                          className="quantity-input px-8 py-5 xetgo-font-tag full-width border-box"
                          type="number"
                          value={selectedSku.starting_price}
                          onChange={(e) =>
                            setAddressSkuDetails(
                              addressIndex,
                              skuIndex,
                              "starting_price",
                              e.target.value,
                            )
                          }
                        />
                        <p className="heading bolder">Starting Price (INR)</p>
                      </div>
                      <div className="sku-input-main-container xetgo-font-tag flex-1">
                        <input
                          className="quantity-input px-8 py-5 xetgo-font-tag full-width border-box"
                          type="number"
                          value={selectedSku.decrement_price}
                          onChange={(e) =>
                            setAddressSkuDetails(
                              addressIndex,
                              skuIndex,
                              "decrement_price",
                              e.target.value,
                            )
                          }
                        />
                        <p className="heading bolder">
                          Min Decrement Price (INR)
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              ),
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default AuctionAddressSkuEntry;
