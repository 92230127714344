import React, { useEffect, useState } from "react";
import "./BidHall.scss";
import BidHallLive from "../../components/BidHallLive/BidHallLive";
import BidHallHistory from "../../components/BidHallHistory/BidHallHistory";
import { Auction, BidSku, IBidEntry } from "../../models/Auction";
import ApiService from "../../services/apiService";
import { useLocation, useParams } from "react-router-dom";
import URLS from "../../urls/Urls";
import AuctionBidResult from "../../components/AuctionBidResult/AuctionBidResult";
import BidHistoryResult from "../../components/BidHistoryResult/BidHistoryResult";

interface BidSkuResponse {
  locked: boolean;
  skus: { [key: number]: BidSku[] };
  participants: number;
  bidders: number;
}
interface BidHallProps {
  auction: Auction;
}

const BidHall: React.FC<BidHallProps> = ({ auction }) => {
  const [liveBidHall, setLiveBidHall] = useState(true);
  const [timeLeft, setTimeLeft] = useState("");
  const [bidSkus, setBidSkus] = useState<BidSkuResponse>();
  const [skuHistory, setSkuHistory] = useState<{ [key: number]: IBidEntry[] }>(
    {},
  );
  const [skuResult, setSkuResult] = useState<{ [key: number]: IBidEntry[] }>(
    {},
  );
  const location = useLocation();
  const { id } = useParams();

  useEffect(() => {
    if (location.pathname === `/auctions/${id}/bid-hall`) {
      setLiveBidHall(true);
    } else {
      setLiveBidHall(false);
    }
  }, [location]);

  useEffect(() => {
    const updateTimer = () => {
      if (auction) {
        const endTime = new Date(auction.end_time).getTime();
        const now = new Date().getTime();
        const timeDifference = endTime - now;

        if (timeDifference > 0) {
          const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
          const hours = Math.floor(
            (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
          );
          const minutes = Math.floor(
            (timeDifference % (1000 * 60 * 60)) / (1000 * 60),
          );
          setTimeLeft(`${days}d ${hours}h ${minutes}m Left`);
        } else {
          setTimeLeft("Auction Ended");
        }
      }
    };
    updateTimer();
    const intervalId = setInterval(updateTimer, 60000);
    return () => clearInterval(intervalId);
  }, [auction]);

  useEffect(() => {
    fetchBidSkus();
  }, []);

  const fetchBidSkus = async () => {
    try {
      const { data } = await ApiService().client.get(
        URLS.AUCTION.BID_SKUS(id || ""),
      );
      setBidSkus(data);
    } catch (e) {
      console.error("Error: ", e);
    }
  };

  const fetchSkuHistory = async (sku_id: number) => {
    if (skuResult[sku_id] != null) {
      return;
    }
    try {
      const { data } = await ApiService().client.get(
        URLS.AUCTION.BID_RESULT(id || "", sku_id),
      );
      if (!data.locked) {
        setSkuHistory((prev) => {
          return { ...prev, [sku_id]: data.bids };
        });
        setSkuResult((prev) => {
          prev[sku_id] = data.bids
            .filter(
              (row: IBidEntry) =>
                row.bid_rank === 1 && row.status === "ACCEPTED",
            )
            .sort((a: IBidEntry, b: IBidEntry) => a.price < b.price);
          return prev;
        });
      }
    } catch (e) {
      console.error("Error: ", e);
    }
  };

  return (
    <div className="bid-hall-main-page  flex-column gap-12">
      <div className="bid-hall-upper-bar xetgo-font-tag p-12 flex-row justify-content-space-between">
        <p className="flex-row gap-16 title-green">
          <span className=" bold">Participants </span>{" "}
          <span className="sub-title-green">{bidSkus?.participants}</span>
        </p>
        <p className="flex-row gap-16  title-green">
          <span className=" bold">Active Bidders </span>{" "}
          <span className="sub-title-green">{bidSkus?.bidders}</span>
        </p>
        {/* <p className="flex-row gap-16 title-green">
          <span className=" bold">Projected Savings </span>{" "}
          <span className="sub-title-green">₹ 46, 000</span>
        </p> */}
        <p className="title-brown bolder">{timeLeft}</p>
        {auction.status === "PAST" && (
          <div style={{ width: "150px", height: "16px" }}>
            {liveBidHall ? (
              <AuctionBidResult auction={auction} />
            ) : (
              <BidHistoryResult auction={auction} />
            )}
          </div>
        )}
      </div>

      <div className="bid-hall-main-container flex-column gap-4 p-12">
        <div className=""></div>
        {liveBidHall && (
          <div className="upper-detail-container flex-row align-items-center justify-content-space-between px-8 py-8 xetgo-font-tag border-box bold">
            <p
              onClick={() => setLiveBidHall(true)}
              style={{ color: "#333333" }}
              className="cursor-pointer"
            >
              Bid Result
            </p>
            <p
              onClick={() => setLiveBidHall(false)}
              style={{ color: "#AA5200" }}
              className="cursor-pointer"
            >
              Bid History
            </p>
          </div>
        )}
        {liveBidHall ? (
          <BidHallLive
            skuResult={skuResult}
            skus={bidSkus?.skus || []}
            fetchSkuHistory={fetchSkuHistory}
          />
        ) : (
          <BidHallHistory
            skuHistory={skuHistory}
            skus={bidSkus?.skus || []}
            fetchSkuHistory={fetchSkuHistory}
            auction={auction}
          />
        )}
      </div>
    </div>
  );
};

export default BidHall;
