import { useLocation, useNavigate, useParams } from "react-router-dom";
import AuctionDetail from "../../components/AuctionDetail/AuctionDetail";
import AuctionDetailVendorTab from "../../components/AuctionDetailVendorTab/AuctionDetailVendorTab";
import "./AuctionDetailPage.scss";
import React, { useEffect, useState } from "react";
import { Auction } from "../../models/Auction";
import ApiService from "../../services/apiService";
import URLS from "../../urls/Urls";
import BidHall from "../BidHall/BidHall";

const AuctionDetailPage: React.FC = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [timeLeft, setTimeLeft] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const [auction, setAuction] = useState<Auction>();
  const { id } = useParams();

  useEffect(() => {
    fetchAuctionDetails();
  }, [id]);

  const fetchAuctionDetails = async () => {
    try {
      const { data } = await ApiService().client.get(
        URLS.AUCTION.DETAILS(id || ""),
      );
      if (data) {
        setAuction(data.auction);
      }
    } catch (e) {
      console.error("Error:", e);
    }
  };

  useEffect(() => {
    if (location.pathname.endsWith("vendors")) {
      setActiveTab(1);
    } else if (location.pathname.endsWith("bid-hall")) {
      setActiveTab(2);
    } else {
      setActiveTab(0);
    }
  }, [location.pathname]);

  useEffect(() => {
    const updateTimer = () => {
      if (auction) {
        const endTime = new Date(auction.end_time).getTime();
        const now = new Date().getTime();
        const timeDifference = endTime - now;

        if (timeDifference > 0) {
          const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
          const hours = Math.floor(
            (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
          );
          const minutes = Math.floor(
            (timeDifference % (1000 * 60 * 60)) / (1000 * 60),
          );
          setTimeLeft(`${days}d ${hours}h ${minutes}m Left`);
        } else {
          setTimeLeft("Auction Ended");
        }
      }
    };
    updateTimer();
    const intervalId = setInterval(updateTimer, 60000);
    return () => clearInterval(intervalId);
  }, [auction]);

  return (
    <div className="auction-detail-main-page full-width flex-column">
      <div className="upper-bar flex-row align-items-center justify-content-space-between py-12 px-16">
        <p className="xetgo-font-tag">
          <span
            className="sub-title cursor-pointer"
            onClick={() => navigate("/auctions")}
          >
            Auctions /
          </span>{" "}
          <span
            className={`${activeTab === 1 && "sub-title"} cursor-pointer`}
            onClick={() => navigate(`/auctions/${id}`)}
          >
            {auction?.title}
          </span>
          {activeTab === 1 && <span className="sub-title"> / </span>}
          {activeTab === 1 && <span> Vendors </span>}
        </p>
      </div>
      <div className="auction-detail-lower-main-container px-16 py-20 flex-column gap-12">
        <div className="auction-detail-tab-main-container flex-row align-items-center justify-content-center xetgo-font-button">
          <div
            onClick={() => navigate(`/auctions/${id}`)}
            className={`tab-container cursor-pointer border-box flex-row align-items-center gap-8 px-12 py-8 flex-row align-items-center justify-content-center ${
              activeTab == 0 && "activeTab"
            }`}
          >
            <img
              height={18}
              width={18}
              src={`${
                activeTab == 0
                  ? "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20241122102123-Group+1171276134.svg"
                  : "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20241122102353-Group+1171276134.svg"
              }`}
              alt="auction-icon"
            />
            <p>Auction Details</p>
          </div>
          <div
            onClick={() => navigate(`/auctions/${id}/vendors`)}
            className={`tab-container border-box flex-row align-items-center gap-8 px-12 py-8 flex-row align-items-center justify-content-center        cursor-pointer ${
              activeTab == 1 && "activeTab"
            }`}
          >
            <img
              height={18}
              width={18}
              src={`${
                activeTab == 1
                  ? "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20241122103346-Icon.svg"
                  : "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20241122103512-Icon.svg"
              }`}
              alt="auction-icon"
            />
            <p>Manage Bidders</p>
          </div>
          <div
            onClick={() => navigate(`/auctions/${id}/bid-hall`)}
            className={`tab-container cursor-pointer border-box flex-row align-items-center gap-8 px-12 py-8 flex-row align-items-center justify-content-center ${
              activeTab == 2 && "activeTab"
            }`}
          >
            <img
              height={18}
              width={18}
              src={`${
                activeTab == 2
                  ? "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20241122102123-Group+1171276134.svg"
                  : "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20241122102353-Group+1171276134.svg"
              }`}
              alt="auction-icon"
            />
            <p>Bid Hall</p>
          </div>
        </div>
        {activeTab === 0 ||
          (activeTab === 1 && (
            <div className="auction-type-edit-main-container flex-row align-items-center justify-content-space-between">
              <div className=" flex-row gap-20 align-items-center xetgo-font-tag">
                <p className="px-28 py-6 type-container bold">
                  {auction?.type}
                </p>
                <p style={{ color: "#31AD0C" }} className="bolder">
                  {timeLeft}
                </p>
              </div>
              {auction?.status === "UPCOMING" && (
                <div className="edit-auction-btn xetgo-font-tag px-16 py-6 cursor-pointer">
                  Edit Auction
                </div>
              )}
            </div>
          ))}

        <div className="flex-1 overflow-scroll">
          {activeTab === 0 && auction && <AuctionDetail auction={auction} />}
          {activeTab === 1 && auction && <AuctionDetailVendorTab />}
          {activeTab === 2 && auction && <BidHall auction={auction} />}
        </div>
      </div>
    </div>
  );
};

export default AuctionDetailPage;
