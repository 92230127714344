import React from "react";
import { styles } from "./AuctionReportStyles";
import { Page, Text, View, Document, Link, Image } from "@react-pdf/renderer";
import { UserState } from "../../redux/user/userReducer";
import {
  Auction,
  BidMapAddress,
  BidMapResponse,
  IAuctionHistory,
} from "../../models/Auction";
import { commaFormat, formatDate, formatDateTime } from "../../utils/Helper";

interface IAuctionReport {
  user: UserState;
  currentDate: string;
  auction: Auction;
  bids: BidMapResponse;
}

const AuctionReport: React.FC<IAuctionReport> = ({
  user,
  currentDate,
  auction,
  bids,
}) => {
  const fileName = (
    auction.title +
    "___" +
    user.company_details?.name +
    "_ Bid_History.pdf"
  ).replaceAll(" ", "_");

  return (
    <Document title={fileName}>
      <Page size="A4" style={styles.page}>
        <View fixed style={styles.borderOut}>
          <View style={styles.borderIn}></View>
        </View>
        <View>
          <View style={styles.pageHeader}>
            <View style={styles.pageHeaderLeftField}>
              <Text style={styles.pageHeaderKey}>Auction ID</Text>
              <Text style={styles.pageHeaderValue}>#{auction.auction_id}</Text>
            </View>

            <View style={styles.pageHeaderRightField}>
              <Text style={styles.pageHeaderKey}>Auction Duration</Text>
              <Text style={styles.pageHeaderValue}>
                {"23/03/1999, 5:00pm"}- {currentDate}
              </Text>
            </View>
          </View>
          <View style={styles.clientInfoContainer}>
            <View style={styles.leftContainer}>
              <Text style={styles.auctionType}>Reverse Auction</Text>
              <Text style={styles.auctionTitle}>{auction.title}</Text>
              <Text style={styles.auctionTime}>
                {formatDateTime(auction.start_time).replace(/-/g, "/")} -{" "}
                {formatDateTime(auction.end_time).replace(/-/g, "/")}
              </Text>
            </View>
            <View style={styles.rightContainer}>
              <Image
                style={styles.clientLogo}
                src={user.company_details?.logo}
              ></Image>
              <Text style={styles.auctionTitle}>
                {user.company_details?.name}
              </Text>
            </View>
          </View>
          <Text style={styles.auctionDescription}>
            {auction.description.replaceAll(/<[^>]*>/g, " ")}
          </Text>
          <View style={styles.particpantMainContainer}>
            <View style={styles.participantContainer}>
              <Text style={styles.participantTitle}>Participants :</Text>
              <Text>16</Text>
            </View>
            <View style={styles.participantContainer}>
              <Text style={styles.participantTitle}>Winning Vendors :</Text>
              <Text>8</Text>
            </View>
            <View style={styles.participantContainer}>
              <Text style={styles.participantTitle}>Projected Savings :</Text>
              <Text>{`INR ${46000}`}</Text>
            </View>
          </View>
          <View style={styles.auctionBidsContainer}>
            {Object.values(bids).map((address: BidMapAddress, index) => {
              return (
                <View
                  style={styles.auctionBidAddressContainer}
                  key={`address-${index}`}
                >
                  <View style={styles.addressDetailContainer}>
                    <Text style={styles.addressState}>{address.state}</Text>
                    <Text style={styles.addressLine}>{address.name}</Text>
                  </View>
                  {address.skus.map((sku, sindex) => {
                    return (
                      <View key={`skus-${sku.id}`}>
                        <View style={styles.skuDetailContainer}>
                          <View style={styles.skuIndexMainContainer}>
                            <Text>
                              {"0".repeat(3 - (sindex + 1).toString().length)}
                              {sindex + 1}
                            </Text>
                            <View style={styles.skuDetailRightBox}>
                              <Text style={styles.skuName}>{sku.name}</Text>
                              <View
                                style={styles.allMeasurementDetailContainer}
                              >
                                <View style={styles.measurementContainer}>
                                  <Text style={styles.subtiTle}>
                                    Quantity :{" "}
                                  </Text>
                                  <View style={styles.measurementUnitContainer}>
                                    <Text style={styles.quantity}>
                                      {sku.quantity}
                                    </Text>
                                    <Text style={styles.subtiTle}>
                                      {sku.unit}
                                    </Text>
                                  </View>
                                </View>
                                <View style={styles.measurementContainer}>
                                  <Text style={styles.subtiTle}>
                                    Start Price :{" "}
                                  </Text>
                                  <Text style={styles.quantity}>
                                    {" "}
                                    {sku.start_price}
                                  </Text>
                                </View>
                                <View style={styles.measurementContainer}>
                                  <Text style={styles.subtiTle}>
                                    Decrement :{" "}
                                  </Text>
                                  <Text style={styles.quantity}>
                                    {" "}
                                    {sku.decreement}
                                  </Text>
                                </View>
                              </View>
                            </View>
                          </View>
                        </View>
                        <View style={styles.bidsHeaderContainer}>
                          <Text style={styles.unitRank}>Rank</Text>
                          <Text style={styles.bidderName}>Bidder Name</Text>
                          <Text style={styles.unitRate}>Unit Rate</Text>
                          <Text style={styles.unitRate}>Total Rate</Text>
                          <Text style={styles.bidDateTime}>Bid Time</Text>
                        </View>
                        {sku.bids.map((bid, bindex) => {
                          return (
                            <View key={`skus-${sku.id}`}>
                              <View style={styles.bidsContainer}>
                                <View
                                  style={{
                                    ...styles.bidsRowContainer,
                                    borderBottom:
                                      index + 1 === Object.keys(bids).length &&
                                      sindex + 1 === address.skus.length &&
                                      bindex + 1 === sku.bids.length
                                        ? "none"
                                        : "1px solid #E5E7EB",
                                    borderBottomLeftRadius:
                                      index + 1 === Object.keys(bids).length &&
                                      sindex + 1 === address.skus.length &&
                                      bindex + 1 === sku.bids.length
                                        ? "6px"
                                        : "0px",
                                    borderBottomRightRadius:
                                      index + 1 === Object.keys(bids).length &&
                                      sindex + 1 === address.skus.length &&
                                      bindex + 1 === sku.bids.length
                                        ? "6px"
                                        : "0px",
                                  }}
                                  key={`bids-${bindex}`}
                                >
                                  <Text style={styles.bidStatusValue}>
                                    {bindex + 1}
                                  </Text>
                                  <Text style={styles.skuNameValue}>
                                    {bid.name}
                                  </Text>
                                  <Text style={styles.unitRateValue}>
                                    INR {commaFormat(bid.price.toString())}
                                  </Text>
                                  <Text style={styles.totalRateValue}>
                                    INR{" "}
                                    {commaFormat(
                                      (
                                        Math.round(
                                          sku.quantity * bid.price * 100,
                                        ) / 100
                                      ).toString(),
                                    )}
                                  </Text>
                                  <Text style={styles.skuDateTime}>
                                    {formatDateTime(bid.created_at)}
                                  </Text>
                                </View>
                              </View>
                            </View>
                          );
                        })}
                      </View>
                    );
                  })}
                </View>
              );
            })}
          </View>
        </View>
        <View style={styles.pageFooter}>
          <View style={styles.footerLine}></View>
          <Text style={styles.pageFooterName}>
            {`Confidentiality Note: This document is confidential and intended for the recipient's use only. Unauthorized sharing or distribution is prohibited.`}
          </Text>
          <Text style={styles.pageFooterName}>
            Disclaimer: This report is generated from auction data as recorded
            in the system. While every effort is made to ensure accuracy, Xetgo
            Private Limited is not liable for errors arising from technical
            issues, vendor inputs, or delays. All data is subject to
            verification.
          </Text>
        </View>
      </Page>
    </Document>
  );
};

export default AuctionReport;
