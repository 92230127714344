import { useNavigate, useParams } from "react-router-dom";
import "./AuctionDetailVendorTab.scss";
import React, { useEffect, useState } from "react";
import ApiService from "../../services/apiService";
import URLS from "../../urls/Urls";
import { AuctionVendorInvitationStatus } from "../../models/Auction";
import { formatDate } from "../../utils/Helper";

const AuctionDetailVendorTab: React.FC = () => {
  const [vendors, setVendors] = useState<
    { name: string; email: string; date: string; status: string; id: number }[]
  >([]);
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    fetchAuctionVendors();
  }, [id]);

  const fetchAuctionVendors = async () => {
    try {
      const { data } = await ApiService().client.get(
        URLS.AUCTION.VENDORS(id || ""),
      );
      if (data) {
        setVendors(data.vendors);
      }
    } catch (e) {
      console.error("Error:", e);
    }
  };

  return (
    <div className="auction-detail-vendor-tab-main-container p-12">
      <div className="vendor-tab-inner-container p-12 flex-column ">
        <p className="header-bar xetgo-font-button px-12 py-5 bold">
          Vendor Details
        </p>
        <div className="vendor-header-list-main-container p-8">
          <div className="vendor-list-grid header-box xetgo-font-tag bold">
            <p className="empty-index"></p>
            <p className="header-title p-12">Vendor Name</p>
            <p className="header-title p-12">Email</p>
            <p className="header-title p-12">Invitation Date</p>
            <p className="header-title p-12">Status</p>
          </div>
          {vendors.length > 0 && (
            <div className="all-vendors-container">
              {vendors.map((vendor, index) => (
                <div
                  key={index}
                  className={`vendor-list-grid vendor-list-container cursor-pointer xetgo-font-tag bold ${
                    vendors.length - 1 !== index && "header-box"
                  }`}
                  onClick={() =>
                    navigate(`/auctions/${id}/vendors/${vendor.id}`)
                  }
                >
                  <p className="empty-index p-12 border-right">{index + 1}</p>
                  <p className="p-12 border-right">{vendor.name}</p>
                  <p className="p-12 border-right">{vendor.email}</p>
                  <p className="p-12 border-right">{formatDate(vendor.date)}</p>
                  <p className={` p-12`}>
                    <span
                      className="status flex-row align-items-center px-24 py-6"
                      style={{
                        color:
                          AuctionVendorInvitationStatus[vendor.status].color,
                        background:
                          AuctionVendorInvitationStatus[vendor.status]
                            ?.background,
                        borderColor:
                          AuctionVendorInvitationStatus[vendor.status]?.border,
                      }}
                    >
                      {AuctionVendorInvitationStatus[vendor.status].name}
                    </span>
                  </p>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AuctionDetailVendorTab;
