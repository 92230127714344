import { QuestionSchema } from "./QuestionForm";
import { TaskCommentFile } from "./Quote";
import { QuestionDetail } from "./VendorForm";

export interface IAuctionItem {
  id: number;
  title: string;
  type: string;
  start_time: string;
  end_time: string;
  favourite: boolean;
  guidelines: string;
}

export interface IAuctionBid {
  address_line: string;
  aid: number;
  bid_rank: number;
  city: string;
  id: number;
  min_decreement_price: number;
  name: string;
  pincode: number;
  price: number;
  quantity: number;
  start_price: number;
  state: string;
  status: string;
  remark: string;
  vendor_id: number;
  created_at: string;
  unit: string;
}

export interface Auction {
  id: number;
  title: string;
  type: string;
  status: "LIVE" | "UPCOMING" | "PAST";
  auction_id: string;
  start_time: string;
  end_time: string;
  visibility: string;
  currency: string;
  guidelines: string;
  description: string;
  notes: string;
  buyer_details: {
    contact: number;
    name: string;
    location: string;
    email: string;
  };
  vendor: {
    name: string;
    avatar: string;
    id: number;
  };
  address: AuctionAddress[];
}

export interface AuctionAddress {
  detail: {
    state: string;
    pincode: string;
    line: string;
  };
  skus: {
    id: number;
    unit: string;
    name: string;
    quantity: number;
    start_price: number;
    decrement_price: number;
    description: string;
  }[];
}

export const AuctionVendorInvitationStatus: {
  [key: string]: {
    background: string;
    color: string;
    border: string;
    name: string;
  };
} = {
  INVITED: {
    color: "#5151EC",
    background: "#ECECFE",
    border: "#DADAFF",
    name: "Invited",
  },
  ACCEPTED: {
    color: "#D28409",
    background: "#ECECFE",
    border: "#F8F3C5 ",
    name: "Accepted",
  },
  PENDING_APPROVAL: {
    color: "#D28409",
    background: "#FEF8DE",
    border: "#F8F3C5 ",
    name: "Pending Approval",
  },
  DECLINED: {
    color: "#B93100",
    background: "#FFE0E0",
    border: "#FFC1C1",
    name: "Declined",
  },
  REJECTED: {
    color: "#B93100",
    background: "#FFE0E0",
    border: "#FFC1C1",
    name: "Rejected",
  },
  EDIT_REQUESTED: {
    color: "#AA5200",
    background: "#FFECDA",
    border: "#FBDEC4",
    name: "Edit Requested",
  },
  APPROVED: {
    color: "#05AB3A",
    background: "#E5FEED",
    border: "#B8FFD0",
    name: "Approved",
  },
};

export interface IAuctionVendor {
  auction: {
    id: number;
    title: string;
    vendor_form_id: number;
  };
  auction_status: "LIVE" | "PAST" | "UPCOMING";
  comments: {
    comment: string;
    id: number;
    created_at: string;
    name: string;
    avatar: string;
    user_id: number;
    files: TaskCommentFile[];
  }[];
  poc: {
    id: number;
    name: string;
    email: string;
    phone_number: string;
  };
  status: string;
  invite_id: number;
  company_details: {
    name: string;
    owner_name: string;
    company_type: string;
    nature_of_business: string;
    pan_number: string;
    gst_number: string;
    trade_license_number: null;
    tl_expiry_date: null;
    tl_issue_date: null;
    capacity: string;
    capacity_unit: string;
    employee_count: number;
    company_attachments: {
      image: {
        asset_record_id: number;
        name: string;
        url: string;
        company_id: number;
        asset_record_type: string;
        id: number;
      };
    };
    logo: string;
  };
  addresses: {
    id: number;
    address_line: string;
    address_type: string;
    city: string;
    country: string;
    alias: string;
    map_link: string;
    pincode: number;
    state: string;
    company_id: number;
    gstin: string;
    address_poc: {
      alias_name: string;
      contact_number: string;
      poc_name: string;
    };
  }[];
  products: {
    id: number;
    name: string;
    material: string;
    code: string;
  }[];
  vendor_products: number[];
  vendor_machines: string[];
  vendor_images: { id: number; url: string }[];
}

export interface IAuctionVendorForm {
  id: number;
  title: string;
  description: string;
  questions: QuestionSchema[];
  response: string;
}

export interface BidSku {
  address_line: string;
  city: string;
  state: string;
  pincode: number;
  aid: number;
  name: string;
  sku_id: number;
  id: number;
  min_decreement_price: number;
  start_price: string;
  quantity: number;
  unit: string;
}

export interface IBidEntry {
  cname: string;
  price: number;
  created_at: string;
  bid_rank: number;
  status: string;
  remark: string;
}

export interface IAuctionBid {
  address_line: string;
  aid: number;
  bid_rank: number;
  city: string;
  id: number;
  min_decreement_price: number;
  name: string;
  pincode: number;
  price: number;
  quantity: number;
  start_price: number;
  state: string;
  status: string;
  remark: string;
  vendor_id: number;
  created_at: string;
  unit: string;
}

export interface BidMapResponse {
  [key: number]: BidMapAddress;
}
export interface BidMapAddress {
  name: string;
  state: string;
  skus: {
    bids: IAuctionBid[];
    id: number;
    name: string;
    quantity: number;
    start_price: number;
    decreement: number;
    unit: string;
  }[];
}
export interface IAuctionHistory {
  title: string;
  t_c: boolean;
  type: string;
  auction_id: string;
  start_time: string;
  end_time: string;
  visibility: string;
  currency: string;
  guidelines: string;
  description: string;
  notes: string;
  buyer_details: {
    contact: number;
    name: string;
    location: string;
    email: string;
  };
  status: string;
  address: AuctionAddress[];
  form: AuctionVendorFormDataSchema;
  comments: {
    comment: string;
    id: number;
    created_at: string;
    name: string;
    avatar: string;
    user_id: number;
    files: {
      name: string;
      url: string;
      id: number;
    }[];
  }[];
  invite_id: number;
  client: {
    name: string;
    avatar: string;
    id: number;
  };
}
export interface AuctionVendorFormDataSchema {
  id: number;
  title: string;
  description: string;
  questions: QuestionDetail[];
  status: string;
}

export const AuctionBidStatus: {
  [key: string]: {
    background: string;
    color: string;
    border: string;
    name: string;
  };
} = {
  ACCEPTED: {
    color: "#319713",
    background: "#f3faed",
    border: "#e6fddb ",
    name: "Accepted",
  },
  DECLINED: {
    color: "#b93100",
    background: "#ffe0e0",
    border: "#ffc1c1",
    name: "Declined",
  },
  REJECTED: {
    color: "#e0892d",
    background: "#fef0e0",
    border: "#fee5cb",
    name: "Rejected",
  },
};
