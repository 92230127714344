import { useEffect, useState } from "react";
import "./AuctionVendorInvitation.scss";
import Filter from "../Filter/Filter";
import { FilterOptionSchema } from "../Filter/filter.data";
import { useNavigate } from "react-router-dom";
import { useAuctionContext } from "../../context/create-auction-context";
import ApiService from "../../services/apiService";
import URLS from "../../urls/Urls";

const AuctionVendorInvitation: React.FC = () => {
  const { auction, setVendorInvitationDetails } = useAuctionContext();
  const [vendorList, setVendorList] = useState([]);
  const [selectedVendorList, setSelectedVendorList] = useState<
    FilterOptionSchema[]
  >([]);
  const [vendorForms, setVendorForms] = useState<FilterOptionSchema[]>([]);
  const [selectedVendorForm, setSelectefdVendorForm] = useState<
    FilterOptionSchema[]
  >([]);

  useEffect(() => {
    fetchVendorAndForms();
  }, []);

  const fetchVendorAndForms = async () => {
    const { data } = await ApiService().client.get(
      URLS.AUCTION.GET_VENDOR_AND_FORMS,
    );
    setVendorList(data.vendors);
    setVendorForms(data.forms);
  };

  const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setVendorInvitationDetails("invitation_type", event.target.value);
  };

  const handleVendorSelection = (val: FilterOptionSchema[]) => {
    setSelectedVendorList(selectedVendorList);
    setVendorInvitationDetails(
      "invited_vendors_ids",
      val.map((row) => row.id),
    );
    setVendorInvitationDetails("invited_vendors", val);
  };

  const handleVendorFormSelection = (val: FilterOptionSchema[]) => {
    setSelectefdVendorForm(val);
    setVendorInvitationDetails("form_id", val[0].id);
    setVendorInvitationDetails("form_name", val[0].name);
  };

  return (
    <div className="auction-vendor-invitation-main-container flex-column gap-16 px-24">
      <div className="flex-column gap-14">
        <p className="xetgo-font-tag"> Auction Name</p>
        <div className="vendor-all-info-main-container flex-column gap-18">
          <div className="vendor-form-detail-main-container border-box flex-column gap-14 p-12 xetgo-font-tag">
            <p>Invite Vendors</p>
            <div className="vendor-auction-option-main-container flex-row align-items-center p-10">
              <label className="custom-radio flex-row align-items-center gap-8 cursor-pointer">
                <input
                  type="radio"
                  name="auctionType"
                  value="public"
                  checked={
                    auction.vendor_invitation_details.invitation_type ===
                    "PUBLIC"
                  }
                  onChange={handleOptionChange}
                />
                <span className="custom-radio-button"></span>
                Public
              </label>

              <label className="custom-radio flex-row gap-8 align-items-center cursor-pointer">
                <input
                  type="radio"
                  name="auctionType"
                  value="private"
                  checked={
                    auction.vendor_invitation_details.invitation_type ===
                    "PRIVATE"
                  }
                  onChange={handleOptionChange}
                />
                <span className="custom-radio-button"></span>
                Private
              </label>
            </div>
          </div>
          <div className="vendor-form-detail-main-container border-box flex-column gap-14 p-12 xetgo-font-tag">
            <div className="vendor-auction-option-main-container new-vendor-container flex-column p-10">
              <p className="title xetgo-font-tag">
                If inviting privately to vendors, choose from the list
                <span style={{ color: "red" }}>*</span>
              </p>

              <Filter
                option={{
                  key: "vendors",
                  name: "Vendors: Enter Name or ID",
                }}
                optionList={vendorList || []}
                multipleSelection={true}
                onSelection={(val, _option) => {
                  handleVendorSelection(val);
                }}
                preSelected={selectedVendorList}
                mode="showNames"
                showPlaceholder={true}
                addNew={false}
                classname="vendor-filter-input"
                vertical="bottom"
              />
            </div>
          </div>
          <div className="vendor-form-detail-main-container border-box flex-column gap-14 p-12 xetgo-font-tag">
            <div className="vendor-auction-option-main-container new-vendor-container flex-column p-10">
              <div className="flex-row align-items-center justify-content-space-between full-width">
                <p className="title xetgo-font-tag">
                  Assign Form
                  <span style={{ color: "red" }}>*</span>
                </p>
                <a
                  href="/forms/new"
                  target="_blank"
                  rel="noreferrer"
                  className="cursor-pointer"
                  style={{ color: "#5151EC" }}
                >
                  Create New Form
                </a>
              </div>
              <Filter
                option={{
                  key: "vendor_form",
                  name: "Vendor Forms",
                }}
                optionList={vendorForms}
                multipleSelection={false}
                onSelection={(val, _option) => {
                  handleVendorFormSelection(val);
                }}
                preSelected={selectedVendorForm}
                mode="showNames"
                showPlaceholder={true}
                addNew={false}
                classname="vendor-filter-input"
                vertical="bottom"
              />
            </div>
          </div>
          <div className="vendor-form-detail-main-container border-box flex-column gap-14 p-12 xetgo-font-tag">
            <p className="additional-title">Additional Notes (Optional)</p>
            <textarea
              className="additional-note-area px-12 py-6 xetgo-font-tag"
              onChange={(e) =>
                setVendorInvitationDetails("notes", e.target.value)
              }
              value={auction.vendor_invitation_details.notes}
              placeholder="Enter any additional instructions or notes"
            ></textarea>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuctionVendorInvitation;
